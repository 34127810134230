import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";
import DateTimeInput from "app/components/_base/DateTimeInput";
import { CSVLink } from "react-csv";
import PaginateTable from "./PaginateTable";
import moment from "moment";
import OptionService from "app/services/option";

const expiredDays = (deal_time, expiration) => {
    const diff = expiration - deal_time;

    return diff > 0 ? (diff / 1000 / 60 / 60 / 24).toFixed(3) : 0;
};

const MispricingTrades = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 24 * 60 * 60 * 1000);
    const [toTime, setToTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const [csvData, setCsvData] = useState([]);
    const [trades, setTrades] = useState([]);

    const getMispricingTrades = async () => {
        setLoading(true);

        const currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();

        let data = await optionService.GetMisPricingTrades(currentFromTime, currentToTime);
        data = data.map((datum) => {
            return {
                ...datum,
                theoretical_price: datum.theoretical_price.toFixed(4),
                mispricing_magnitude: datum.mispricing_magnitude.toFixed(4),
                pricing_contract: datum.pricing_contract.toFixed(2),
                deal_price: datum.deal_price.toFixed(4),
                deal_time: moment(datum.deal_time).format("DD-MM-YYYY HH:mm:ss.SSS"),
                deal_timestamp: datum.deal_time,
                expiration_date_time: moment(datum.expiration).format("DD-MM-YYYY HH:mm:ss"),
                expiration_days: expiredDays(datum.deal_time, datum.expiration),
                option_vega: datum.option_vega.toFixed(1),
                option_delta: datum.option_delta.toFixed(2),
                option_iv: datum.option_iv.toFixed(4),
                series_offset: currency === "BTC" ? datum.series_offset.toFixed(0) : datum.series_offset.toFixed(1),
            };
        });
        setTrades(data);
        setLoading(false);
        return data;
    };

    const csvInstance = useRef(null);

    const mispricingTradeColumns = [
        {
            Header: "Timestamp",
            accessor: "timestamp",
        },
        {
            Header: "Deal Time",
            accessor: "deal_time",
        },
        {
            Header: "Deal Timestamp",
            accessor: "deal_timestamp",
        },
        {
            Header: "Instrument Name",
            accessor: "instrument_name",
        },
        {
            Header: "Option Type",
            accessor: "option_type",
        },
        {
            Header: "Strike",
            accessor: "strike",
        },
        {
            Header: "Expiration (date/time)",
            accessor: "expiration_date_time",
        },
        {
            Header: "Expiration (days)",
            accessor: "expiration_days",
        },
        {
            Header: "Deal Price",
            accessor: "deal_price",
        },
        {
            Header: "Deal Qty",
            accessor: "deal_qty",
        },
        {
            Header: "Theoretical Price",
            accessor: "theoretical_price",
        },
        {
            Header: "Mispricing Magnitude",
            accessor: "mispricing_magnitude",
        },
        {
            Header: "Deal Direction",
            accessor: "deal_direction",
        },
        {
            Header: "Book State",
            accessor: "book_state",
        },
        {
            Header: "Pricing Contract",
            accessor: "pricing_contract",
        },
        {
            Header: "Option Vega",
            accessor: "option_vega",
        },
        {
            Header: "Option Delta",
            accessor: "option_delta",
        },
        {
            Header: "Option IV",
            accessor: "option_iv",
        },
        {
            Header: "Series Offset",
            accessor: "series_offset",
        },
        {
            Header: "Future Bid Price",
            accessor: "future_bid_price",
        },
        {
            Header: "Future Bid Qty",
            accessor: "future_bid_qty",
        },
        {
            Header: "Future Ask Price",
            accessor: "future_ask_price",
        },
        {
            Header: "Future Ask Qty",
            accessor: "future_ask_qty",
        },
    ];

    const exportMispricingTrades = async () => {
        const trades = await getMispricingTrades();
        await exportCSV(trades, mispricingTradeColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => item[c.accessor]);
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getMispricingTrades}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Mispricing Trades
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportMispricingTrades}
                            disabled={loading}
                        >
                            Export Mispricing Trades
                        </Button>

                        <CSVLink
                            data={csvData}
                            filename={`Mispricing_Trades_${fromTime}_${toTime}.csv`}
                            ref={csvInstance}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    {trades?.length ? (
                        <PaginateTable columns={mispricingTradeColumns} data={trades} className="w-100 text-center" />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default MispricingTrades;
