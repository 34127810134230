import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";
import DateTimeInput from "app/components/_base/DateTimeInput";
import { CSVLink } from "react-csv";
import PaginateTable from "./PaginateTable";

import OptionService from "app/services/option";

const MarketData = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 10 * 60 * 1000); //10 minutes
    const [toTime, setToTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const [csvData, setCsvData] = useState([]);
    const [marketData, setMarketData] = useState([]);

    const getMarketData = async () => {
        setLoading(true);
        let arr = [];
        setMarketData(arr);
        const currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();

        const duration = 20 * 60 * 1000; // 20p ~ 5k record
        const step = Math.ceil((currentToTime - currentFromTime) / duration);

        for (let i = 0; i < step; i++) {
            const upperBound = currentFromTime + (i + 1) * duration;
            const to = upperBound > currentToTime ? currentToTime : upperBound;
            const data = await optionService.GetMarketData(currentFromTime + i * duration, to);

            arr = [...arr, ...data];
            setMarketData(arr);
        }

        setLoading(false);
        return arr;
    };

    const csvInstance = useRef(null);

    const marketDataColumns = [
        {
            Header: "Instrument Name",
            accessor: "instrument_name",
        },
        {
            Header: "Expiration",
            accessor: "expiration",
        },
        {
            Header: "Instrument Type",
            accessor: "instrument_type",
        },
        {
            Header: "Strike",
            accessor: "strike",
        },

        {
            Header: "Timestamp",
            accessor: "timestamp",
        },
        {
            Header: "Bid Price",
            accessor: "bid_price",
        },
        {
            Header: "Bid Quantity",
            accessor: "bid_quantity",
        },
        {
            Header: "Ask Price",
            accessor: "ask_price",
        },
        {
            Header: "Ask Quantity",
            accessor: "ask_quantity",
        },
        {
            Header: "Last Price",
            accessor: "last_price",
        },
        {
            Header: "Last Quantity",
            accessor: "last_quantity",
        },
    ];

    const exportMarketData = async () => {
        const marketData = await getMarketData();
        await exportCSV(marketData, marketDataColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => item[c.accessor]);
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getMarketData}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Market Data
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportMarketData}
                            disabled={loading}
                        >
                            Export Market Data
                        </Button>

                        <CSVLink data={csvData} filename={`Market_Data_${fromTime}_${toTime}.csv`} ref={csvInstance} />
                    </Col>
                </Row>
                <br />
                <Row>
                    {marketData?.length ? (
                        <PaginateTable columns={marketDataColumns} data={marketData} className="w-100 text-center" />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default MarketData;
