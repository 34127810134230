import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "app/actions/option";
import OptionService from "app/services/option";
import moment from "moment";

import { InputGroup, FormControl, Table, Container, Col, Row } from "react-bootstrap";
import PositionValuation from "./PositionValuation";
import DeltaValuation from "./DeltaValuation";
import LastMarketUpdate from "./LastMarketUpdate";
import PositionSummary from "./PositionSummary";

export default function LeadingFuture({ lastPrice }) {
    const dispatch = useDispatch();

    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState();
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);
    const { min_day_expiry, risk_free_rate, dividend_rate, past_deal_sec, past_deal_price_diff, leading_contract } =
        useSelector((state) => state.config.system);

    const [minDayExpiry, setMinDayExpiry] = useState(min_day_expiry);
    const [riskFreeRate, setRiskFreeRate] = useState(risk_free_rate);
    const [dividendRate, setDividendRate] = useState(dividend_rate);
    const [pastDealSec, setPastDealSec] = useState(past_deal_sec);
    const [pastDealPriceDiff, setPastDealPriceDiff] = useState(past_deal_price_diff);

    const { perpetual_contract } = useSelector((state) => state.config.system);

    function changeMinDayExpiry(e) {
        var value = e.target.value;
        if (value === "") {
            value = 0;
        }
        value = parseFloat(value);
        optionService.UpdateParams({
            min_day_expiry: value,
            risk_free_rate: riskFreeRate,
            dividend_rate: dividendRate,
            past_deal_sec: pastDealSec,
            past_deal_price_diff: pastDealPriceDiff,
        });
        setMinDayExpiry(value);
        dispatch(actions.UpdateSystemConfig());
    }

    function changeRiskFreeRate(e) {
        var value = e.target.value;
        if (value === "") {
            value = 0;
        }
        value = parseFloat(value);
        optionService.UpdateParams({
            min_day_expiry: minDayExpiry,
            risk_free_rate: value,
            dividend_rate: dividendRate,
            past_deal_sec: pastDealSec,
            past_deal_price_diff: pastDealPriceDiff,
        });
        setRiskFreeRate(value);
        dispatch(actions.UpdateSystemConfig());
    }

    function changeDividendRate(e) {
        var value = e.target.value;
        if (value === "") {
            value = 0;
        }
        value = parseFloat(value);
        optionService.UpdateParams({
            min_day_expiry: minDayExpiry,
            risk_free_rate: riskFreeRate,
            dividend_rate: value,
            past_deal_sec: pastDealSec,
            past_deal_price_diff: pastDealPriceDiff,
        });
        setDividendRate(value);
        dispatch(actions.UpdateSystemConfig());
    }

    function changePastDealSec(e) {
        var value = e.target.value;
        if (value === "") {
            value = 0;
        }
        value = parseFloat(value);
        optionService.UpdateParams({
            min_day_expiry: minDayExpiry,
            risk_free_rate: riskFreeRate,
            dividend_rate: dividendRate,
            past_deal_sec: value,
            past_deal_price_diff: pastDealPriceDiff,
        });
        setPastDealSec(value);
        dispatch(actions.UpdateSystemConfig());
    }

    function changePastDealPriceDiff(e) {
        var value = e.target.value;
        if (value === "") {
            value = 0;
        }
        value = parseFloat(value);
        optionService.UpdateParams({
            min_day_expiry: minDayExpiry,
            risk_free_rate: riskFreeRate,
            dividend_rate: dividendRate,
            past_deal_sec: pastDealSec,
            past_deal_price_diff: value,
        });
        setPastDealPriceDiff(value);
        dispatch(actions.UpdateSystemConfig());
    }
    const EmptyRowData = () => (
        <tr>
            {[1, 2, 3, 4, 5].map((val) => (
                <td key={val}>0</td>
            ))}
        </tr>
    );

    const [totalDelta, setTotalDelta] = useState({}); // mapping expiration - delta, None = 0, total = 1

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col xs={3} className="pl-0 pr-0">
                        <b>Position Valuation</b>
                        <PositionValuation optionService={optionService} />
                        <LastMarketUpdate optionService={optionService} />
                    </Col>

                    <Col xs={9} className="pr-0">
                        <b>Delta Valuation</b>
                        <DeltaValuation optionService={optionService} setTotalDelta={setTotalDelta} />

                        <b>Position Summary</b>
                        <PositionSummary optionService={optionService} totalDelta={totalDelta} />
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-4 pl-0">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Min day to expiry</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" value={minDayExpiry} onChange={changeMinDayExpiry} />
                        </InputGroup>

                        <Row>
                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Risk free rate</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl type="number" value={riskFreeRate} onChange={changeRiskFreeRate} />
                                </InputGroup>
                            </Col>

                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Dividend rate</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl type="number" value={dividendRate} onChange={changeDividendRate} />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Past Deal Sec</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        type="number"
                                        value={pastDealSec}
                                        onChange={changePastDealSec}
                                        min={0}
                                        max={1000000}
                                        step={10}
                                    />
                                </InputGroup>
                            </Col>

                            <Col>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Past Deal Price Diff</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        type="number"
                                        value={pastDealPriceDiff}
                                        onChange={changePastDealPriceDiff}
                                        min={0}
                                        max={1}
                                        step={0.00001}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <b>
                                    {" "}
                                    Leading future contract: {leading_contract?.instrument} (Expires{" "}
                                    {moment(leading_contract?.expiration).fromNow()}) and Perpetual Future
                                </b>
                            </Col>
                        </Row>

                        <Row>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Ask qty</th>
                                        <th>Ask price</th>
                                        <th>Last Price</th>
                                        <th>Bid price</th>
                                        <th>Bid qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leading_contract ? (
                                        <tr>
                                            <td>{leading_contract.first_level.ask[1]}</td>
                                            <td>{leading_contract.first_level.ask[0]}</td>
                                            <td>{lastPrice.leadingFuturePrice}</td>
                                            <td>{leading_contract.first_level.bid[0]}</td>
                                            <td>{leading_contract.first_level.bid[1]}</td>
                                        </tr>
                                    ) : (
                                        EmptyRowData()
                                    )}
                                    {perpetual_contract ? (
                                        <tr>
                                            <td>{perpetual_contract.first_level.ask[1]}</td>
                                            <td>{perpetual_contract.first_level.ask[0]}</td>
                                            <td>{lastPrice.perpetualPrice}</td>
                                            <td>{perpetual_contract.first_level.bid[0]}</td>
                                            <td>{perpetual_contract.first_level.bid[1]}</td>
                                        </tr>
                                    ) : (
                                        EmptyRowData()
                                    )}
                                </tbody>
                            </Table>

                            {!leading_contract && <div>Cannot load orderbook of leading future contract</div>}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
