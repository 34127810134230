import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import styled from "styled-components";
import cx from "classnames";

import ToastMessage from "./ToastMessage";
import CloseButton from "./CloseButton";

import "react-toastify/dist/ReactToastify.css";

const renderCloseButton = ({ closeToast }) => <CloseButton closeToast={closeToast} />;

const ToastNotificationContainer = ({ className }) => {
    const { id, type, text, title } = useSelector((state) => state.toast);

    useEffect(() => {
        if (id) toast[type](<ToastMessage text={text} title={title} type={type} />);
    }, [id, text, title, type]);

    return (
        <div className={cx(className, "toastNotification")}>
            <ToastContainer
                limit={3}
                autoClose={5000}
                draggable={false}
                closeButton={renderCloseButton}
                position="top-right"
                hideProgressBar
                pauseOnHover
                closeOnClick
                newestOnTop
            />
        </div>
    );
};

export default styled(ToastNotificationContainer)`
    .Toastify__toast-container {
        width: 380px;

        .Toastify__toast {
            padding: 12px;
            border-radius: 4px;
            color: #636c79;
            box-shadow: 0px 2px 8px rgba(1, 18, 34, 0.16);
            background: #ffffff;
            font-size: 14px;
            font-weight: normal;
            line-height: 20;

            &::after {
                position: absolute;
                height: 100%;
                content: "";
                width: 4px;
                left: 0;
                top: 0;
            }
        }

        .Toastify__toast-body {
            padding: 0;
        }

        .Toastify__toast.Toastify__toast--success::after {
            background-color: #00bc3c;
        }

        .Toastify__toast.Toastify__toast--error::after {
            background-color: #e60a32;
        }

        .Toastify__toast.Toastify__toast--warning::after {
            background-color: #ff821e;
        }

        .Toastify__toast.Toastify__toast--info::after {
            background-color: #2a66f4;
        }
    }
`;
