import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import AmCharts from "@amcharts/amcharts3-react";

import OptionService from "app/services/option";

const PERIOD_TIME = {
    ONE_DAY: 1000 * 60 * 60 * 24,
    ONE_WEEK: 1000 * 60 * 60 * 24 * 7,
    ONE_MONTH: 1000 * 60 * 60 * 24 * 30,
    SIX_MONTH: 1000 * 60 * 60 * 24 * 120,
};

const INTERVAL_TIME = {
    ONE_DAY: 1000 * 60, // 1m
    ONE_WEEK: 1000 * 60 * 10, // 10m
    ONE_MONTH: 1000 * 60 * 30, // 30m
    SIX_MONTH: 1000 * 60 * 30 * 6, // 30m
};

function getTableConfig(dataProvider) {
    const timeBaseData = dataProvider.map((d) => ({
        ...d,
        date: new Date(d.timestamp),
    }));
    return {
        type: "serial",
        theme: "light",
        dataProvider: timeBaseData,
        gridAboveGraphs: true,
        categoryField: "date",
        dataDateFormat: "YYYY-MM-DD",
        pathToImages: "https://cdn.amcharts.com/lib/3/images/",

        valueAxes: [
            {
                id: "v1",
                axisColor: "#FF6600",
                axisThickness: 2,
                axisAlpha: 1,
                position: "left",
            },
            {
                id: "v2",
                axisColor: "#FCD202",
                axisThickness: 2,
                axisAlpha: 1,
                position: "left",
                offset: 50,
            },
            {
                id: "v3",
                axisColor: "#B0DE09",
                axisThickness: 2,
                gridAlpha: 0,
                axisAlpha: 1,
                position: "right",
            },
            {
                id: "v4",
                axisColor: "#3a34eb",
                axisThickness: 2,
                gridAlpha: 0,
                axisAlpha: 1,
                position: "right",
                offset: 50,
            },
        ],

        graphs: [
            {
                valueAxis: "v1",
                title: "ATM IV",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "displayATMIV",
                hideBulletsCount: 120,
                position: "left",
            },
            {
                valueAxis: "v2",
                title: "PC Offset",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "pricing_offset",
                hideBulletsCount: 120,
                position: "left",
                lineColor: "#fcad03",
            },
            {
                valueAxis: "v3",
                title: "Series Contract",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "underlying",
                hideBulletsCount: 120,
                position: "left",
            },
            {
                valueAxis: "v1",
                title: "P1",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "displayP1",
                hideBulletsCount: 120,
                position: "right",
            },
            {
                valueAxis: "v1",
                title: "C1",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "displayC1",
                hideBulletsCount: 120,
                position: "right",
            },
        ],

        chartScrollbar: {
            graph: "v1",
            oppositeAxis: false,
            offset: 30,
            scrollbarHeight: 30,
            backgroundAlpha: 0,
            selectedBackgroundAlpha: 0.1,
            selectedBackgroundColor: "#888888",
            graphFillAlpha: 0,
            graphLineAlpha: 0.5,
            selectedGraphFillAlpha: 0,
            selectedGraphLineAlpha: 1,
            autoGridCount: true,
            color: "#AAAAAA",
        },

        categoryAxis: {
            parseDates: true,
            dashLength: 1,
            minorGridEnabled: true,
            minPeriod: "fff",
            dateFormats: [
                {
                    period: "fff",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "ss",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "mm",
                    format: "JJ:NN",
                },
                {
                    period: "hh",
                    format: "JJ:NN",
                },
                {
                    period: "DD",
                    format: "MMM DD",
                },
                {
                    period: "WW",
                    format: "MMM DD",
                },
                {
                    period: "MM",
                    format: "MMM",
                },
                {
                    period: "YYYY",
                    format: "YYYY",
                },
            ],
        },
        legend: {
            spacing: 100,
            valueWidth: 70,
        },

        chartCursor: {
            valueBalloonsEnabled: true,
            categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
        },
    };
}

const ChartCollectedStats = (props) => {
    const [show, setShow] = useState(false);

    const intervalRef = useRef();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [tableConfig, setTableConfig] = useState(getTableConfig([]));
    const [period, setPeriod] = useState("D");

    const handleChangePeriod = async (period) => {
        await setPeriod(period);
        await getStatsData(period);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getStatsData = useCallback(
        async (currentPeriod = period) => {
            console.log("getStatsData");

            const toTime = new Date().getTime();
            let fromTime = new Date().getTime() - PERIOD_TIME.ONE_DAY;
            let interval = INTERVAL_TIME.ONE_DAY;

            if (currentPeriod == "W") {
                fromTime = toTime - PERIOD_TIME.ONE_WEEK;
                interval = INTERVAL_TIME.ONE_WEEK;
            } else if (currentPeriod == "M") {
                fromTime = toTime - PERIOD_TIME.ONE_MONTH;
                interval = INTERVAL_TIME.ONE_MONTH;
            } else if (currentPeriod == "6M") {
                fromTime = toTime - PERIOD_TIME.SIX_MONTH;
                interval = INTERVAL_TIME.SIX_MONTH;
            }

            const data = await optionService.GetSeriesData(props.expiration, fromTime, toTime, interval);
            if (!data.length) return;

            // The data is sorted by latest timestamp
            data.reverse();

            let minPCOffset;

            const filteredIVData = data.filter((x, i) => {
                if (i == 0) minPCOffset = x.pricing_offset;
                else if (x.pricing_offset < minPCOffset) minPCOffset = x.pricing_offset;

                return x.at_the_money_iv && x.underlying && x.p1 && x.c1;
            });

            const multipliedIVData = filteredIVData.map((d) => {
                return {
                    ...d,
                    displayATMIV: (Math.round(d.at_the_money_iv * 1000) / 10).toFixed(1),
                    iv30day: (Math.round(d.iv30day * 1000) / 10).toFixed(1),
                    iv90day: (Math.round(d.iv90day * 1000) / 10).toFixed(1),
                    displayP1: (Math.round(d.p1 * 1000) / 10).toFixed(1),
                    displayC1: (Math.round(d.c1 * 1000) / 10).toFixed(1),
                };
            });
            setTableConfig(getTableConfig(multipliedIVData));
            // const { time, atmIv30d, atmIv90d, leadingPrices } = mappingStats(multipliedIVData);
        },
        [optionService, period, props.expiration]
    );

    useEffect(() => {
        const cleanUp = () => {
            clearInterval(intervalRef.current);
        };

        if (!show) {
            return cleanUp;
        }

        cleanUp();
        getStatsData();
        intervalRef.current = setInterval(getStatsData, 30_000);

        return cleanUp;
    }, [getStatsData, show]);

    return (
        <>
            <Button size="sm" className="mb-1 mr-1" variant="primary" onClick={handleShow}>
                Collected Stats
            </Button>

            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        Collected Stats: {moment(props.expiration).format("D MMMM Y")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ButtonGroup aria-label="select time range">
                        <Button
                            size="sm"
                            className={period == "D" ? "btn-info" : "btn-light"}
                            onClick={() => handleChangePeriod("D")}
                        >
                            Day
                        </Button>
                        <Button
                            size="sm"
                            className={period == "W" ? "btn-info" : "btn-light"}
                            onClick={() => handleChangePeriod("W")}
                        >
                            Week
                        </Button>
                        <Button
                            size="sm"
                            className={period == "M" ? "btn-info" : "btn-light"}
                            onClick={() => handleChangePeriod("M")}
                        >
                            Month
                        </Button>
                        <Button
                            size="sm"
                            className={period == "6M" ? "btn-info" : "btn-light"}
                            onClick={() => handleChangePeriod("6M")}
                        >
                            6 Months
                        </Button>
                    </ButtonGroup>

                    <AmCharts.React
                        style={{
                            width: "100%",
                            height: "600px",
                        }}
                        options={tableConfig}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChartCollectedStats;
