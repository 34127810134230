import * as constants from "app/actions/constants";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
    expand_all_series: false,
    secret: "",
    currency: "BTC",
    gammas: {},
};

export default function globalReducer(state = initialState, action) {
    switch (action.type) {
        // case REHYDRATE: {
        //   if (action.key === "global") {
        //     let payload = action.payload
        //     if(payload){
        //       let newState = { ...state }

        //       return {...newState,
        //         expand_all_series: payload.expand_all_series,
        //         secret: payload.secret

        //       }
        //     }
        //     return initialState
        //   }
        // }
        case constants.Action_ToggleExpandAllSeries: {
            state.expand_all_series = action.payload;
            break;
        }

        case constants.Action_SetGamma: {
            state.gammas[action.payload.expiration] = action.payload.value;
            break;
        }

        case constants.Action_SaveSecret: {
            state.secret = action.payload;
            break;
        }

        case constants.Action_SaveCurrency: {
            state.currency = action.payload;
            break;
        }

        default:
            return state;
    }
    return { ...state };
}
