import * as constants from "./constants";

export function UpdateOrderbook(instrument, orderbook) {
    return {
        type: constants.Action_UpdateOrderbook,
        payload: { instrument, orderbook },
    };
}

export function UpdateAllOrderbook(orderbook) {
    return {
        type: constants.Action_UpdateAllOrderbook,
        payload: orderbook,
    };
}
