import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import {
    Button,
    ListGroup,
    Row,
    Col,
    InputGroup,
    Container,
    Table,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import * as actions from "app/actions/option";
import OptionService from "app/services/option";

import { GET_OPTION_DATA_INTERVAL } from "./const";

import Option from "./Option";

import useInterval from "../../services/useInterval";

import ChartCombinedIv from "./ChartCombinedIv";
import ChartMarketStatistics from "./ChartMarketStatistics";
import ChartOffset from "./ChartOffset";

const Styles = styled.div`
    input.numeric-input::-webkit-inner-spin-button,
    input.numeric-input::-webkit-outer-spin-button {
        opacity: 0;
    }
    .button-step {
        button {
            padding: 0.05rem 0.35rem;
            font-size: 0.375rem;
        }
    }
    .numeric-input {
        height: auto !important;
    }
    .table td,
    table th {
        padding: 0.25rem;
    }
`;

const calculateTotalIv = (strikes) => {
    if (!strikes || !strikes.length) return 0;
    let total = 0;
    strikes.forEach((strike) => (total += strike.iv));

    return total * 100;
};
export default function OptionSeries({ setLastPrice }) {
    const dispatch = useDispatch();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState();
    const [positionMap, setPositionMap] = useState({});

    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const { future_contracts, series, perpetual_contract, leading_contract } = useSelector(
        (state) => state.config.system
    );
    const expand_all_series = useSelector((state) => state.global.expand_all_series);
    const expandExpiration = useSelector((state) => state.expand);
    const orderbook = useSelector((state) => state.orderbook);

    const [lastFutureTrades, setLastFutureTrades] = useState({});

    const [validatedSeries, setValidatedSeries] = React.useState([]);
    const [expiredSeries, setExpiredSeries] = React.useState([]);

    function refreshValidatedSeries() {
        const seriesNotExpired = series ? series.filter((s) => s.expiration >= new Date().getTime()) : [];

        const expiredSeries = series ? series.filter((s) => s.expiration < new Date().getTime()) : [];

        setValidatedSeries([...seriesNotExpired]);
        setExpiredSeries([...expiredSeries]);
    }
    useEffect(() => {
        getPositions();
    }, [optionService]);

    useEffect(() => {
        refreshValidatedSeries();
    }, [series]);

    useEffect(() => {
        validatedSeries.forEach((s) => {
            dispatch(actions.SetExpandSeries(s.expiration, expand_all_series));
        });
    }, [expand_all_series]);

    useEffect(() => {
        validatedSeries.forEach((s) => {
            if (expandExpiration[s.expiration] === undefined) {
                dispatch(actions.SetExpandSeries(s.expiration, expand_all_series));
            }
        });
    }, [validatedSeries]);

    const getPositions = () => {
        if (optionService)
            optionService.getMispricedPositions().then((res) => {
                const map = {};
                res.forEach((pos) => {
                    map[pos.Symbol] = pos.Size;
                });
                setPositionMap(map);
            });
    };

    function requireExpirationContract(obj) {
        // find future contract
        var expirationContract = findExpirationContract(obj.expiration);

        // eslint-disable-next-line no-prototype-builtins
        if (expirationContract && orderbook && orderbook.hasOwnProperty(expirationContract.instrument)) {
            var orderExpirationContract = orderbook[expirationContract.instrument];
            return (
                <Row className="pt-2">
                    <Col sm={3} />
                    <Col sm={9} className="pr-0">
                        <div className="d-flex position-relative">
                            <div>
                                <b>Future:</b>
                            </div>
                            <div className="position-absolute" style={{ left: "160px" }}>
                                <b>Ask qty: </b>
                                <b>{orderExpirationContract.ask[1]}</b>
                            </div>
                            <div className="position-absolute" style={{ left: "320px" }}>
                                <b>Ask: </b>
                                <b>{orderExpirationContract.ask[0]}</b>
                            </div>
                            <div className="position-absolute" style={{ left: "491px" }}>
                                <b>Bid: </b>
                                <b>{orderExpirationContract.bid[0]}</b>
                            </div>
                            <div className="position-absolute" style={{ left: "620px" }}>
                                <b>Bid qty: </b>
                                <b>{orderExpirationContract.bid[1]}</b>
                            </div>
                            <div className="position-absolute" style={{ left: "766px" }}>
                                <b>Last: </b>
                                <b>
                                    {lastFutureTrades[obj.future_contract]
                                        ? lastFutureTrades[obj.future_contract].price
                                        : null}
                                </b>
                            </div>
                            <div className="position-absolute" style={{ left: "890px" }}>
                                <b>Time: </b>
                                {lastFutureTrades[obj.future_contract]
                                    ? moment(lastFutureTrades[obj.future_contract].timestamp).format("HH:mm:ss")
                                    : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        return "";
    }

    function findExpirationContract(expiration) {
        for (var i = 0; i < future_contracts.length; i++) {
            if (future_contracts[i].expiration === expiration) {
                return future_contracts[i];
            }
        }
        return false;
    }

    function toggleExpandAllSeries() {
        dispatch(actions.ToggleExpandAllSeries(!expand_all_series));
    }

    function expandView(expiration) {
        dispatch(actions.SetExpandSeries(expiration, true));
    }

    function collapseView(expiration) {
        dispatch(actions.SetExpandSeries(expiration, false));
    }

    async function enableOnChange(expiration, e, name) {
        const checked = e.target.checked;

        await optionService.SetOptionConfig(expiration, name, checked);

        dispatch(actions.updateAutoRunConfig(expiration, name, checked));
        dispatch(actions.UpdateSystemConfig());
    }

    async function getLastFutureTrade() {
        const filteredSeries = validatedSeries.reduce((filtered, item) => {
            if (item.leading_contract && item.future_contract) {
                filtered.push(item.future_contract);
            }
            return filtered;
        }, []);

        const perpetualInstrument = perpetual_contract ? perpetual_contract.instrument : "";

        const responses = await optionService.GetLastTradesByInstruments([...filteredSeries, perpetualInstrument]);

        const lastTrades = {};

        const lastPrice = {
            leadingFuturePrice: 0,
            perpetualPrice: 0,
        };

        responses.forEach((item) => {
            lastTrades[item.instrument] = item;
            if (item.instrument == perpetualInstrument) lastPrice.perpetualPrice = item.price;

            if (item.instrument == leading_contract?.instrument) lastPrice.leadingFuturePrice = item.price;
        }, {});

        setLastPrice(lastPrice);
        setLastFutureTrades(lastTrades);
    }

    useInterval(getLastFutureTrade, 3_000);
    useInterval(getPositions, 1_000); // 1s

    const displayVirtual = (expiration) => {
        const oneHourMillis = 3.6e6;
        return expiration - moment().format("x") > oneHourMillis && expiration != leading_contract.expiration;
    };

    return (
        <Styles>
            <ChartCombinedIv series={validatedSeries} />
            <ChartMarketStatistics optionService={optionService} />
            <ChartOffset expiredSeries={expiredSeries} series={validatedSeries} />

            <Button size="sm" className="mb-1 mr-1" variant="warning" onClick={(e) => toggleExpandAllSeries()}>
                {expand_all_series ? "Collapse all series" : "Expand all series"}
            </Button>

            <ListGroup className="pb-5">
                {validatedSeries.map((obj) => {
                    return (
                        <ListGroup.Item key={obj.expiration}>
                            <div>
                                <b className={obj.error ? "text-danger" : ""}>
                                    Expiration: {moment(obj.expiration).format("D MMMM Y")} (Expires{" "}
                                    {moment(obj.expiration).fromNow()})
                                </b>
                                {obj.error && (
                                    <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {Object.keys(obj.error).map((key, index) => {
                                                    return (
                                                        <div key={index} className="text-left">
                                                            {key}: {obj.error[key]}
                                                        </div>
                                                    );
                                                })}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-exclamation-circle" aria-hidden="true" />
                                    </OverlayTrigger>
                                )}
                            </div>

                            <Container className="pt-1">
                                <Row>
                                    <Col xs={11}>
                                        <Row>
                                            <Col xs={2} className="pt-2">
                                                Current Offset: {obj.pricing_offset.toFixed(1)}
                                                <br />
                                                Fair Value Offset: {obj.fair_value_offset.toFixed(1)}
                                            </Col>
                                            <Col xs={3} className="pt-2" style={{ paddingLeft: 0 }}>
                                                Offset Volatility: {obj.offset_volatility.toFixed(1)}
                                            </Col>

                                            <Col sm={5} className="pt-2 pl-2">
                                                <div>
                                                    Pricing Contract Theoretical Price:{" "}
                                                    <b>{obj.underlying.toFixed(1)}</b>
                                                </div>
                                            </Col>
                                            <Col className="pl-4">
                                                <div>
                                                    &nbsp;ATM iv:{" "}
                                                    <b>{(Math.round(obj.at_the_money * 1000) / 10).toFixed(1)}</b>
                                                </div>
                                                <div>
                                                    Total IV: <b>{calculateTotalIv(obj.strikes).toFixed(4)}</b>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col>
                                        {!expandExpiration[obj.expiration] && (
                                            <Button
                                                size="sm"
                                                onClick={(e) => expandView(obj.expiration)}
                                                key={obj.expiration}
                                            >
                                                Expand
                                            </Button>
                                        )}

                                        {expandExpiration[obj.expiration] && (
                                            <Button
                                                size="sm"
                                                onClick={(e) => collapseView(obj.expiration)}
                                                key={obj.expiration}
                                            >
                                                Collapse
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="pt-1 text-left pl-3 series-header">
                                    <div className="offset">
                                        <div className="mb-1">Offset High: {obj.offset_high.toFixed(1)}</div>
                                        <div>Offset Low: {obj.offset_low.toFixed(1)}</div>
                                    </div>
                                    <div className="pt-2 iv-table">
                                        <Table responsive>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        P1 iv:{" "}
                                                        <span className="font-weight-light">
                                                            {(Math.round(obj.p1_iv * 1000) / 10).toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        C1 iv:{" "}
                                                        <span className="font-weight-light">
                                                            {(Math.round(obj.c1_iv * 1000) / 10).toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        P2 iv:{" "}
                                                        <span className="font-weight-light">
                                                            {(Math.round(obj.p2_iv * 1000) / 10).toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        C2 iv:{" "}
                                                        <span className="font-weight-light">
                                                            {(Math.round(obj.c2_iv * 1000) / 10).toFixed(1)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Ratio:{" "}
                                                        <span className="font-weight-light">
                                                            {obj.p1_ratio.toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        Ratio:{" "}
                                                        <span className="font-weight-light">
                                                            {obj.c1_ratio.toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        Ratio:{" "}
                                                        <span className="font-weight-light">
                                                            {obj.p2_ratio.toFixed(1)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        Ratio:{" "}
                                                        <span className="font-weight-light">
                                                            {obj.c2_ratio.toFixed(1)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="future-price px-5">
                                        <div className="w-50">
                                            <div className="mb-1 ml-tiny">
                                                Synth ask: <b>{obj.min_synth_ask.toFixed(1)}</b>
                                            </div>
                                            <div className={displayVirtual(obj.expiration) || "d-none"}>
                                                Virtual ask: <b>{obj.virtual_future_ask[0].toFixed(1)}</b>
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <div className="mb-1 ml-tiny">
                                                Synth bid: <b>{obj.max_synth_bid.toFixed(1)}</b>
                                            </div>
                                            <div className={displayVirtual(obj.expiration) || "d-none"}>
                                                Virtual bid: <b>{obj.virtual_future_bid[0].toFixed(1)}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkbox">
                                        <Row className="p-0 mt-1">
                                            <Col className="p-0">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="enable market offset"
                                                    checked={obj.auto_run && obj.auto_run.market_offset_enabled}
                                                    onClick={(e) =>
                                                        enableOnChange(obj.expiration, e, "market_offset_enabled")
                                                    }
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="enable market iv"
                                                    checked={obj.auto_run && obj.auto_run.market_iv_enabled}
                                                    onClick={(e) =>
                                                        enableOnChange(obj.expiration, e, "market_iv_enabled")
                                                    }
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="enable smooth iv"
                                                    checked={obj.auto_run && obj.auto_run.smooth_iv_enabled}
                                                    onClick={(e) =>
                                                        enableOnChange(obj.expiration, e, "smooth_iv_enabled")
                                                    }
                                                />
                                            </Col>
                                            <Col className="p-0">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="enable smooth density"
                                                    checked={obj.auto_run && obj.auto_run.smooth_density_enabled}
                                                    onClick={(e) =>
                                                        enableOnChange(obj.expiration, e, "smooth_density_enabled")
                                                    }
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="auto routine"
                                                    checked={obj.auto_run && obj.auto_run.auto_routine}
                                                    onClick={(e) => enableOnChange(obj.expiration, e, "auto_routine")}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                                <div>{requireExpirationContract(obj)}</div>
                            </Container>

                            {expandExpiration[obj.expiration] && (
                                <Option
                                    positions={positionMap}
                                    strikes={obj.strikes}
                                    underlyingPrice={obj.underlying}
                                    expiration={obj.expiration}
                                    ivATM={obj.at_the_money}
                                    p1Ratio={obj.p1_ratio}
                                    p2Ratio={obj.p2_ratio}
                                    c1Ratio={obj.c1_ratio}
                                    c2Ratio={obj.c2_ratio}
                                    minSynthAsk={obj.min_synth_ask}
                                    maxSynthBid={obj.max_synth_bid}
                                    p1Iv={obj.p1_iv * 100}
                                    p2Iv={obj.p2_iv * 100}
                                    c1Iv={obj.c1_iv * 100}
                                    c2Iv={obj.c2_iv * 100}
                                    optionData={obj}
                                    optionService={optionService}
                                />
                            )}
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </Styles>
    );
}
