import { createStore, applyMiddleware } from "redux";

import { persistStore } from "redux-persist";
import rootReducer from "app/reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "app/sagas";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(rootReducer, applyMiddleware(...middleware));
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
