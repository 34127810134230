import { useState, useEffect, useCallback } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import useInterval from "app/services/useInterval";
import useLocalStorage from "app/services/useLocalStorage";
import { formatMoney } from "app/helper/function";
import moment from "moment";

const TooltipHeader = ({ tooltip, display }) => (
    <OverlayTrigger key={"top"} placement={"top"} overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}>
        <div>{display}</div>
    </OverlayTrigger>
);

const DeltaValuation = ({ optionService, setTotalDelta }) => {
    const [data, setData] = useLocalStorage("DeltaValuation", []);
    const [totalRow, setTotalRow] = useState({});

    const updateDeltaValuation = useCallback(async () => {
        const delta = await optionService.GetDeltaValuation();
        if (delta?.length) delta.sort((a, b) => a.expiration - b.expiration);
        setData(delta || []);
    }, [optionService]);

    useEffect(() => {
        if (optionService) {
            updateDeltaValuation();
        }
    }, [optionService, updateDeltaValuation]);

    useInterval(() => updateDeltaValuation(), 1_000); // 1s

    const getCash = (data) => {
        if (data && data.length) return data[0].cash;
        return 0;
    };

    useEffect(() => {
        const totalDelta = {};
        const result = {
            options: getCash(data),
            futures: 0,
            commissions: 0,
            expValue: 0,
            openOrders: 0,
            total: getCash(data),
        };
        if (data && data.length) {
            data.forEach((datum) => {
                result.options += datum.options;
                result.futures += datum.futures;
                result.commissions += datum.commissions;
                result.expValue += datum.expiration_value;
                result.openOrders += datum.open_orders;
                result.total += datum.total;

                totalDelta[datum.expiration] = datum.total;
            });
        }
        totalDelta[0] = getCash(data) - result.expValue;
        totalDelta[1] = result.total - result.expValue;
        setTotalRow(result);

        setTotalDelta(totalDelta);
    }, [data]);

    return (
        <Table striped bordered hover className="table-valuation">
            <thead>
                <tr>
                    <th className="mr-0">
                        <TooltipHeader tooltip={"Expiration"} display={"Expiration"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Options"} display={"Options"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Futures"} display={"Futures"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Commissions"} display={"Comm"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Expiration Value"} display={"ExpVal"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Total"} display={"Total"} />
                    </th>
                    <th className="pr-1 pl-1">
                        <TooltipHeader tooltip={"Open Orders"} display={"OpenOrds"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Future Position Size"} display={"FPosSize"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Price Use In Calculate Unsettled Cash"} display={"PriceUns"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Future Current Price"} display={"CurPrice"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Future LastSettled Price"} display={"LastPrice"} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {data?.length ? (
                    data.map((datum, index) => (
                        <tr key={index}>
                            <td>{datum.expiration ? moment(datum.expiration).format("MMM DD YYYY") : ""}</td>
                            <td>{formatMoney(datum.options, 4)}</td>
                            <td>{formatMoney(datum.futures, 4)}</td>
                            <td>{formatMoney(datum.commissions, 4)}</td>
                            <td>{formatMoney(datum.expiration_value, 4)}</td>
                            <td>{formatMoney(datum.total, 4)}</td>
                            <td>{formatMoney(datum.open_orders, 4)}</td>
                            <td>{datum.future_position_size}</td>
                            <td>{datum.price_in_calc_unsettled_cash}</td>
                            <td>{datum.future_current_price}</td>
                            <td>{datum.future_last_settled_price}</td>
                        </tr>
                    ))
                ) : (
                    <tr />
                )}

                <tr>
                    <td>None</td>
                    <td>{formatMoney(getCash(data), 4)}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{formatMoney(-totalRow.expValue, 4)}</td>
                    <td>{formatMoney(getCash(data) - totalRow.expValue, 4)}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>

                <tr>
                    <td>Total</td>
                    <td>{formatMoney(totalRow.options, 4)}</td>
                    <td>{formatMoney(totalRow.futures, 4)}</td>
                    <td>{formatMoney(totalRow.commissions, 4)}</td>
                    <td>0</td>
                    <td>{formatMoney(totalRow.total - totalRow.expValue, 4)}</td>
                    <td>{formatMoney(totalRow.openOrders, 4)}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default DeltaValuation;
