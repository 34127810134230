let option;

console.log(process.env.REACT_APP_OPTION_INTEGRATE);

if (!process.env) {
    option = require("./moc_api");
} else {
    switch (process.env.REACT_APP_OPTION_INTEGRATE) {
        case "cache":
            option = require("./cache_option_api");
            break;
        case "deribit":
            option = require("./deribit_option_api");
            break;
        default:
            option = require("./moc_api");
            break;
    }
}

export default option.OptionService;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utils = require("./option_utils");

export { option, utils };

const HardCrossMispricedOptionLogType = "mispriced_option";
const HardCrossFirstOptionLogType = "first_option_result";
const HardCrossSecondOptionLogType = "second_option_result";
const HardCrossMinAmountLogType = "min_amount";
const HardCrossFutureOrderLogType = "future_order_result";
const HardCrossFutureOrderBreakdownLogType = "future_order_breakdown";
const HardCrossCashFlowLogType = "total_cash_flow";
const HardCrossCashFlowFutureOrderLogType = "cash_flow_order_result";
const HardCrossMetricsLogType = "metrics";

export {
    HardCrossMispricedOptionLogType,
    HardCrossFirstOptionLogType,
    HardCrossSecondOptionLogType,
    HardCrossMinAmountLogType,
    HardCrossFutureOrderLogType,
    HardCrossFutureOrderBreakdownLogType,
    HardCrossCashFlowLogType,
    HardCrossCashFlowFutureOrderLogType,
    HardCrossMetricsLogType,
};
