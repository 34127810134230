import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import PaginateTable from "./PaginateTable";
import OptionService from "app/services/option";
import useInterval from "app/services/useInterval";

const MispricingTrades = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [positions, setPositions] = useState([]);

    useInterval(() => getPositions(), 1000);

    const getPositions = async () => {
        let data = await optionService.getMispricedPositions();
        data = data.map((datum) => {
            return {
                ...datum,
                // toFixed(1)
            };
        });
        setPositions(data);
    };

    const mispricingTradeColumns = [
        { Header: "Symbol", accessor: "Symbol" },
        { Header: "Kind", accessor: "Kind" },
        { Header: "Side", accessor: "Side" },
        { Header: "Size", accessor: "Size" },
        { Header: "SizeCurrency", accessor: "SizeCurrency" },
        { Header: "AveragePrice", accessor: "AveragePrice" },
        { Header: "TotalPnl", accessor: "TotalPnl" },
        { Header: "RealizedPnl", accessor: "RealizedPnl" },
        { Header: "InitialMargin", accessor: "InitialMargin" },
        { Header: "MaintenanceMargin", accessor: "MaintenanceMargin" },
        { Header: "Delta", accessor: "Delta" },
        { Header: "IndexPrice", accessor: "IndexPrice" },
        { Header: "SettlementPrice", accessor: "SettlementPrice" },
    ];

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    {positions?.length ? (
                        <PaginateTable
                            columns={mispricingTradeColumns}
                            data={positions}
                            className="w-100 text-center"
                        />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default MispricingTrades;
