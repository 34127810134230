import { useState, useEffect, useCallback } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import useInterval from "app/services/useInterval";
import { formatMoney } from "app/helper/function";

const initialPosition = {
    timestamp: 0,
    cash: 0,
    cash_usd: 0,
    options: 0,
    options_usd: 0,
    futures: 0,
    futures_usd: 0,
    commissions: 0,
    commissions_usd: 0,
    total: 0,
    total_usd: 0,
};

const TooltipHeader = ({ tooltip, display }) => (
    <OverlayTrigger key={"top"} placement={"top"} overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}>
        <div>{display}</div>
    </OverlayTrigger>
);

const PositionValuation = ({ optionService }) => {
    const [data, setData] = useState(initialPosition);

    const updateHardCrossStatus = useCallback(async () => {
        const p = await optionService.GetPositionValuation();
        setData(p || initialPosition);
    }, [optionService]);

    useEffect(() => {
        if (optionService) {
            updateHardCrossStatus();
        }
    }, [optionService, updateHardCrossStatus]);

    useInterval(() => updateHardCrossStatus(), 1_000);

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        <TooltipHeader tooltip={"Component"} display={"Component"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"BTC Valuation"} display={"BTC"} />
                    </th>
                    <th>
                        <TooltipHeader tooltip={"Dollar Valuation"} display={"Dollar"} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Cash</td>
                    <td>{formatMoney(data.cash, 4)}</td>
                    <td>{formatMoney(data.cash_usd, 1)}</td>
                </tr>

                <tr>
                    <td>Options</td>
                    <td>{formatMoney(data.options, 4)}</td>
                    <td>{formatMoney(data.options_usd, 1)}</td>
                </tr>

                <tr>
                    <td>Futures</td>
                    <td>{formatMoney(data.futures, 4)}</td>
                    <td>{formatMoney(data.futures_usd, 1)}</td>
                </tr>

                <tr>
                    <td>Commissions</td>
                    <td>{formatMoney(data.commissions, 4)}</td>
                    <td>{formatMoney(data.commissions_usd, 1)}</td>
                </tr>

                <tr>
                    <td>Total</td>
                    <td>{formatMoney(data.total, 4)}</td>
                    <td>{formatMoney(data.total_usd, 1)}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default PositionValuation;
