import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OptionService from "app/services/option";
import ReactJson from "react-json-view";
import { InputGroup, Form, Button } from "react-bootstrap";
import Select from "react-select";

const BOOK_SIDE_OPTION = [
    { value: "bid", label: "Bid" },
    { value: "ask", label: "Ask" },
];
const ACTION_OPTION = [
    { value: "add", label: "Add" },
    { value: "delete", label: "Delete" },
    { value: "change", label: "Change" },
];

const UpdateOrderbook = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [isLoading, setLoading] = useState(false);
    const [instrumentName, setInstrumentName] = useState({});
    const [bookSide, setBookSide] = useState(BOOK_SIDE_OPTION[0]);
    const [action, setAction] = useState(ACTION_OPTION[0]);
    const [price, setPrice] = useState(0);
    const [amount, setAmount] = useState(0);
    const [data, setData] = useState({});

    const [instrumentOption, setInstrumentOption] = useState([]);

    useEffect(() => {
        if (optionService) {
            getAllInstruments();
        }
    }, [optionService]);

    const getAllInstruments = async () => {
        const optionInstruments = await optionService.LoadOptionInstruments();
        const futuresInstruments = await optionService.LoadFutureInstrument();
        setInstrumentOption([...futuresInstruments, ...optionInstruments]);
    };

    const submit = () => {
        setLoading(true);
        setData({});

        optionService
            .UpdateOrderbookForInstrument([instrumentName.value || "", bookSide.value, action.value, price, amount])
            .then((res) => {
                setData(res);
                setLoading(false);
            })
            .catch(() => {
                setLoading(true);
                setData({
                    err: "Internal server error, please try again!",
                });
            });
    };

    return (
        <div className="global_config">
            <div className="row">
                <div className="col-6">
                    {" "}
                    <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Instrument Name</div>
                        </div>
                        <Select
                            options={instrumentOption.map((i) => {
                                return {
                                    value: i.instrument_name,
                                    label: i.instrument_name,
                                };
                            })}
                            className="select-in-form-control"
                            classNamePrefix="custom_select"
                            value={instrumentName}
                            onChange={(e) => setInstrumentName(e)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Book Side</div>
                        </div>

                        <Select
                            options={BOOK_SIDE_OPTION}
                            className="select-in-form-control"
                            classNamePrefix="custom_select"
                            value={bookSide}
                            onChange={(e) => setBookSide(e)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Action</div>
                        </div>

                        <Select
                            options={ACTION_OPTION}
                            className="select-in-form-control"
                            classNamePrefix="custom_select"
                            value={action}
                            onChange={(e) => setAction(e)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Price</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            value={price}
                            onChange={(e) => setPrice(+e.target.value)}
                            type="number"
                            step="0.01"
                            placeholder="Price"
                            aria-label="Price"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Amount</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            value={amount}
                            onChange={(e) => setAmount(+e.target.value)}
                            type="number"
                            placeholder="Total Amount"
                            aria-label="Total Amount"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className="col-1" />
                <div className="col-5">
                    <div>Data</div>
                    <ReactJson
                        src={data}
                        name={false}
                        collapseStringsAfterLength={100}
                        indentWidth={2}
                        displayDataTypes={false}
                    />
                </div>
            </div>

            <Button className="mt-2 mr-1" variant="primary" onClick={submit} disabled={isLoading}>
                {isLoading && <div className="spinner-border spinner-border-sm" />} Submit
            </Button>
            <br />
        </div>
    );
};

export default UpdateOrderbook;
