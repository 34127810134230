import { call, delay, put, select, takeEvery, fork, takeLatest, take, cancel } from "redux-saga/effects";
import produce from "immer";

import OptionService from "app/services/option";
import * as actions from "app/actions/option";
import * as orderbookActions from "app/actions/orderbook";
import * as constants from "app/actions/constants";

const getSecret = (state) => state.global.secret;
const getCurrency = (state) => state.global.currency;

function* pollConfigsTask() {
    while (true) {
        yield delay(3_000);

        try {
            yield updateSystemConfig();

            const secret = yield select(getSecret);
            const currency = yield select(getCurrency);
            const optionService = new OptionService({ secret, currency });

            const futureOrderbook = yield call(optionService.GetFutureOrderbook);

            yield put(actions.SaveFutureOrderbook(futureOrderbook));

            if (futureOrderbook && futureOrderbook.length) {
                const orderbook = {};
                futureOrderbook.forEach((f) => {
                    orderbook[f.instrument] = {
                        ask: f.first_level.ask,
                        bid: f.first_level.bid,
                    };
                });

                yield put(orderbookActions.UpdateAllOrderbook(orderbook));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

function* watchForEndingPollingTask() {
    while (true) {
        const _ = yield take(constants.Action_EndPollingConfigs);
        return true;
    }
}

function* pollingConfigSaga() {
    const startTask = yield fork(pollConfigsTask);
    const ended = yield call(watchForEndingPollingTask);

    if (ended) {
        yield cancel(startTask);
    }
}

function* updateSystemConfig() {
    try {
        const secret = yield select(getSecret);
        const currency = yield select(getCurrency);

        const optionService = new OptionService({ secret, currency });

        const systemConfig = yield call(optionService.LoadSystemConfig);

        const calculatedSystemConfig = calculateMinSynthAskAndMaxSynthBid(systemConfig);

        yield put(actions.SaveSystemConfig(calculatedSystemConfig));
    } catch (error) {
        console.error(error);
    }
}

function calculateMinSynthAskAndMaxSynthBid(data) {
    if (data && data.series && data.series.length) {
        const newData = produce(data, (draft) => {
            draft.series = draft.series.map((item) => {
                if (item.strikes?.length) {
                    let minSynthAsk = null;
                    let maxSynthBid = null;

                    item.strikes.forEach((strike, index) => {
                        if (index === 0) {
                            minSynthAsk = strike.synth_ask;
                            maxSynthBid = strike.synth_bid;
                        } else {
                            if (strike.synth_ask < minSynthAsk) {
                                minSynthAsk = strike.synth_ask;
                            }

                            if (strike.synth_bid > maxSynthBid) {
                                maxSynthBid = strike.synth_bid;
                            }
                        }
                    });

                    item.min_synth_ask = minSynthAsk;
                    item.max_synth_bid = maxSynthBid;
                }
                return item;
            });
        });

        return newData;
    }

    return data;
}

export default function* rootSaga() {
    yield takeEvery(constants.Action_UpdateSystemConfig, updateSystemConfig);
    yield takeLatest(constants.Action_StartPollingConfigs, pollingConfigSaga);
}
