import { useState, useEffect, useCallback } from "react";
import useInterval from "app/services/useInterval";
import moment from "moment";

const LastMarketUpdate = ({ optionService }) => {
    const [lastUpdate, setLastUpdate] = useState(moment().format("x"));

    const updateDeltaValuation = useCallback(async () => {
        const data = await optionService.getLastMarketUpdate();
        setLastUpdate(data);
    }, [optionService]);

    useEffect(() => {
        if (optionService) {
            updateDeltaValuation();
        }
    }, [optionService, updateDeltaValuation]);

    const NumberOfMinute = (time) => {
        const ms = moment().format("x") - time;
        const minutes = ms > 0 ? ms / 60_000 : 0;
        return Math.floor(minutes);
    };

    useInterval(() => updateDeltaValuation(), 5 * 1000);

    return (
        <div className="pt-3 pl-3">
            <p>Time of Last Received Market Update</p>
            <p className="mb-1">
                <span>{moment(lastUpdate).format("HH:mm")}</span>
                <span style={{ paddingLeft: "50px" }}>{NumberOfMinute(lastUpdate)} Min. from Current Time</span>
            </p>
            {NumberOfMinute(lastUpdate) >= 2 && <span>Warning: Stale Data</span>}
        </div>
    );
};

export default LastMarketUpdate;
