import { useState, useEffect, useRef } from "react";
import { InputGroup, Container, Col, Row, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import cx from "classnames";

import { toast } from "app/components/_base/Toast/slice";
import Button from "app/components/_base/Button";
import DateTimeInput from "app/components/_base/DateTimeInput";
import OptionService from "app/services/option";

import PaginateTable from "./PaginateTable";
import axios from "axios";

const Columns = [
    {
        Header: "Trade Seq",
        accessor: "trade_seq",
    },
    {
        Header: "Trade ID",
        accessor: "trade_id",
    },
    {
        Header: "Timestamp",
        accessor: "timestamp",
    },
    {
        Header: "Date",
        accessor: "date",
    },
    {
        Header: "Tick Direction",
        accessor: "tick_direction",
    },
    {
        Header: "Price",
        accessor: "price",
    },
    {
        Header: "Mark Price",
        accessor: "mark_price",
    },
    {
        Header: "IV",
        accessor: "iv",
    },
    {
        Header: "Instrument_name",
        accessor: "instrument_name",
    },
    {
        Header: "Index Price",
        accessor: "index_price",
    },
    {
        Header: "Direction",
        accessor: "direction",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
];

const mapping = (trade) => {
    const date = new Date(trade.timestamp).toISOString();
    return {
        ...trade,
        date,
    };
};

const getDeribitTrades = async (instrumentName, startTime, endTime) => {
    const getTrades = async (start_timestamp, end_timestamp) => {
        const baseURL =
            process.env.REACT_APP_ENVIRONMENT === "Testnet"
                ? "https://test.deribit.com/api/v2"
                : "https://www.deribit.com/api/v2";
        const endpoint = "/public/get_last_trades_by_instrument_and_time";

        const params = {
            instrument_name: instrumentName,
            start_timestamp,
            end_timestamp,
            sorting: "desc",
            count: 1000,
        };

        const url = `${baseURL}${endpoint}?${new URLSearchParams(params).toString()}`;

        const response = await axios
            .get(url)
            .then((res) => res.data)
            .catch((err) => {
                const e = err.response.data.error;
                throw new Error(e.message + " " + JSON.stringify(e.data));
            });
        return response;
    };

    const trades = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const response = await getTrades(startTime, endTime);
        const newTrades = response.result.trades;

        if (Array.isArray(newTrades)) {
            trades.push(...newTrades);
        } else {
            throw new Error("Cannot parse trades to array");
        }

        if (!response.result.has_more) {
            break;
        }

        // Data is fetched in DESC order so timestamp is DESC
        // startTime remains
        endTime = newTrades[newTrades.length - 1].timestamp - 1;
    }

    return trades;
};

const DeribitTrades = ({ className }) => {
    const dispatch = useDispatch();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [selectedOption, setSelectedOption] = useState("");
    const [trades, setTrades] = useState([]);
    const csvRef = useRef();

    const [startTime, setStartTime] = useState(Date.now() - 24 * 60 * 60 * 1000);
    const [endTime, setEndTime] = useState(Date.now());
    const [isLoading, setLoading] = useState(false);

    const handleClickGetDeribitTrades = async () => {
        if (!selectedOption) {
            return;
        }

        setLoading(true);
        try {
            const trades = await getDeribitTrades(selectedOption, startTime, endTime);

            dispatch(toast({ type: "success", text: "Fetch successfully" }));

            setTrades(trades.map((trade) => mapping(trade)));
        } catch (e) {
            console.error("Error while fetching deribit trades: ", e);
            dispatch(toast({ type: "error", text: `${e}` }));
        }

        setLoading(false);
    };

    const handleClickExportCSV = async () => {
        await handleClickGetDeribitTrades();
        if (csvRef.current) {
            csvRef.current.link.click();
        }
    };

    return (
        <div className={cx("global_config", className)}>
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Instrument Name</InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                id="inlineFormInputGroup"
                                value={selectedOption}
                                onChange={(e) => setSelectedOption(e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Start Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={startTime} onDateChange={(date) => setStartTime(date.getTime())} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>End Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={endTime} onDateChange={(date) => setEndTime(date.getTime())} />
                        </InputGroup>
                    </Col>
                </Row>
                <div className="buttonRow">
                    <div className="group">
                        <Button.Fill
                            disabled={!selectedOption}
                            loading={isLoading}
                            size="s"
                            onClick={handleClickGetDeribitTrades}
                        >
                            Get Deribit Trades
                        </Button.Fill>
                    </div>

                    <div className="group">
                        <Button.Fill
                            disabled={isLoading || !selectedOption}
                            color="secondary"
                            size="s"
                            onClick={handleClickExportCSV}
                        >
                            Export CSV
                        </Button.Fill>
                    </div>
                </div>
                <br />

                <div>
                    <h2>Trades</h2>

                    <PaginateTable columns={Columns} data={trades} />
                </div>

                {selectedOption && (
                    <CSVLink data={trades} filename={`${selectedOption}_${startTime}_${endTime}.csv`} ref={csvRef} />
                )}
            </Container>
        </div>
    );
};

export default styled(DeribitTrades)`
    padding-top: 30px;

    .buttonRow {
        display: flex;
        justify-content: space-between;

        .group {
            display: flex;
            column-gap: 0.5em;
        }
    }

    .instrumentNameSelect {
        flex: 1 1;

        .select__control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`;
