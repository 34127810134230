import styled from "styled-components";

interface IProps {
    className?: string;
    pageSize: number;
    total: number;
    pageIndex: number;
    pageCount: number;
    setPageIndex: (index: number) => void;
    setPageSize: (size: number) => void;
}

const Paginator: React.FC<IProps> = ({
    total,
    className,
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    pageCount,
}) => {
    const safeSetPageIndexWithEventValue = (value: string) => {
        const n = parseInt(value, 10);

        if (String(n) !== value) {
            return;
        }

        setPageIndex(n - 1);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const value = (e.target as HTMLInputElement).value;
            safeSetPageIndexWithEventValue(value);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        safeSetPageIndexWithEventValue(e.target.value);
    };

    return (
        <div className={className}>
            <div className="group">
                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 50, 100, 200, 500].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>

                <span>Total: {total}</span>
            </div>

            <div className="group">
                <div className="svgButton" onClick={() => setPageIndex(0)}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            d="M18.29 17.29a.996.996 0 0 0 0-1.41L14.42 12l3.88-3.88a.996.996 0 1 0-1.41-1.41L12.3 11.3a.996.996 0 0 0 0 1.41l4.59 4.59c.38.38 1.01.38 1.4-.01z"
                            fill="currentColor"
                        />
                        <path
                            d="M11.7 17.29a.996.996 0 0 0 0-1.41L7.83 12l3.88-3.88a.996.996 0 1 0-1.41-1.41L5.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59c.38.38 1.01.38 1.4-.01z"
                            fill="currentColor"
                        />
                    </svg>
                </div>

                <div className="svgButton" onClick={() => setPageIndex(pageIndex > 0 ? pageIndex - 1 : pageIndex)}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            d="M14.71 15.88L10.83 12l3.88-3.88a.996.996 0 1 0-1.41-1.41L8.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0c.38-.39.39-1.03 0-1.42z"
                            fill="currentColor"
                        />
                    </svg>
                </div>

                <div className="currentPage">
                    Page&nbsp;
                    <b>
                        {pageIndex + 1} of {pageCount}
                    </b>
                </div>

                <div
                    className="svgButton"
                    onClick={() => setPageIndex(pageIndex < pageCount - 1 ? pageIndex + 1 : pageIndex)}
                >
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            d="M9.29 15.88L13.17 12L9.29 8.12a.996.996 0 1 1 1.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3a.996.996 0 0 1-1.41 0c-.38-.39-.39-1.03 0-1.42z"
                            fill="currentColor"
                        />
                    </svg>
                </div>

                <div className="svgButton" onClick={() => setPageIndex(pageCount - 1)}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            d="M5.7 6.71a.996.996 0 0 0 0 1.41L9.58 12L5.7 15.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L7.12 6.71c-.39-.39-1.03-.39-1.42 0z"
                            fill="currentColor"
                        />
                        <path
                            d="M12.29 6.71a.996.996 0 0 0 0 1.41L16.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L13.7 6.7c-.38-.38-1.02-.38-1.41.01z"
                            fill="currentColor"
                        />
                    </svg>
                </div>

                <div>
                    Go to page:&nbsp;
                    <input
                        type="number"
                        min={0}
                        max={total - 1}
                        defaultValue={pageIndex + 1}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        style={{ width: "100px" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default styled(Paginator)`
    width: 100%;
    height: 42px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .group {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
    }

    .svgButton {
        color: #bdc3c7;
        cursor: pointer;

        transition: color 0.1s ease-in-out;
        user-select: none;

        &:hover {
            color: #2c3e50;
        }
    }
`;
