export const Action_GetUserConfig = "GET_USER_CONFIG";
export const Action_SaveUserConfig = "SAVE_USER_CONFIG";
export const Action_SaveSystemConfig = "SAVE_SYSTEM_CONFIG";
export const Action_UpdateSystemConfig = "UPDATE_SYSTEM_CONFIG";
export const Action_SaveFutureOrderbook = "SAVE_FUTURE_ORDERBOOK";
export const Action_ChangeMinDayExpiry = "CHANGE_MIN_DAY_EXPIRY";
export const Action_ChangeRiskFreeRate = "CHANGE_RISK_FREE_RATE";
export const Action_ChangeDividendRate = "CHANGE_DIVIDEND_RATE";
export const Action_ChangePastDealSec = "CHANGE_PAST_DEAL_SEC";
export const Action_ChangePastDealPriceDiff = "CHANGE_PAST_DEAL_PRICE_DIFF";
export const Action_UpdateFutureContractAsPricing = "UPDATE_FUTURE_CONTRACT_AS_PRICING";
export const Action_ChangeIv = "CHANGE_IV";
export const Action_UpdatePricingContractOffset = "UPDATE_PRICING_CONTRACT_OFFSET";
export const Action_UpdatePricingContractOffsetPercent = "UPDATE_PRICING_CONTRACT_OFFSET_PERCENT";
export const Action_UpdateOrderbook = "UPDATE_ORDERBOOK";
export const Action_UpdateAllOrderbook = "UPDATE_ALL_ORDERBOOK";
export const Action_UpdateIvs = "UPDATE_IVS";
export const Action_ConvertUserConfig = "CONVERT_USER_CONFIG";
export const Action_ToggleExpandAllSeries = "EXPAND_ALL_SERIES";
export const Action_SetGamma = "SET_GAMMA";
export const Action_SetExpand = "SET_EXPAND";
export const Action_SaveSecret = "SAVE_SECRET";
export const Action_SaveCurrency = "SAVE_CURRENCY";
export const Action_UpdateAutoRunConfig = "UPDATE_AUTO_RUN_CONFIG";
export const Action_StartPollingConfigs = "START_POLLING_CONFIGS";
export const Action_EndPollingConfigs = "END_POLLING_CONFIGS";
