import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";
import DateTimeInput from "app/components/_base/DateTimeInput";
import { CSVLink } from "react-csv";
import PaginateTable from "./PaginateTable";

import OptionService from "app/services/option";
import { formatMoney } from "app/helper/function";
import moment from "moment";

const OffsetStatistics = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 24 * 60 * 60 * 1000); // 24 hours
    const [toTime, setToTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const [csvData, setCsvData] = useState([]);
    const [offsetStatistics, setOffsetStatistics] = useState([]);

    const getOffsetStatistics = async () => {
        setLoading(true);
        let arr = [];
        setOffsetStatistics(arr);
        const currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();

        const duration = 136e5; // ~ 5k record
        const step = Math.ceil((currentToTime - currentFromTime) / duration);

        for (let i = 0; i < step; i++) {
            const upperBound = currentFromTime + (i + 1) * duration;
            const to = upperBound > currentToTime ? currentToTime : upperBound;
            let data = await optionService.GetOffsetStatistics(currentFromTime + i * duration, to);
            data = data.map((datum) => {
                return {
                    ...datum,
                    bid_price: formatMoney(datum.bid_price, 1),
                    ask_price: formatMoney(datum.ask_price, 1),
                    bid_qty: formatMoney(datum.bid_qty, 1),
                    ask_qty: formatMoney(datum.ask_qty, 1),
                    average_bid: formatMoney(datum.average_bid, 1),
                    average_ask: formatMoney(datum.average_ask, 1),
                    theoretical_offset: formatMoney(datum.theoretical_offset, 2),
                    expiration: datum.expiration,
                    expiration_date: moment(datum.expiration).format("DD-MM-YYYY HH:mm:ss"),
                };
            });
            arr = [...arr, ...data];
            arr = arr.sort(
                (a, b) =>
                    b.timestamp - a.timestamp ||
                    (a.contract_type == "perpetual" ? -1 : 0) ||
                    a.expiration - b.expiration
            );
            setOffsetStatistics(arr);
        }

        setLoading(false);
        return arr;
    };

    const csvInstance = useRef(null);

    const offsetStatisticsColumns = [
        {
            Header: "Timestamp",
            accessor: "timestamp",
        },
        {
            Header: "Future Name",
            accessor: "future_name",
        },
        {
            Header: "Best Bid",
            accessor: "bid_price",
        },
        {
            Header: "Best Bid Qty",
            accessor: "bid_qty",
        },
        {
            Header: "Best Ask",
            accessor: "ask_price",
        },
        {
            Header: "Best Ask Qty",
            accessor: "ask_qty",
        },
        {
            Header: `Average Bid (0.1 ${currency})`,
            accessor: "average_bid",
        },
        {
            Header: `Average Ask (0.1 ${currency})`,
            accessor: "average_ask",
        },
        {
            Header: "Expiration",
            accessor: "expiration_date",
        },
        {
            Header: "Contract Type",
            accessor: "contract_type",
        },
        {
            Header: "Theoretical Offset",
            accessor: "theoretical_offset",
        },
    ];

    const exportOffsetStatistics = async () => {
        const offsetStatistics = await getOffsetStatistics();
        await exportCSV(offsetStatistics, offsetStatisticsColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => item[c.accessor]);
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getOffsetStatistics}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Offset Statistics
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportOffsetStatistics}
                            disabled={loading}
                        >
                            Export Offset Statistics
                        </Button>

                        <CSVLink
                            data={csvData}
                            filename={`Offset_Statistics_${fromTime}_${toTime}.csv`}
                            ref={csvInstance}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    {offsetStatistics?.length ? (
                        <PaginateTable
                            columns={offsetStatisticsColumns}
                            data={offsetStatistics}
                            className="w-100 text-center"
                        />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default OffsetStatistics;
