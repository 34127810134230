import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, ButtonGroup } from "react-bootstrap";
import AmCharts from "@amcharts/amcharts3-react";
import OptionService from "app/services/option";
import moment from "moment";
import sortBy from "lodash/sortBy";

const instrumentNameRegex = /^(BTC|ETH)-(?<day>\d{2})(?<month>\w{3})(?<year>\d{2})$/; // No g flag
const QUARTERLY_MONTHS = ["mar", "jun", "sep", "dec"];
const EXPIRATION_TIMESTAMP = "expiration_timestamp";
const INSTRUMENT_NAME = "instrument_name";

const COLORS = ["#3498db", "#9b59b6", "#f39c12", "#333333", "#bdc3c7", "#e74c3c", "#34495e", "#7f8c8d"];

const PERIOD_CONFIG = {
    DAY: {
        label: "Day",
        periodTime: 1000 * 60 * 60 * 24,
        intervalTime: 1000 * 60, //1m,
    },
    WEEK: {
        label: "Week",
        periodTime: 1000 * 60 * 60 * 24 * 7,
        intervalTime: 1000 * 60 * 10, //10m
    },
    MONTH: {
        label: "Month",
        periodTime: 1000 * 60 * 60 * 24 * 30,
        intervalTime: 1000 * 60 * 30, //30m
    },
    "6MONTHS": {
        label: "6 Months",
        periodTime: 1000 * 60 * 60 * 24 * 120,
        intervalTime: 1000 * 60 * 30 * 6, //30m
    },
};

function getTableConfig(data, keysAndLabels, leadingContractName = "Leading contract") {
    const graphs = keysAndLabels.map(({ key, label }, i) => {
        return {
            valueAxis: "v1",
            title: label,
            balloonText: "[[title]]: <b>[[value]]</b>",
            bullet: "round",
            bulletSize: 5,
            bulletBorderColor: "#ffffff",
            bulletBorderAlpha: 1,
            bulletBorderThickness: 2,
            valueField: key,
            hideBulletsCount: 120,
            position: "left",
            lineColor: COLORS[i],
        };
    });

    graphs.push({
        valueAxis: "v2",
        title: leadingContractName,
        balloonText: "[[title]]: <b>[[value]]</b>",
        bullet: "round",
        bulletSize: 5,
        bulletBorderColor: "#ffffff",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 2,
        valueField: "leadingContractPrice",
        hideBulletsCount: 120,
        position: "right",
        lineColor: "#6ab04c",
    });

    const config = {
        type: "serial",
        theme: "light",
        connect: false,
        dataProvider: data,
        gridAboveGraphs: true,
        categoryField: "date",
        dataDateFormat: "YYYY-MM-DD",
        pathToImages: "https://cdn.amcharts.com/lib/3/images/",

        valueAxes: [
            {
                id: "v1",
                axisColor: "#FF6600",
                axisThickness: 2,
                axisAlpha: 1,
                position: "left",
            },

            {
                id: "v2",
                axisColor: "#B0DE09",
                axisThickness: 2,
                gridAlpha: 0,
                axisAlpha: 1,
                position: "right",
            },
        ],

        graphs,

        chartScrollbar: {
            graph: "v1",
            oppositeAxis: false,
            offset: 30,
            scrollbarHeight: 30,
            backgroundAlpha: 0,
            selectedBackgroundAlpha: 0.1,
            selectedBackgroundColor: "#888888",
            graphFillAlpha: 0,
            graphLineAlpha: 0.5,
            selectedGraphFillAlpha: 0,
            selectedGraphLineAlpha: 1,
            autoGridCount: true,
            color: "#AAAAAA",
        },

        categoryAxis: {
            parseDates: true,
            dashLength: 1,
            minorGridEnabled: true,
            minPeriod: "fff",
            dateFormats: [
                {
                    period: "fff",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "ss",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "mm",
                    format: "JJ:NN",
                },
                {
                    period: "hh",
                    format: "JJ:NN",
                },
                {
                    period: "DD",
                    format: "MMM DD",
                },
                {
                    period: "WW",
                    format: "MMM DD",
                },
                {
                    period: "MM",
                    format: "MMM",
                },
                {
                    period: "YYYY",
                    format: "YYYY",
                },
            ],
        },
        legend: {
            spacing: 100,
            valueWidth: 70,
        },

        chartCursor: {
            valueBalloonsEnabled: true,
            categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
        },
    };

    return config;
}

const getLastFridayOf = (date = Date.now()) => {
    const d = new Date(date),
        day = d.getUTCDay(),
        hours = d.getUTCHours();

    let diff = 0;

    if (day === 5 /*today is Friday*/ && hours >= 8) {
        diff = 0;
    } else {
        // nearest Friday
        diff = day <= 5 ? 7 - 5 + day : day - 5;
    }

    d.setUTCDate(d.getUTCDate() - diff);
    d.setUTCHours(8); // contract expires at 8AM UTC time
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);

    const time = d.getTime();

    return time;
};

const getExpiredInstruments = (currency, howMany = 1) => {
    const now = new Date(Date.now());
    const month = now.getUTCMonth();
    const year = now.getUTCFullYear();

    return Array(12)
        .fill(0)
        .map((value, index) => getLastFridayOf(Date.UTC(year, month + 1 - 11 + index, 1)))
        .filter((time) => {
            return time < Date.now();
        })
        .filter((time) => {
            const date = new Date(time);
            const month = date.getUTCMonth();

            return [2, 5, 8, 11].includes(month);
        })
        .slice(-howMany)
        .map((time) => ({
            instrument_name: `${currency.toUpperCase()}-${moment.utc(time).format("DDMMMYY").toUpperCase()}`,
            [EXPIRATION_TIMESTAMP]: time,
        }));
};

const convertInstrumentNameToDate = (name) => {
    const matches = instrumentNameRegex.exec(name);

    const { day, month, year } = matches.groups;

    return { day, month, year };
};

const sortInstrumentsByExpiration = (instruments) => {
    // const getExpiration = (instrument) => instrument["expiration_timestamp"];

    return sortBy(instruments, EXPIRATION_TIMESTAMP);
};

export default function ChartOffset(props) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { secret, currency } = useSelector((state) => state.global);
    const intervalRef = useRef(null);

    const [tableConfig, setTableConfig] = useState(getTableConfig([], []));
    const [period, setPeriod] = useState("DAY");

    const getInstruments = async (optionService) => {
        const originalInstruments = await optionService.getLiveFuture();

        // Not expired yet
        // Take instruments whose name is not perpetual
        const activeInstruments = originalInstruments
            .filter((instrument) => instrument["settlement_period"] === "month")
            // .filter((instrument) => !hasExpired(instrument))
            .filter((instrument) => {
                const { month } = convertInstrumentNameToDate(instrument["instrument_name"]);
                return QUARTERLY_MONTHS.includes(month.toLowerCase());
            });

        // Sort by expiration timestamp
        const instruments = sortInstrumentsByExpiration(activeInstruments);

        return instruments;
    };

    const getSeriesData = useCallback(
        async (period = "DAY") => {
            setLoading(true);
            console.log("getSeriesData");
            const optionService = new OptionService({ secret, currency });

            if (Object.keys(optionService).length === 0) {
                setLoading(false);
                return;
            }
            let leadingContract;

            const toTime = Date.now();
            const fromTime = toTime - PERIOD_CONFIG[period].periodTime;
            const interval = PERIOD_CONFIG[period].intervalTime;

            const activeInstruments = await getInstruments(optionService);

            // these expired instruments are sorted incrementally by timestamp
            const expiredInstruments = getExpiredInstruments(currency);

            // Reverse to get positive offsets
            const allInstruments = [...expiredInstruments, ...activeInstruments].reverse();

            const instrumentSeriesData = await Promise.all(
                allInstruments.map(
                    async (instrument) =>
                        await optionService.GetSeriesData(instrument[EXPIRATION_TIMESTAMP], fromTime, toTime, interval)
                )
            );

            const seriesDataByTimestampMap = new Map();
            instrumentSeriesData.forEach((dataList) => {
                if (dataList) {
                    dataList.forEach((data) => {
                        const { timestamp, future_contract_price: price, future_contract: contract } = data;

                        if (!leadingContract) {
                            leadingContract = data["leading_contract"];
                        }

                        if (!seriesDataByTimestampMap.has(timestamp)) {
                            seriesDataByTimestampMap.set(timestamp, {
                                timestamp,
                            });
                        }

                        const seriesData = seriesDataByTimestampMap.get(timestamp);
                        seriesData[contract] = price;
                    });
                }
            });

            const keysAndLabels = [];

            for (let i = 0; i < allInstruments.length - 1; i++) {
                keysAndLabels.push({
                    label: `${allInstruments[i][INSTRUMENT_NAME]} - ${allInstruments[i + 1][INSTRUMENT_NAME]}`,
                    key: `offset${i}${i + 1}`,
                });
            }

            const plotData = sortBy([...seriesDataByTimestampMap.keys()]).map((timestamp) => {
                const data = seriesDataByTimestampMap.get(timestamp);

                const singlePlotData = {
                    timestamp,
                    date: new Date(timestamp),
                    leadingContractPrice: data[leadingContract],
                };

                for (let i = 0; i < allInstruments.length - 1; i++) {
                    const key = `offset${i}${i + 1}`;

                    const price0 = data[allInstruments[i][INSTRUMENT_NAME]];
                    const price1 = data[allInstruments[i + 1][INSTRUMENT_NAME]];

                    if ((!price0 && price0 !== 0) || (!price1 && price1 !== 0)) {
                        singlePlotData[key] = null;
                    } else {
                        singlePlotData[key] = price0 - price1;
                    }
                }
                return singlePlotData;
            });
            setLoading(false);
            setTableConfig(getTableConfig(plotData, keysAndLabels, leadingContract));
        },
        [currency, secret]
    );

    useEffect(() => {
        getSeriesData(period);

        intervalRef.current = setInterval(() => {
            getSeriesData(period);
        }, 60_000);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [period, getSeriesData]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    return (
        <>
            <Button size="sm" className="mb-1 mr-1" variant="success" onClick={() => setShow(true)}>
                Offset Statistics
            </Button>

            <Modal size="xl" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <ButtonGroup aria-label="select time range">
                        {Object.keys(PERIOD_CONFIG).map((key) => (
                            <Button
                                disabled={loading}
                                key={key}
                                size="sm"
                                className={period === key ? "btn-info" : "btn-light"}
                                onClick={() => setPeriod(key)}
                            >
                                {PERIOD_CONFIG[key].label}
                            </Button>
                        ))}
                    </ButtonGroup>

                    {loading && <div className="spinner-border spinner-border-sm my-auto ml-2" />}
                </Modal.Header>
                <Modal.Body>
                    <AmCharts.React
                        style={{
                            width: "100%",
                            height: "600px",
                        }}
                        options={tableConfig}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}
