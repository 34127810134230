import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { InputGroup, FormControl, Form, Col, Row } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import * as actions from "app/actions/option";
import OptionService from "app/services/option";

import ChangeApiJson from "./ChangeApiJson";
import HistoricalData from "./HistoricalData";
import TradingLogs from "./TradingLogs";
import FutureOrderHandler from "./FutureOrderHandler";
import TradeMetric from "./TradeMetric";
import HardCross from "./HardCross";
import HCAttempt from "./HCAttempts";
import OptionOrderHandler from "./OptionOrderHandler";
import DeribitTrades from "./DeribitTrades";
import MispricingTrades from "./MispricingTrades";
import MarketData from "./MarketData";
import OffsetStatistics from "./OffsetStatistics";
import SimpleOption from "./SimpleOption";
import MispricedPositions from "./MispricedPositions";
import OptionAttempt from "./OptionAttempts";
import DeltaHistories from "./DeltaHistories";
import UpdateOrderbook from "./UpdateOrderbook";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const tabConfig = {
    // first line
    "compare-series": {
        Component: ChangeApiJson,
        title: "Compare series",
    },
    "trading-logs": {
        Component: TradingLogs,
        title: "Trading Logs",
        width: 10.5,
    },
    "future-order-handler": {
        Component: FutureOrderHandler,
        title: "Future Order Handler",
        width: 14.5,
    },
    "hard-cross": {
        Component: HardCross,
        title: "Hard Cross",
    },
    "deribit-trades": {
        Component: DeribitTrades,
        title: "Deribit Trades",
    },
    "market-data": {
        Component: MarketData,
        title: "Market Data",
    },
    "simple-option": {
        Component: SimpleOption,
        title: "Simple Option",
    },
    "mispriced-positions": {
        Component: MispricedPositions,
        title: "Mispriced Positions",
    },
    // second line
    "historical-data": {
        Component: HistoricalData,
        title: "Historical Data",
    },
    "trade-metric": {
        Component: TradeMetric,
        title: "Trade Metric",
        width: 10.5,
    },
    "option-order-handler": {
        Component: OptionOrderHandler,
        title: "Option Order Handler",
        width: 14.5,
    },
    "hc-attempts": {
        Component: HCAttempt,
        title: "HC Attempts",
    },
    "mispricing-trades": {
        Component: MispricingTrades,
        title: "Mispricing Trades",
    },
    "offset-statistics": {
        Component: OffsetStatistics,
        title: "Offset Statistics",
    },
    "option-attempts": {
        Component: OptionAttempt,
        title: "Option Attempts",
    },
    "delta-histories": {
        Component: DeltaHistories,
        title: "Delta Histories",
    },
    // third line
    "update-orderbook": {
        Component: UpdateOrderbook,
        title: "Update Orderbook",
    },
};

const availableTabs = Object.keys(tabConfig);

const Debug = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const currencyQuery = query.get("currency");
    const secretQuery = query.get("secret");

    useEffect(() => {
        dispatch(actions.saveCurrency(currencyQuery || "BTC"));
        dispatch(actions.UpdateSystemConfig());
    }, [currencyQuery, dispatch]);

    useEffect(() => {
        dispatch(actions.saveSecret(secretQuery || ""));
        dispatch(actions.UpdateSystemConfig());
    }, [dispatch, secretQuery]);

    const [isInvalid, setIsInvalid] = useState(false);
    const { tab } = useParams();

    const { secret, currency } = useSelector((state) => state.global);

    useEffect(() => {
        document.title = `${currency} ${process.env.REACT_APP_ENVIRONMENT} Debug | Option trading dashboard`;
    }, [currency]);

    const Component = tabConfig[tab]?.Component;

    function changeConfig(key, value) {
        const config = { secret, currency };
        config[key] = value;
        const url = tab ? `/debug/${tab}` : "/debug";
        history.push(`${url}?secret=${config.secret}&currency=${config.currency}`);
    }

    const checkKey = async () => {
        const optionService = new OptionService({ secret, currency });

        try {
            const result = await optionService.IsSecretKeyCorrect(secret);

            if (result?.success) {
                return setIsInvalid(false);
            }

            setIsInvalid(true);
        } catch (e) {
            setIsInvalid(true);
        }
    };

    useEffect(() => {
        if (!availableTabs.includes(tab)) {
            history.push("/debug");
        }
    }, [history, tab]);

    return (
        <div className="home container">
            <Row>
                <Col xs={9}>
                    <h1>Options Debugger</h1>
                </Col>

                <Col>
                    <Form noValidate>
                        <InputGroup className="p-1">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Currency</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                onChange={(e) => changeConfig("currency", e.target.value)}
                                value={currency}
                            >
                                <option value="BTC">BTC</option>
                                <option value="ETH">ETH</option>
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="p-1">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Key</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                id="inlineFormInputGroup"
                                placeholder="secret key"
                                value={secret}
                                onChange={(e) => changeConfig("secret", e.target.value)}
                                onBlur={checkKey}
                                isInvalid={isInvalid}
                            />
                            <Form.Control.Feedback type="invalid">Your key is invalid!</Form.Control.Feedback>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            <Nav
                // justify
                variant="tabs"
                defaultActiveKey={tab}
                onSelect={(tabId) => {
                    history.push(`/debug/${tabId}?currency=${currency}&secret=${secret}`);
                }}
            >
                {availableTabs.map((tabId) => (
                    <Nav.Item key={tabId} style={{ minWidth: (tabConfig[tabId]?.width || 12.5) + "%" }}>
                        <Nav.Link eventKey={tabId}>{tabConfig[tabId].title}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>

            {Component ? <Component /> : null}
        </div>
    );
};

export default Debug;
