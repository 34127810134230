import React from "react";
import styled from "styled-components";
import cx from "classnames";

import styles from "./styles";

const AlertTriangle = ({ className }) => {
    return (
        <div className={cx(className, "icon")}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1897 12.2546C11.1534 12.7654 10.7393 13.1613 10.2402 13.1613C9.74103 13.1613 9.32688 12.7654 9.29141 12.2546L9.00596 9.52124C8.95481 8.78624 9.52241 8.16124 10.2402 8.16124C10.9587 8.16124 11.5255 8.78624 11.4752 9.52124L11.1897 12.2546ZM10.2402 15.6613C9.78476 15.6613 9.41516 15.2888 9.41516 14.8279C9.41516 14.3671 9.78476 13.9946 10.2402 13.9946C10.6955 13.9946 11.0651 14.3671 11.0651 14.8279C11.0651 15.2888 10.6955 15.6613 10.2402 15.6613ZM18.194 14.9154L12.0098 4.20124C11.2087 2.81457 9.27408 2.81457 8.47301 4.20124L2.28798 14.9154C1.46381 16.3438 2.45381 18.1613 4.05678 18.1613H16.426C18.029 18.1613 19.019 16.3438 18.194 14.9154Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default styled(AlertTriangle)`
    ${styles}

    color: #FF821E;

    width: 24px;
    height: 24px;
`;
