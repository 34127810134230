import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import configReducer from "./configReducer";
import globalReducer from "./globalReducer";
import gammasReducer from "./gammasReducer";
import expandReducer from "./expandReducer";
import orderbookReducer from "./orderbookReducer";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import toastSlice from "app/components/_base/Toast/slice";

const rootReducer = combineReducers({
    config: persistReducer(
        {
            key: "config",
            storage,
        },
        configReducer
    ),

    global: persistReducer(
        {
            key: "global",
            storage,
            stateReconciler: autoMergeLevel2,
        },
        globalReducer
    ),

    gammas: persistReducer(
        {
            key: "gammas",
            storage,
            stateReconciler: autoMergeLevel2,
        },
        gammasReducer
    ),

    expand: persistReducer(
        {
            key: "expand",
            storage,
            stateReconciler: autoMergeLevel2,
        },
        expandReducer
    ),

    orderbook: orderbookReducer,
    [toastSlice.name]: toastSlice.reducer,
});

export default rootReducer;
