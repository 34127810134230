import React from "react";
import cx from "classnames";
import styled, { keyframes } from "styled-components";

const SIZE = { xl: 48, l: 32, m: 20, s: 16 };

export type SpinnerColor = "light" | "dark" | "warning";
export type SpinnerSize = keyof typeof SIZE;

interface IProps {
    className?: string;
    color?: SpinnerColor;
    size?: SpinnerSize;
    duration?: number;
}

const Spinner: React.FC<IProps> = ({ className, duration = 1200, size = "m", color = "light" }) => {
    return (
        <svg
            className={cx(className, size, color)}
            viewBox={`0 0 ${SIZE[size]} ${SIZE[size]}`}
            style={{
                animationDuration: `${duration}ms`,
            }}
        >
            <circle className="path" fill="none" />
        </svg>
    );
};

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

export default styled(Spinner)`
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ${rotate};
    z-index: 2;

    .path {
        stroke-linecap: round;
        animation: ${dash} 1.5s ease-in-out infinite;
    }

    &.light .path {
        stroke: #2a66f4;
    }
    &.dark .path {
        stroke: rgba(1, 18, 34, 0.3);
    }

    &.warning .path {
        stroke: #e60a32;
    }

    &.xl {
        width: 48px;
        height: 48px;

        .path {
            cx: 24px;
            cy: 24px;
            r: 20px;
            stroke-width: 4px;
        }
    }

    &.l {
        width: 32px;
        height: 32px;

        .path {
            cx: 16px;
            cy: 16px;
            r: 13px;
            stroke-width: 3px;
        }
    }

    &.m {
        width: 20px;
        height: 20px;

        .path {
            cx: 10px;
            cy: 10px;
            r: 8px;
            stroke-width: 2px;
        }
    }

    &.s {
        width: 16px;
        height: 16px;

        .path {
            cx: 8px;
            cy: 8px;
            r: 6.5px;
            stroke-width: 1.5px;
        }
    }
`;
