const RPC_ENDPOINT = "http://localhost/api";

export function LoadConfig() {
    return new Promise((resolve, reject) => {
        var params = { method: "api_LoadConfig" };
        fetch(RPC_ENDPOINT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                resolve(result);
            });
    });
}

export function PopulateExchangeIv(instruments) {
    return new Promise((resolve, reject) => {
        var params = { method: "api_PolulateIv", params: instruments };
        fetch(RPC_ENDPOINT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                var response = {};
                instruments.forEach((element) => {
                    response[element] = result[element];
                });
                resolve(response);
            });
    });
}

export function SaveConfig(config) {
    return new Promise((resolve, reject) => {
        var params = { method: "api_SaveConfig", params: [JSON.stringify(config)] };
        fetch(RPC_ENDPOINT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                resolve("success save config");
            })
            .catch((err) => {
                reject(err);
            });
    });
}
