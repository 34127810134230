import styled from "styled-components";
import { useTable, usePagination } from "react-table";

import Paginator from "./Paginator";

const PaginateTable = ({ columns, data, className, offset, limit, onChangePageIndex, onChangePageSize, total }) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 50 },
        },
        usePagination
    );

    // Render the UI for your table
    return (
        <div className={className}>
            <Paginator
                total={total}
                pageSize={limit}
                pageCount={parseInt(total / limit) + 1}
                pageIndex={offset}
                setPageSize={(e) => onChangePageSize(e)}
                setPageIndex={(e) => onChangePageIndex(e)}
            />

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, j) => (
                                <th key={j} {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={i} {...row.getRowProps()}>
                                {row.cells.map((cell, j) => {
                                    return (
                                        <td key={j} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <Paginator
                total={total}
                pageSize={limit}
                pageCount={parseInt(total / limit) + 1}
                pageIndex={offset}
                setPageSize={(e) => onChangePageSize(e)}
                setPageIndex={(e) => onChangePageIndex(e)}
            />
        </div>
    );
};

export default styled(PaginateTable)`
    padding: 0.25rem;
    font-size: 12px;

    table {
        width: 100%;
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.15rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }
`;
