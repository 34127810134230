import produce from "immer";

import * as constants from "app/actions/constants";
import { utils } from "app/services/option";

const initialState = {
    user: {},
    system: {
        future_contracts: [],
        options: [],
    },
};

export default function configReducer(state = initialState, action) {
    return produce(state, (stateDraft) => {
        switch (action.type) {
            case constants.Action_SaveUserConfig: {
                stateDraft.user = action.payload;
                return;
            }

            case constants.Action_SaveSystemConfig: {
                const config = action.payload;

                delete stateDraft.system.perpetual_contract;

                Object.keys(config).forEach((key) => {
                    stateDraft.system[key] = config[key];
                });

                return;
            }

            case constants.Action_SaveFutureOrderbook: {
                stateDraft.system.future_contracts = action.payload;
                return;
            }

            case constants.Action_ChangeMinDayExpiry: {
                stateDraft.user.min_day_expiry = action.payload;
                return;
            }

            case constants.Action_ChangeRiskFreeRate: {
                stateDraft.user.risk_free_rate = action.payload;
                return;
            }

            case constants.Action_ChangeDividendRate: {
                stateDraft.user.dividend_rate = action.payload;
                return;
            }

            case constants.Action_ChangePastDealSec: {
                stateDraft.user.past_deal_sec = action.payload;
                return;
            }

            case constants.Action_ChangePastDealPriceDiff: {
                stateDraft.user.past_deal_price_diff = action.payload;
                return;
            }

            case constants.Action_UpdateFutureContractAsPricing: {
                const { isTrue, expiration } = action.payload;
                for (let i = 0; i < stateDraft.series.length; i++) {
                    if (stateDraft.series[i].expiration === expiration) {
                        stateDraft.series[i].future_contract_as_pricing = isTrue;
                    }
                }
                return;
            }

            case constants.Action_ChangeIv: {
                const { offset, percent, expiration } = action.payload;
                for (let i = 0; i < stateDraft.user.series.length; i++) {
                    if (stateDraft.user.series[i].expiration === expiration) {
                        for (let j = 0; j < stateDraft.user.series[i].ivs.length; j++) {
                            const ivValue = stateDraft.user.series[i].ivs[j][1];
                            stateDraft.user.series[i].ivs[j][1] = ivValue + offset + percent * ivValue;
                        }
                    }
                }
                return;
            }

            case constants.Action_UpdatePricingContractOffset: {
                const { value, expiration } = action.payload;

                for (let i = 0; i < stateDraft.system.series.length; i++) {
                    if (stateDraft.system.series[i].expiration === expiration) {
                        stateDraft.system.series[i].pricing_offset = value;
                    }
                }
                return;
            }

            case constants.Action_UpdatePricingContractOffsetPercent: {
                const { value, expiration } = action.payload;
                for (let i = 0; i < stateDraft.system.series.length; i++) {
                    if (stateDraft.system.series[i].expiration === expiration) {
                        stateDraft.system.series[i].pricing_offset_percent = value;
                    }
                }
                return;
            }

            case constants.Action_UpdateIvs: {
                const { expiration, ivs } = action.payload;

                for (let i = 0; i < stateDraft.user.series.length; i++) {
                    if (stateDraft.user.series[i].expiration === expiration) {
                        for (let j = 0; j < stateDraft.user.series[i].ivs.length; j++) {
                            const iv = findIv(
                                expiration,
                                stateDraft.user.series[i].ivs[j][0],
                                ivs,
                                stateDraft.system.options
                            );
                            stateDraft.user.series[i].ivs[j][1] = iv;
                        }
                    }
                }

                return;
            }

            case constants.Action_ConvertUserConfig: {
                const { config } = action.payload;
                const expirationArr = utils.FindListExpiration(config.options);
                expirationArr.forEach((elm) => {
                    const options = utils.FindOptions(elm, config.options);
                    const strikeArr = utils.FindListStrike(options);
                    const ivs = [];
                    for (let i = 0; i < strikeArr.length; i++) {
                        ivs.push([strikeArr[i], 0]);
                    }
                    stateDraft.user.series.push({
                        expiration: elm,
                        future_contract_as_pricing: false,
                        pricing_offset: 0,
                        pricing_offset_percent: 0,
                        ivs,
                    });
                });

                return;
            }

            case constants.Action_UpdateAutoRunConfig: {
                const { expiration, name, value } = action.payload;

                const foundSeries = stateDraft.system.series.find((one) => one.expiration === expiration);

                if (!foundSeries) {
                    return;
                }

                foundSeries.auto_run[name] = value;

                return;
            }

            default:
                return;
        }
    });
}

function findIv(expiration, strike, ivs, options) {
    let instrument;
    for (let i = 0; i < options.length; i++) {
        if (options[i].expiration === expiration && options[i].strike === strike) {
            instrument = options[i].instrument;
            break;
        }
    }
    return ivs[instrument];
}
