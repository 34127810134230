import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Form, Col, Row } from "react-bootstrap";

import LeadingFuture from "./LeadingFuture";
import OptionSeries from "./OptionSeries";
import * as actions from "app/actions/option";
import OptionService from "app/services/option";

import { useLocation, useHistory } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Home() {
    const query = useQuery();
    const history = useHistory();
    const currencyQuery = query.get("currency");
    const secretQuery = query.get("secret");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.saveCurrency(currencyQuery || "BTC"));
        dispatch(actions.UpdateSystemConfig());
    }, [currencyQuery, dispatch]);

    useEffect(() => {
        dispatch(actions.saveSecret(secretQuery || ""));
        dispatch(actions.UpdateSystemConfig());
    }, [dispatch, secretQuery]);

    const { secret, currency } = useSelector((state) => state.global);
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        document.title = `${currency} ${process.env.REACT_APP_ENVIRONMENT} | Option trading dashboard`;
    }, [currency]);

    function changeConfig(key, value) {
        const config = { secret, currency };
        config[key] = value;
        history.push(`?secret=${config.secret}&currency=${config.currency}`);
    }

    const checkKey = async () => {
        const optionService = new OptionService({ secret, currency });

        try {
            const result = await optionService.IsSecretKeyCorrect(secret);

            if (result?.success) {
                return setIsInvalid(false);
            }

            setIsInvalid(true);
        } catch (e) {
            setIsInvalid(true);
        }
    };

    useEffect(() => {
        dispatch(actions.StartPollingConfigs());

        return () => {
            dispatch(actions.EndPollingConfigs());
        };
    }, [dispatch]);

    const [lastPrice, setLastPrice] = useState({
        leadingFuturePrice: 0,
        perpetualPrice: 0,
    });

    return (
        <div className="home container">
            <Row>
                <Col xs={9}>
                    <h1>
                        {process.env.REACT_APP_ENVIRONMENT} {currency.toUpperCase()} Options Chain For Deribit
                    </h1>
                </Col>

                <Col>
                    <Form noValidate>
                        <InputGroup className="p-1">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Currency</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                onChange={(e) => changeConfig("currency", e.target.value)}
                                value={currency}
                            >
                                <option value="BTC">BTC</option>
                                <option value="ETH">ETH</option>
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="p-1">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Key</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                id="inlineFormInputGroup"
                                placeholder="secret key"
                                value={secret}
                                onChange={(e) => changeConfig("secret", e.target.value)}
                                onBlur={checkKey}
                                isInvalid={isInvalid}
                            />
                            <Form.Control.Feedback type="invalid">Your key is invalid!</Form.Control.Feedback>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            <LeadingFuture lastPrice={lastPrice} />

            <OptionSeries setLastPrice={setLastPrice} />
        </div>
    );
}
