export function CalcualteLeadingFuture(future_contracts, min_day_expiry) {
    var now = new Date().getTime();
    var minExpiry = min_day_expiry * 86400000;

    var leadingFuture = {};
    var minTime = 9999999999999;
    for (var i = 0; i < future_contracts.length; i++) {
        if (future_contracts[i].expiration - now >= minExpiry && future_contracts[i].expiration < minTime) {
            leadingFuture = future_contracts[i];
            minTime = future_contracts[i].expiration;
        }
    }

    return leadingFuture;
}

/*
  PutCallFlag: Either "put" or "call"
  S: Stock Price
  X: Strike Price
  T: Time to expiration (in years)
  r: Risk-free rate
  d: Dividend rate
  v: Volatility
  This is the same one found in http://www.espenhaug.com/black_scholes.html
  but written with proper indentation and a === instead of == because it's
  faster, and it doesn't declare 5 useless variables (although if you really
  want to do it to have more elegant code I left a commented CND function in
  the end)
*/
export function BlackScholesPricing(PutCallFlag, S, X, T, r, d, v) {
    // console.log({PutCallFlag, S, X, T, r, d, v})
    var d1 = (Math.log(S / X) + (r + (v * v) / 2) * T) / (v * Math.sqrt(T));
    var d2 = d1 - v * Math.sqrt(T);

    if (isNaN(d1) || isNaN(d2)) {
        return 0;
    }

    if (PutCallFlag === "call") {
        return S * CND(d1) - X * Math.exp(-r * T) * CND(d2);
    } else {
        return X * Math.exp(-r * T) * CND(-d2) - S * CND(-d1);
    }
}

export function Delta(PutCallFlag, S, X, T, r, d, v) {
    var d1 = (Math.log(S / X) + (r + (v * v) / 2) * T) / (v * Math.sqrt(T));
    var normD1 = CND(d1);

    if (isNaN(normD1)) {
        return 0;
    }

    if (PutCallFlag === "call") {
        return normD1;
    }

    if (PutCallFlag === "put") {
        return normD1 - 1;
    }

    return 0;
}

/* The cummulative Normal distribution function: */
function CND(x) {
    if (x < 0) {
        return 1 - CND(-x);
    } else {
        var k = 1 / (1 + 0.2316419 * x);
        return (
            1 -
            (Math.exp((-x * x) / 2) / Math.sqrt(2 * Math.PI)) *
                k *
                (0.31938153 + k * (-0.356563782 + k * (1.781477937 + k * (-1.821255978 + k * 1.330274429))))
        );
    }
}

export function GenerateRandomId() {
    return Math.round(Math.random() * 1000000);
}

export function FindListExpiration(options = []) {
    var expiration = [];
    for (var i = 0; i < options.length; i++) {
        if (!expiration.includes(options[i].expiration)) {
            expiration.push(options[i].expiration);
        }
    }
    expiration.sort();
    return expiration;
}

export function FindListStrike(options) {
    var strikes = [];

    for (var i = 0; i < options.length; i++) {
        if (!strikes.includes(options[i].strike)) {
            strikes.push(options[i].strike);
        }
    }
    strikes.sort();
    return strikes;
}

export function FindOptions(expiration, options) {
    var optionArr = [];
    for (var i = 0; i < options.length; i++) {
        if (options[i].expiration == expiration) {
            optionArr.push(options[i]);
        }
    }
    return optionArr;
}
