import * as constants from "app/actions/constants";

const initialState = {};

export default function expandReducer(state = initialState, action) {
    switch (action.type) {
        case constants.Action_SetExpand: {
            state[action.payload.expiration] = action.payload.value;
            break;
        }

        default:
            return state;
    }
    return { ...state };
}
