import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "app/actions/option";
import * as obActions from "app/actions/orderbook";
import OptionService from "app/services/option";
import moment from "moment";
import ReactJson from "react-json-view";
import * as flat from "flat";
import { CSVLink, CSVDownload } from "react-csv";
import { InputGroup, FormControl, Form, Table, Container, Col, Row, Button } from "react-bootstrap";
import ReactDiffViewer from "react-diff-viewer";

const SELECTOR_MODE = ["taker", "maker"];
const SELECTOR_SIDE = ["buy", "sell"];

export default function TradeMetric() {
    const dispatch = useDispatch();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [balances, setBalances] = useState([]);
    const [openOrders, setOpenOrders] = useState([]);
    const [positions, setPositions] = useState([]);
    const [futureHandleLog, setFutureHandleLog] = useState({});
    const [csvData, setCsvData] = useState([]);
    const csvInstance = useRef(null);

    useEffect(() => {
        if (optionService) {
            getTradeMetric();
            getFutureOrderHandlerLog();
        }
    }, [optionService]);

    const getTradeMetric = async () => {
        const tradeMetric = await optionService.GetTradeMetric();
        console.log("+++++++++++++++++ trade metric ================", tradeMetric);
        if (tradeMetric && tradeMetric.balances) {
            setBalances(Object.values(tradeMetric.balances));
        }
        if (tradeMetric && tradeMetric.open_orders) {
            setOpenOrders(tradeMetric.open_orders);
        }
        if (tradeMetric && tradeMetric.positions) {
            setPositions(tradeMetric.positions);
        }
    };

    const getFutureOrderHandlerLog = async () => {
        const handlerLog = await optionService.GetFutureOrderHandlerLogs();
        console.log("+++++++++++++++++ trade handlerLog ================", handlerLog);

        const arrayLogs = handlerLog.map((l) => {
            const updatedTime = l.order.updated_time;
            const timeString = new Date(updatedTime).toISOString();
            return {
                ...l.order,
                ...l.metric,
                ...{
                    hander_orig_qty: l.order_state.origin_qty,
                    handler_filled_qty: l.order_state.filled_qty,
                },
                updated_time: timeString,
            };
        });
        setFutureHandleLog(arrayLogs);
        setCsvData(arrayLogs);
    };

    const exportLogs = async () => {
        csvInstance.current.link.click();
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <div className="col-12 text-center">Balances</div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Asset</th>
                                <th>Free</th>
                                <th>Lock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {balances.map((b, index) => (
                                <tr key={index}>
                                    <td>{b.asset}</td>
                                    <td>{b.free}</td>
                                    <td>{b.lock}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br />
                </Row>

                <Row>
                    <div className="col-12 text-center">Open Orders</div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#Id</th>
                                <th>Client Order Id</th>
                                <th>Symbol</th>
                                <th>Side</th>
                                <th>Type</th>
                                <th>Price</th>
                                <th>Origin Qty</th>
                                <th>Executed Qty</th>
                                <th>Cumulative Quote Qty</th>
                                <th>Status</th>
                                <th>Updated Time</th>
                                <th>Pay Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {openOrders.map((o, index) => (
                                <tr key={index}>
                                    <td>{o.Id}</td>
                                    <td>{o.ClientOrderId}</td>
                                    <td>{o.Symbol}</td>
                                    <td>{o.Side}</td>
                                    <td>{o.Type}</td>
                                    <td>{o.Price}</td>
                                    <td>{o.OriginQty}</td>
                                    <td>{o.ExecutedQty}</td>
                                    <td>{o.CumulativeQuoteQty}</td>
                                    <td>{o.Status}</td>
                                    <td>{o.UpdatedTime}</td>
                                    <td>{o.PayCurrency}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br />
                </Row>

                <Row>
                    <div className="col-12 text-center">Positions</div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Symbol</th>
                                <th>Side</th>
                                <th>Size</th>
                                <th>Average Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {positions.map((b, index) => (
                                <tr key={index}>
                                    <td>{b.Symbol}</td>
                                    <td>{b.Side}</td>
                                    <td>{b.Size}</td>
                                    <td>{b.AveragePrice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br />
                    <br />
                </Row>

                <Row>
                    <div className="col-12 text-center">
                        Future Order Handler Logs &nbsp;&nbsp;
                        <Button size="sm" className="mb-1 mr-1" variant="info" onClick={exportLogs}>
                            Export CSV Series
                        </Button>
                        <CSVLink data={csvData} filename={`future_order_handler_logs.csv`} ref={csvInstance} />
                    </div>
                    <div className="col-12">
                        <ReactJson
                            src={futureHandleLog}
                            name={false}
                            collapseStringsAfterLength={100}
                            indentWidth={4}
                            displayDataTypes={false}
                            collapsed={true}
                        />
                    </div>
                </Row>
            </Container>
        </div>
    );
}
