import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";

import OptionService from "app/services/option";
import DateTimeInput from "app/components/_base/DateTimeInput";
import PaginateTable from "./PaginateTable";

const tradingLogsColumns = [
    {
        Header: "call ask price",
        accessor: "call_ask_price",
    },
    {
        Header: "call ask qty",
        accessor: "call_ask_qty",
    },
    {
        Header: "call bid price",
        accessor: "call_bid_price",
    },
    {
        Header: "call bid qty",
        accessor: "call_bid_qty",
    },
    {
        Header: "call delta",
        accessor: "call_delta",
    },
    {
        Header: "call/put iv",
        accessor: "call_iv",
    },
    {
        Header: "call last price",
        accessor: "call_last_price",
    },
    {
        Header: "call last tradingtime",
        accessor: "call_last_tradingtime",
    },
    {
        Header: "iso call last tradingtime",
        accessor: "iso_call_last_tradingtime",
    },
    {
        Header: "call theoretical price",
        accessor: "call_theoretical_price",
    },
    {
        Header: "call vega",
        accessor: "call_vega",
    },
    {
        Header: "delivery commission",
        accessor: "delivery_commission",
    },
    {
        Header: "direction",
        accessor: "direction",
    },
    {
        Header: "iso expiration",
        accessor: "iso_expiration",
    },
    {
        Header: "expiration",
        accessor: "expiration",
    },
    {
        Header: "future ask price",
        accessor: "future_ask_price",
    },
    {
        Header: "future ask qty",
        accessor: "future_ask_qty",
    },
    {
        Header: "future bid price",
        accessor: "future_bid_price",
    },
    {
        Header: "future bid qty",
        accessor: "future_bid_qty",
    },
    {
        Header: "future last price",
        accessor: "future_last_price",
    },
    {
        Header: "future last tradingtime",
        accessor: "future_last_tradingtime",
    },
    {
        Header: "iso future last tradingtime",
        accessor: "iso_future_last_tradingtime",
    },
    {
        Header: "put ask price",
        accessor: "put_ask_price",
    },
    {
        Header: "put_ask_qty",
        accessor: "put_ask_qty",
    },
    {
        Header: "put bid price",
        accessor: "put_bid_price",
    },
    {
        Header: "put bid qty",
        accessor: "put_bid_qty",
    },
    {
        Header: "put delta",
        accessor: "put_delta",
    },
    // {
    //     Header : "put iv",
    //     accessor : "put_iv",
    // },
    {
        Header: "put last price",
        accessor: "put_last_price",
    },
    {
        Header: "put last tradingtime",
        accessor: "put_last_tradingtime",
    },
    {
        Header: "put theoretical price",
        accessor: "put_theoretical_price",
    },
    {
        Header: "put vega",
        accessor: "put_vega",
    },
    {
        Header: "strike",
        accessor: "strike",
    },
    {
        Header: "synth ask",
        accessor: "synth_ask",
    },
    {
        Header: "synth bid",
        accessor: "synth_bid",
    },
    {
        Header: "timestamp",
        accessor: "timestamp",
    },
    {
        Header: "iso timestamp",
        accessor: "iso_timestamp",
    },
    {
        Header: "trading commission",
        accessor: "trading_commission",
    },
    {
        Header: "type",
        accessor: "type",
    },
];

const TradingLogs = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [loading, setLoading] = useState(false);
    const [optionService, setOptionService] = useState({});
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);

    const csvInstance = useRef(null);
    const [csvData, setCsvData] = useState([]);

    const [tradingLogs, setTradingLogs] = useState([]);

    const getTradeLogsData = async () => {
        let tradingLogsData = [];
        if (!fromTime) return;

        let currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();
        let loopFetch = true;
        setLoading(true);
        while (loopFetch) {
            const currentData = await optionService.getTradeLogsData(currentFromTime, currentToTime);
            console.log("_________ fetch _________");
            if (currentData && currentData.data && currentData.data.length) {
                const lastItem = currentData.data[currentData.data.length - 1];
                currentFromTime = lastItem.timestamp + 1;
                const convertedData = currentData.data.map((d) => ({
                    ...d,
                    iso_timestamp: new Date(d.timestamp).toISOString(),
                    iso_call_last_tradingtime: new Date(d.call_last_tradingtime).toISOString(),
                    iso_future_last_tradingtime: new Date(d.future_last_tradingtime).toISOString(),
                    iso_expiration: new Date(d.expiration).toISOString(),

                    delivery_commission: d.delivery_commission.toFixed(5),
                    trading_commission: d.trading_commission.toFixed(5),

                    call_ask_price: d.call_ask_price.toFixed(4),
                    call_bid_price: d.call_bid_price.toFixed(4),
                    call_theoretical_price: d.call_theoretical_price.toFixed(4),

                    put_ask_price: d.put_ask_price.toFixed(4),
                    put_bid_price: d.put_bid_price.toFixed(4),
                    put_theoretical_price: d.put_theoretical_price.toFixed(4),

                    future_ask_price: d.future_ask_price.toFixed(1),
                    future_bid_price: d.future_bid_price.toFixed(1),
                    future_last_price: d.future_last_price.toFixed(1),
                    synth_ask: d.synth_ask.toFixed(1),
                    synth_bid: d.synth_bid.toFixed(1),

                    call_iv: d.call_iv.toFixed(5),
                    call_delta: d.call_delta.toFixed(4),
                    put_delta: d.put_delta.toFixed(4),

                    call_vega: d.call_vega.toFixed(3),
                    put_vega: d.put_vega.toFixed(3),
                }));
                tradingLogsData = [...tradingLogsData, ...convertedData];
            } else {
                console.log("------- end fetch");
                loopFetch = false;
            }
        }
        console.log("-----tradingLogsData--------", tradingLogsData);

        setLoading(false);
        setTradingLogs(tradingLogsData);

        return tradingLogsData;
    };

    const exportTradeLogs = async () => {
        const tradingLogs = await getTradeLogsData();
        await exportCSV(tradingLogs, tradingLogsColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => item[c.accessor]);
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getTradeLogsData}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Trading Logs Data
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportTradeLogs}
                            disabled={loading}
                        >
                            Export Trading Logs
                        </Button>

                        <CSVLink data={csvData} filename={`TradingLogs_${fromTime}_${toTime}.csv`} ref={csvInstance} />
                    </Col>
                </Row>
                <br />
                <Row>
                    {tradingLogs?.length ? <PaginateTable columns={tradingLogsColumns} data={tradingLogs} /> : null}
                </Row>
            </Container>
        </div>
    );
};

export default TradingLogs;
