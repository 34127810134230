import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OptionService from "app/services/option";
import styled from "styled-components";
import { useTable } from "react-table";
import ChartIvMoneyness from "./ChartIvMoneyness";
import { InputGroup, Button } from "react-bootstrap";
import * as actions from "app/actions/option";
import useInterval from "app/services/useInterval";
import moment from "moment";
import ChartCollectedStats from "./ChartCollectedStats";
import { GET_OPTION_DATA_INTERVAL } from "./const";
import { formatMoney } from "app/helper/function";

const MS_24H = 24 * 60 * 60 * 1000;
const MA_IV_THRESHOLD = 0.1;
const round999999 = (number) => {
    return number >= 999999 ? 999999 : number;
};

const formatPriceQty = (val) => {
    if (val) return val != 999999 ? formatMoney(val, 4) : 999999;
    return "-";
};

const formatLastTrade = (val) => {
    if (val) {
        return new Date().getTime() - val > MS_24H
            ? Math.round((new Date().getTime() - val) / MS_24H) + "d"
            : moment(val).format("HH:mm");
    }
    return "-";
};

const Styles = styled.div`
    padding: 1rem;
    table {
        background-color: #444;
        color: #ddd;
        border-spacing: 0;
        border: 1px solid black;
        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        th,
        td {
            text-align: center;
            margin: 0;
            padding: 0.1rem;
            border-bottom: 1px solid #333;
            border-right: 1px solid #333;
            :last-child {
                border-right: 0;
            }
        }
    }
    input.numeric-input::-webkit-inner-spin-button,
    input.numeric-input::-webkit-outer-spin-button {
        opacity: 1;
    }
    .table-option-data {
        font-size: 14px;
    }
`;

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: ["call_last_price_timestamp", "put_last_price_timestamp"],
        },
    });

    // Render the UI for your table
    return (
        <table {...getTableProps()} className="table-option-data">
            <thead>
                {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, j) => (
                            <th key={j} {...column.getHeaderProps()}>
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr key={i} {...row.getRowProps()}>
                            {row.cells.map((cell, j) => {
                                return (
                                    <td
                                        key={j}
                                        {...cell.getCellProps([
                                            {
                                                className: cell.column.className,
                                            },
                                        ])}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default function Option({ optionService, ...props }) {
    const dispatch = useDispatch();
    const columns = [
        {
            Header: "Calls",
            columns: [
                {
                    Header: "Synth ASK",
                    accessor: "synth_ask",
                    Cell: (c_props) => {
                        var className = "";
                        if (c_props.row.original.is_highligh_synth_ask) {
                            className = "synth-highlight";
                        }
                        return <div className={className}>{round999999(c_props.value) || "-"}</div>;
                    },
                },
                {
                    Header: "Ask qty",
                    accessor: "call_ask_qty",
                    className: "ask-column text-right pr-2",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.call_ask_price) < Number(props.row.original.call_theoretical_price)
                        ) {
                            className = "ask-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Ask price",
                    accessor: "call_ask_price",
                    className: "ask-column text-right pr-1",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.call_ask_price) < Number(props.row.original.call_theoretical_price)
                        ) {
                            className = "ask-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Theor price",
                    accessor: "display_call_theoretical_price",
                    className: "text-right pr-2",
                },
                {
                    Header: "Bid price",
                    accessor: "call_bid_price",
                    className: "bid-column text-right pr-1",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.call_bid_price) > Number(props.row.original.call_theoretical_price)
                        ) {
                            className = "bid-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Bid qty",
                    accessor: "call_bid_qty",
                    className: "bid-column text-right pr-2",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.call_bid_price) > Number(props.row.original.call_theoretical_price)
                        ) {
                            className = "bid-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Last trade",
                    accessor: "call_last_price",
                    className: "text-right pr-2",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            new Date().getTime() - props.row.original.call_last_price_timestamp <= past_deal_sec * 1000
                        ) {
                            className = "text-danger";
                            if (
                                Math.abs(props.value - props.row.values.display_call_theoretical_price) >=
                                past_deal_price_diff
                            ) {
                                className = className + " bg-warning";
                            }
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Trade time",
                    accessor: "call_trade_time",
                },
                {
                    Header: "Delta",
                    accessor: "display_call_delta",
                    className: "text-right pr-2",
                },
            ],
        },
        {
            Header: "Strike",
            columns: [
                {
                    Header: "IV",
                    accessor: "iv",
                    className: "text-right",
                },
                {
                    Header: "Strike",
                    accessor: "strike",
                },
                {
                    Header: "Avg IV",
                    accessor: "moving_average_iv",
                    Cell: (props) => {
                        return <div>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Density",
                    accessor: "strike_density",
                },
                {
                    Header: "Position",
                    accessor: "total_positions",
                },
            ],
        },
        {
            Header: "Puts",
            columns: [
                {
                    Header: "Delta",
                    accessor: "display_put_delta",
                    className: "text-right pr-2",
                },
                {
                    Header: "Trade time",
                    accessor: "put_trade_time",
                },
                {
                    Header: "Last trade",
                    accessor: "put_last_price",
                    className: "text-right pr-1",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            new Date().getTime() - props.row.original.put_last_price_timestamp <= past_deal_sec * 1000
                        ) {
                            className = "text-danger";
                            if (
                                Math.abs(props.value - props.row.original.put_theoretical_price) >= past_deal_price_diff
                            ) {
                                className = className + " bg-warning";
                            }
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Bid qty",
                    accessor: "put_bid_qty",
                    className: "bid-column text-right pr-2",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.put_bid_price) > Number(props.row.original.put_theoretical_price)
                        ) {
                            className = "bid-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Bid price",
                    accessor: "put_bid_price",
                    className: "bid-column text-right pr-1",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.put_bid_price) > Number(props.row.original.put_theoretical_price)
                        ) {
                            className = "bid-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Theor price",
                    accessor: "display_put_theoretical_price",
                    className: "text-right pr-2",
                },
                {
                    Header: "Ask price",
                    accessor: "put_ask_price",
                    className: "ask-column text-right pr-1",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.put_ask_price) < Number(props.row.original.put_theoretical_price)
                        ) {
                            className = "ask-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Ask qty",
                    accessor: "put_ask_qty",
                    className: "ask-column text-right pr-2",
                    Cell: (props) => {
                        var className = "";
                        if (
                            props.value != 0 &&
                            Number(props.row.values.put_ask_price) < Number(props.row.original.put_theoretical_price)
                        ) {
                            className = "ask-highlight";
                        }
                        return <div className={className}>{props.value || "-"}</div>;
                    },
                },
                {
                    Header: "Synth BID",
                    accessor: "synth_bid",
                    Cell: (c_props) => {
                        var className = "";
                        if (c_props.row.original.is_highligh_synth_bid) {
                            className = "synth-highlight";
                        }
                        return <div className={className}>{c_props.value || "-"}</div>;
                    },
                },
            ],
        },
        {
            Header: "Gamma",
            accessor: "display_call_gamma",
            className: "text-right pr-2",
        },
        {
            Header: " ",
            columns: [
                {
                    Header: "Vega",
                    accessor: "vega",
                    className: "text-right pr-2 pl-1",
                },
            ],
        },
        {
            Header: " ",
            columns: [
                {
                    Header: "Money -ness",
                    accessor: "moneyness",
                    className: "text-right pr-2",
                },
            ],
        },
    ];

    const { secret, currency } = useSelector((state) => state.global);

    useEffect(() => {
        dispatch(actions.SetExpandSeries(props.expiration, true));
    }, [secret, currency]);

    const [tableData, setTableData] = useState([]);

    const { past_deal_sec, past_deal_price_diff } = useSelector((state) => state.config.system);

    const [loadingSmoothIv, setLoadingSmoothIv] = useState(false);
    const [loadingMarketIv, setLoadingMarketIv] = useState(false);
    const [loadingMarketIvGap, setLoadingMarketIvGap] = useState(false);
    const [loadingMarketOffset, setLoadingMarketOffset] = useState(false);
    const [loadingSmoothDensity, setLoadingSmoothDensity] = useState(false);
    const [loadingPricingRouting, setLoadingPricingRouting] = useState(false);

    const GetOptionsData = async () => {
        const data = await optionService.GetOptionsData([props.expiration]);
        const tableData = mappingOptions(data, props.strikes);
        setTableData(tableData);
    };

    React.useEffect(() => {
        if (optionService instanceof OptionService) {
            GetOptionsData();
        }
    }, [optionService, props.strikes]);

    useInterval(GetOptionsData, GET_OPTION_DATA_INTERVAL);

    const mappingOptions = (optionsData = [], strikesArray) => {
        const strikeOptions = {};

        strikesArray.forEach((strikeItem) => {
            strikeOptions[strikeItem.strike] = {
                strike: strikeItem.strike,
                iv: formatMoney(strikeItem.iv * 100, 1),
                moneyness: formatMoney(strikeItem.moneyness, 2),
                synth_bid: formatMoney(strikeItem.synth_bid, 1),
                is_highligh_synth_bid: strikeItem.synth_bid === props.maxSynthBid,
                synth_ask: formatMoney(strikeItem.synth_ask, 1),
                is_highligh_synth_ask: strikeItem.synth_ask == props.minSynthAsk,
                total_positions: formatMoney(
                    (props.positions[strikeItem.call.instrument] || 0) +
                        (props.positions[strikeItem.put.instrument] || 0),
                    1
                ),
                moving_average_iv: formatMoney(strikeItem.moving_average_iv * 100, 1),
                is_highligh_ma_iv: Math.abs(strikeItem.iv - strikeItem.moving_average_iv) > MA_IV_THRESHOLD,
            };
        });

        optionsData.forEach((op) => {
            if (!strikeOptions[op.strike]) {
                strikeOptions[op.strike] = {};
            }

            strikeOptions[op.strike].vega = formatMoney(op.vega, 1);

            if (op.type === "call") {
                strikeOptions[op.strike] = {
                    ...strikeOptions[op.strike],
                    synthetic_ask: 0,
                    call_instrument: op.instrument,
                    call_ask_qty: op.ask_qty,
                    call_bid_qty: op.bid_qty,
                    call_theoretical_price: op.theoretical_price,
                    call_ask_price: formatPriceQty(op.ask_price),
                    call_bid_price: formatPriceQty(op.bid_price),
                    call_last_price: formatPriceQty(op.last_price),
                    call_last_price_timestamp: op.last_price_timestamp,
                    call_delta: op.delta,
                    call_gamma: op.gamma,
                    display_call_delta: op.delta,
                    display_call_gamma: formatMoney(op.gamma * 1000.2, 2),
                    call_trade_time: formatLastTrade(op.last_price_timestamp),
                };
            } else if (op.type === "put") {
                strikeOptions[op.strike] = {
                    ...strikeOptions[op.strike],
                    synthetic_bid: 0,
                    put_instrument: op.instrument,
                    put_ask_qty: op.ask_qty,
                    put_bid_qty: op.bid_qty,
                    put_theoretical_price: op.theoretical_price,
                    put_ask_price: formatPriceQty(op.ask_price),
                    put_bid_price: formatPriceQty(op.bid_price),
                    put_last_price: formatPriceQty(op.last_price),
                    put_last_price_timestamp: op.last_price_timestamp,
                    put_delta: op.delta,
                    put_gamma: op.gamma,
                    display_put_delta: op.delta,
                    display_put_gamma: formatMoney(op.gamma * 1000.2, 2),
                    put_trade_time: formatLastTrade(op.last_price_timestamp),
                };
            }
        });

        const updatedData = Object.keys(strikeOptions)
            .sort((a, b) => +a - b)
            .map((strike) => {
                const s = strikeOptions[strike];
                return {
                    ...s,
                    display_call_theoretical_price: s.call_theoretical_price
                        ? formatMoney(s.call_theoretical_price, 4)
                        : "-",
                    display_call_delta: s.display_call_delta ? formatMoney(s.display_call_delta, 2) : "-",
                    display_put_theoretical_price: s.put_theoretical_price
                        ? formatMoney(s.put_theoretical_price, 4)
                        : "-",
                    display_put_delta: s.display_put_delta ? formatMoney(s.display_put_delta, 2) : "-",
                };
            });

        // calculate Strike Density
        updatedData.forEach((element, index) => {
            if (index == 0 || index == updatedData.length - 1) return;
            var Ph = updatedData[index + 1].put_theoretical_price;
            var P = updatedData[index].put_theoretical_price;
            var Pl = updatedData[index - 1].put_theoretical_price;
            var Ch = updatedData[index + 1].call_theoretical_price;
            var C = updatedData[index].call_theoretical_price;
            var Cl = updatedData[index - 1].call_theoretical_price;
            var Sh = updatedData[index + 1].strike;
            var S = updatedData[index].strike;
            var Sl = updatedData[index - 1].strike;
            var putDensity = 1000000 * [(Ph - P) / (Sh - S) - (Pl - P) / (Sl - S)];
            var callDensity = 1000000 * [(Ch - C) / (Sh - S) - (Cl - C) / (Sl - S)];
            var strikeDensity = (putDensity + callDensity) / 2;

            updatedData[index].strike_density = strikeDensity ? formatMoney(strikeDensity, 2) : "-";
        });

        return updatedData;
    };

    async function smoothIv() {
        setLoadingSmoothIv(true);
        await optionService.SmoothIv(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingSmoothIv(false);
    }

    async function marketIv() {
        setLoadingMarketIv(true);
        await optionService.MarketIv(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingMarketIv(false);
    }

    async function marketIvGap() {
        setLoadingMarketIvGap(true);
        await optionService.MarketIvGap(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingMarketIvGap(false);
    }

    async function marketOffset() {
        setLoadingMarketOffset(true);
        await optionService.MarketOffset(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingMarketOffset(false);
    }

    async function smoothDensity() {
        setLoadingSmoothDensity(true);
        await optionService.SmoothDensity(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingSmoothDensity(false);
    }

    async function pricingRouting() {
        setLoadingPricingRouting(true);
        await optionService.PricingRouting(props.expiration);
        await dispatch(actions.UpdateSystemConfig());
        await GetOptionsData();
        setLoadingPricingRouting(false);
    }

    return (
        <div className="option">
            <Styles>
                <InputGroup>
                    <ChartIvMoneyness
                        expiration={props.expiration}
                        tableData={tableData}
                        ivATM={props.ivATM}
                        p1Iv={props.p1Iv}
                        p2Iv={props.p2Iv}
                        c1Iv={props.c1Iv}
                        c2Iv={props.c2Iv}
                        p1Ratio={props.p1Ratio}
                        p2Ratio={props.p2Ratio}
                        c1Ratio={props.c1Ratio}
                        c2Ratio={props.c2Ratio}
                        theoreticalPrice={props.underlyingPrice}
                    />
                    <ChartCollectedStats expiration={props.expiration} />

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="info"
                        onClick={() => smoothIv()}
                        disabled={loadingSmoothIv}
                    >
                        {loadingSmoothIv && <div className="spinner-border spinner-border-sm" />} Smooth IV
                    </Button>

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="success"
                        onClick={() => marketIv()}
                        disabled={loadingMarketIv}
                    >
                        {loadingMarketIv && <div className="spinner-border spinner-border-sm" />} Market IV
                    </Button>

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="success"
                        onClick={() => marketIvGap()}
                        disabled={loadingMarketIvGap}
                    >
                        {loadingMarketIv && <div className="spinner-border spinner-border-sm" />} Market IV Gap
                    </Button>

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="secondary"
                        onClick={() => marketOffset()}
                        disabled={loadingMarketOffset}
                    >
                        {loadingMarketOffset && <div className="spinner-border spinner-border-sm" />} Market Offset
                    </Button>

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="info"
                        onClick={() => smoothDensity()}
                        disabled={loadingSmoothDensity}
                    >
                        {loadingSmoothDensity && <div className="spinner-border spinner-border-sm" />} Smooth Density
                    </Button>

                    <Button
                        size="sm"
                        className="mb-1 mr-1"
                        variant="warning"
                        onClick={() => pricingRouting()}
                        disabled={loadingPricingRouting}
                    >
                        {loadingPricingRouting && <div className="spinner-border spinner-border-sm" />} Pricing Routine
                    </Button>
                </InputGroup>
                <Table columns={columns} data={tableData} />
            </Styles>
        </div>
    );
}
