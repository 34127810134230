import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OptionService from "app/services/option";
import ReactJson from "react-json-view";
import { InputGroup, Form, Container, Col, Row, Button } from "react-bootstrap";

const SELECTOR_SIDE = ["buy", "sell"];

export default function FutureOrderHandler() {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [isLoading, setLoading] = useState(false);
    const [futureName, setFutureName] = useState(null);
    const [futureSelector, setFutureSelector] = useState([]);
    const [side, setSide] = useState(SELECTOR_SIDE[0]);
    const [amount, setAmount] = useState(0);

    const [data, setData] = useState({});

    useEffect(() => {
        if (optionService) {
            getArrayExpiry();
        }
    }, [optionService]);

    const getArrayExpiry = async () => {
        const futureInstrument = await optionService.LoadFutureInstrument();

        if (futureInstrument && futureInstrument.length) {
            const arrayInstrumentName = futureInstrument
                .filter((f) => f.settlement_period !== "perpetual")
                .map((f) => f.instrument_name);
            setFutureSelector(arrayInstrumentName);
            setFutureName(arrayInstrumentName[0]);
        }
    };

    const getApiData = async () => {
        setLoading(true);
        setData({});
        const data = await optionService.GetApiData("debug_FutureOrderHandler", [futureName, side, amount]);
        setData(data);
        setLoading(false);
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Future Name</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                as="select"
                                value={futureName}
                                onChange={(e) => setFutureName(e.target.value)}
                            >
                                {futureSelector.map((futureName) => {
                                    return <option key={futureName}>{futureName}</option>;
                                })}
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Side</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" value={side} onChange={(e) => setSide(e.target.value)}>
                                {SELECTOR_SIDE.map((side) => (
                                    <option key={side} value={side}>
                                        {side}
                                    </option>
                                ))}
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Amount</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                value={amount}
                                onChange={(e) => setAmount(+e.target.value)}
                                type="number"
                                placeholder="Amount"
                                aria-label="Amount"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getApiData}
                            disabled={isLoading}
                        >
                            {isLoading && <div className="spinner-border spinner-border-sm" />} Send
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="p-0">
                        <div>Data</div>
                        <ReactJson
                            src={data}
                            name={false}
                            collapseStringsAfterLength={5}
                            indentWidth={2}
                            displayDataTypes={false}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
