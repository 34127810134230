import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, ButtonGroup } from "react-bootstrap";
import AmCharts from "@amcharts/amcharts3-react";

const PERIOD_TIME = {
    ONE_DAY: 1000 * 60 * 60 * 24,
    ONE_WEEK: 1000 * 60 * 60 * 24 * 7,
    ONE_MONTH: 1000 * 60 * 60 * 24 * 30,
    SIX_MONTH: 1000 * 60 * 60 * 24 * 120,
};

const INTERVAL_TIME = {
    ONE_DAY: 1000 * 60, // 1m
    ONE_WEEK: 1000 * 60 * 10, // 10m
    ONE_MONTH: 1000 * 60 * 30, // 30m
    SIX_MONTH: 1000 * 60 * 30 * 6, //30m
};

function getTableConfig(dataProvider) {
    const timeBaseData = dataProvider.map((d) => ({
        ...d,
        date: new Date(d.timestamp),
    }));

    return {
        type: "serial",
        theme: "light",
        // "startEffect": "easeOutSine",
        dataProvider: timeBaseData,
        gridAboveGraphs: true,
        // "startDuration": 1,
        categoryField: "date",
        dataDateFormat: "YYYY-MM-DD",
        pathToImages: "https://cdn.amcharts.com/lib/3/images/",

        valueAxes: [
            {
                id: "v1",
                axisColor: "#FF6600",
                axisThickness: 2,
                axisAlpha: 1,
                position: "left",
            },
            {
                id: "v3",
                axisColor: "#B0DE09",
                axisThickness: 2,
                gridAlpha: 0,
                axisAlpha: 1,
                position: "right",
            },
        ],

        graphs: [
            {
                valueAxis: "v1",
                title: "90 day IV",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "iv90day",
                hideBulletsCount: 120,
                position: "left",
            },
            {
                valueAxis: "v1",
                title: "30 day IV",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "iv30day",
                hideBulletsCount: 120,
                position: "left",
            },

            {
                valueAxis: "v3",
                title: "Leading Contract Price",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "leading_contract_price",
                hideBulletsCount: 120,
                position: "right",
            },

            {
                valueAxis: "v1",
                title: "90 day P1",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "p1Iv90",
                hideBulletsCount: 120,
                position: "left",
            },
            {
                valueAxis: "v1",
                title: "90 day C1",
                balloonText: "[[title]]: <b>[[value]]</b>",
                bullet: "round",
                bulletSize: 5,
                bulletBorderColor: "#ffffff",
                bulletBorderAlpha: 1,
                bulletBorderThickness: 2,
                valueField: "c1Iv90",
                hideBulletsCount: 120,
                position: "left",
            },
        ],

        chartScrollbar: {
            graph: "v1",
            oppositeAxis: false,
            offset: 30,
            scrollbarHeight: 30,
            backgroundAlpha: 0,
            selectedBackgroundAlpha: 0.1,
            selectedBackgroundColor: "#888888",
            graphFillAlpha: 0,
            graphLineAlpha: 0.5,
            selectedGraphFillAlpha: 0,
            selectedGraphLineAlpha: 1,
            autoGridCount: true,
            color: "#AAAAAA",
        },

        categoryAxis: {
            parseDates: true,
            dashLength: 1,
            minorGridEnabled: true,
            // "guides": warningGuilds,
            minPeriod: "fff",
            dateFormats: [
                {
                    period: "fff",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "ss",
                    format: "NN:SS.QQQ",
                },
                {
                    period: "mm",
                    format: "JJ:NN",
                },
                {
                    period: "hh",
                    format: "JJ:NN",
                },
                {
                    period: "DD",
                    format: "MMM DD",
                },
                {
                    period: "WW",
                    format: "MMM DD",
                },
                {
                    period: "MM",
                    format: "MMM",
                },
                {
                    period: "YYYY",
                    format: "YYYY",
                },
            ],
        },
        legend: {
            spacing: 100,
            valueWidth: 70,
        },

        chartCursor: {
            valueBalloonsEnabled: true,
            categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
            // "categoryBalloonText": "abc [[index]] [[category]]"
        },
    };
}
const periods = [
    { value: "D", text: "Day" },
    { value: "W", text: "Week" },
    { value: "M", text: "Month" },
    { value: "6M", text: "6 Months" },
];

const ChartMarketStatistics = ({ optionService }) => {
    const intervalRef = useRef();

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [tableConfig, setTableConfig] = useState(getTableConfig([]));
    const [period, setPeriod] = useState("D");

    const handleChangePeriod = (period) => {
        setPeriod(period);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const getGeneralData = useCallback(
        async (fromTime, toTime, interval) => {
            console.log("getGeneralData");
            let generalData = [];

            if (!fromTime) {
                return;
            }

            const currentFromTime = new Date(fromTime).getTime();
            let currentToTime = new Date(toTime).getTime();
            let loopFetch = true;

            while (loopFetch) {
                const currentData = await optionService.GetGeneralData(currentFromTime, currentToTime, interval);

                if (currentData && currentData.length) {
                    const lastItem = currentData[currentData.length - 1];
                    currentToTime = lastItem.timestamp - 1;
                    const convertedData = currentData.map((d) => ({
                        ...d,
                        iso_date: new Date(d.timestamp).toISOString(),
                    }));
                    generalData = [...generalData, ...convertedData];
                } else {
                    loopFetch = false;
                }
            }

            return generalData;
        },
        [optionService]
    );

    const getStatsData = useCallback(
        async (currentPeriod = period) => {
            setLoading(true);
            const toTime = new Date().getTime();
            let fromTime = toTime - PERIOD_TIME.ONE_DAY;
            let interval = INTERVAL_TIME.ONE_DAY;

            if (currentPeriod == "W") {
                fromTime = toTime - PERIOD_TIME.ONE_WEEK;
                interval = INTERVAL_TIME.ONE_WEEK;
            } else if (currentPeriod == "M") {
                fromTime = toTime - PERIOD_TIME.ONE_MONTH;
                interval = INTERVAL_TIME.ONE_MONTH;
            } else if (currentPeriod == "6M") {
                fromTime = toTime - PERIOD_TIME.SIX_MONTH;
                interval = INTERVAL_TIME.SIX_MONTH;
            }

            const data = await getGeneralData(fromTime, toTime, interval);

            // don't need to check for data.length, it's an array returned from getGeneralData

            data.reverse(); // need to reverse because the data is sorted from the latest timestamp

            const multipliedIVData = data
                .filter((x) => x.leading_contract_price && x.iv30day && x.iv90day)
                .map((d) => ({
                    ...d,
                    iv30day: (Math.round(d.iv30day * 1000) / 10).toFixed(1),
                    iv90day: (Math.round(d.iv90day * 1000) / 10).toFixed(1),

                    p1Iv90: (Math.round(d.p1_iv_90 * 1000) / 10).toFixed(1),
                    c1Iv90: (Math.round(d.c1_iv_90 * 1000) / 10).toFixed(1),
                }));

            setTableConfig(getTableConfig(multipliedIVData));
            setLoading(false);
        },
        [getGeneralData, period]
    );

    useEffect(() => {
        const cleanUp = () => {
            clearInterval(intervalRef.current);
        };

        if (!optionService) {
            return cleanUp;
        }

        cleanUp();
        getStatsData(period);

        intervalRef.current = setInterval(() => getStatsData(period), 60_000);

        return cleanUp;
    }, [getStatsData, period, optionService]);

    return (
        <>
            <Button size="sm" className="mb-1 mr-1" variant="success" onClick={handleShow}>
                Market Statistics
            </Button>

            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <ButtonGroup aria-label="select time range">
                        {periods.map((p, index) => (
                            <Button
                                disabled={loading}
                                key={index}
                                size="sm"
                                className={period === p.value ? "btn-info" : "btn-light"}
                                onClick={() => handleChangePeriod(p.value)}
                            >
                                {p.text}
                            </Button>
                        ))}
                    </ButtonGroup>
                    {loading && <div className="spinner-border spinner-border-sm my-auto ml-2" />}
                </Modal.Header>
                <Modal.Body>
                    <AmCharts.React
                        style={{
                            width: "100%",
                            height: "600px",
                        }}
                        options={tableConfig}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChartMarketStatistics;
