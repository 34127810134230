import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";
import DateTimeInput from "app/components/_base/DateTimeInput";
import { CSVLink } from "react-csv";
import PaginateTable from "./PaginateTable";
import OptionService from "app/services/option";
import moment from "moment";
import { formatMoney } from "app/helper/function";

const DeltaHistories = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 24 * 60 * 60 * 1000);
    const [toTime, setToTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const [csvData, setCsvData] = useState([]);
    const [histories, setHistories] = useState([]);

    const getDeltaHistories = async () => {
        setLoading(true);
        let arr = [];
        const currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();

        const duration = 12 * 60 * 60 * 1000; // 12h ~ 5k record
        const step = Math.ceil((currentToTime - currentFromTime) / duration);

        for (let i = 0; i < step; i++) {
            const upperBound = currentFromTime + (i + 1) * duration;
            const to = upperBound > currentToTime ? currentToTime : upperBound;
            let data = await optionService.GetDeltaHistories(currentFromTime + i * duration, to);
            data = data.map((datum) => {
                return {
                    ...datum,
                    expiration: moment(datum.expiration).format("MMM DD YYYY"),
                    date_time: moment(datum.timestamp).format("DD/MM/YY HH:mm:ss"),
                    cash: formatMoney(datum.cash, 4),
                    commissions: formatMoney(datum.commissions, 4),
                    options: formatMoney(datum.options, 4),
                    futures: formatMoney(datum.futures, 4),
                    expiration_value: formatMoney(datum.expiration_value, 4),
                    total: formatMoney(datum.total, 4),
                };
            });
            arr = [...data, ...arr];
            setHistories(arr);
        }
        setLoading(false);
        return arr;
    };

    const csvInstance = useRef(null);

    const deltaHistoriesColumns = [
        {
            accessor: "timestamp",
            Header: "Timestamp",
        },
        {
            accessor: "date_time",
            Header: "Date Time",
        },
        {
            accessor: "expiration",
            Header: "Expiration",
        },
        {
            accessor: "cash",
            Header: "Cash",
        },
        {
            accessor: "commissions",
            Header: "Commissions",
        },
        {
            accessor: "options",
            Header: "Options",
        },
        {
            accessor: "futures",
            Header: "Futures",
        },
        {
            accessor: "expiration_value",
            Header: "Expiration Value",
        },
        {
            accessor: "total",
            Header: "Total",
        },
        {
            accessor: "open_orders",
            Header: "Open Orders",
        },
        {
            accessor: "future_position_size",
            Header: (
                <span>
                    Future <br /> Position Size
                </span>
            ),
        },
        {
            accessor: "price_in_calc_unsettled_cash",
            Header: (
                <span>
                    Price In Calc <br /> Unsettled Cash
                </span>
            ),
        },
        {
            accessor: "future_current_price",
            Header: (
                <span>
                    Future <br /> Current Price
                </span>
            ),
        },
        {
            accessor: "future_last_settled_price",
            Header: (
                <span>
                    Future Last <br /> Settled Price
                </span>
            ),
        },
    ];

    const exportDeltaHistories = async () => {
        const trades = await getDeltaHistories();
        await exportCSV(trades, deltaHistoriesColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => {
                    return item[c.accessor];
                });
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getDeltaHistories}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Delta Histories
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportDeltaHistories}
                            disabled={loading}
                        >
                            Export Delta Histories
                        </Button>

                        <CSVLink
                            data={csvData}
                            filename={`Delta_Histories_${fromTime}_${toTime}.csv`}
                            ref={csvInstance}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    {histories?.length ? (
                        <PaginateTable columns={deltaHistoriesColumns} data={histories} className="w-100 text-center" />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default DeltaHistories;
