import React, { useState, useEffect, useRef } from "react";
import { Modal, InputGroup, Button, ButtonGroup } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import AmCharts from "@amcharts/amcharts3-react";
import * as _ from "lodash";
import moment from "moment";

export default function ChartIvMoneyness(props) {
    const [show, setShow] = useState(false);
    const [tableConfig, setTableConfig] = useState(getTableConfig([]));
    const [chartData, setchartData] = useState({
        labels: [],
        datasets: [
            {
                label: "IV",
                data: [],
                borderColor: "#307bff",
            },
            // {
            //   type: 'scatter',
            //   label: 'IV ATM',
            //   data: [],
            //   borderColor: "#307bff",
            // }
        ],
    });

    const chartOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        stepSize: 5,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        stepSize: 0.5,
                    },
                },
            ],
        },
        // tooltips: {
        //   callbacks: {
        //     footer: function(tooltipItems, data) {
        //       console.log("-------------", tooltipItems, data)
        //       // const dataItem = props.tableData[tooltipItems[0].index]
        //       // return ` iv: ${dataItem.iv} \n strike: ${dataItem.strike} \n monneyness: ${dataItem.moneyness}`;
        //     },
        //   },
        // }
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        handleThroteUpdateTableConfig(props.tableData);
        setShow(true);
    };

    const handleThroteUpdateTableConfig = (tableData) => {
        const arrayChartData = [];
        const isFoundZero = false;
        const isFound_1 = false;
        const isFound_2 = false;
        const isFound1 = false;
        const isFound2 = false;

        tableData.map((i) => {
            if (+i.moneyness > 3 || +i.moneyness < -3) return;

            // if(+i.moneyness > 0 && !isFoundZero){
            //   isFoundZero=true

            //   arrayChartData.push({
            //     moneyness: 0,
            //     iv: props.ivATM * 100,
            //     strike: props.theoreticalPrice
            //   })
            // } else if (+i.moneyness > -1 && !isFound_1){
            //   isFound_1=true

            //   arrayChartData.push({
            //     moneyness: -100,
            //     iv: props.p1Iv
            //   })

            // } else if(+i.moneyness > -2 && !isFound_2){
            //   isFound_2=true

            //   arrayChartData.push({
            //     moneyness: -200,
            //     iv: props.p2Iv
            //   })
            // } else if(+i.moneyness > 1 && !isFound1){
            //   isFound1=true

            //   arrayChartData.push({
            //     moneyness: 100,
            //     iv: props.c1Iv
            //   })
            // } else if(+i.moneyness > 2 && !isFound2){
            //   isFound2=true

            //   arrayChartData.push({
            //     moneyness: 200,
            //     iv: props.c2Iv,
            //   })
            // }

            arrayChartData.push({
                ...i,
                moneyness: i.moneyness * 100,
                y_density: +i.strike_density * 5 + 50,
            });
        });

        const newTableConfig = getTableConfig(arrayChartData);
        setTableConfig(newTableConfig);
    };

    const throttleUpdateTableConfig = useRef(_.throttle(handleThroteUpdateTableConfig, 5000)).current;

    useEffect(() => {
        throttleUpdateTableConfig(props.tableData);
    }, [props.tableData, props.ivATM, props.p1Iv, props.p2Iv, props.c1Iv, props.c2Iv]);

    function getTableConfig(dataProvider) {
        // const timeBaseData = dataProvider.map(d => ({...d, date: new Date(d.timestamp)}))
        return {
            type: "xy",
            theme: "none",
            dataProvider,
            marginRight: 80,
            dataDateFormat: "YYYY-MM-DD",
            startDuration: 0,
            pathToImages: "https://cdn.amcharts.com/lib/3/images/",
            graphs: [
                {
                    xField: "moneyness",
                    yField: "iv",
                    valueField: 1,
                    bullet: "diamond",
                    bulletSize: 10,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    // "valueField": "iv",
                    hideBulletsCount: 120,
                    position: "left",
                    step: 0.5,
                    balloonFunction(item, content) {
                        const dataContext = item.dataContext;
                        var html = `<p style="font-size: 120%; font-weight: bold; margin-bottom: 15px;">
              <p>IV</p>
              <p> IV: ${dataContext.iv}</p>
              <p> Strike: ${dataContext.strike}</p>
              <p> Monneyness: ${dataContext.moneyness}</p>
              </p>`;

                        return html;
                    },
                },
                {
                    xField: "moneyness",
                    yField: "y_density",
                    valueField: 1,
                    bullet: "round",
                    bulletSize: 10,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    // "valueField": "iv",
                    hideBulletsCount: 120,
                    position: "left",
                    step: 0.5,
                    balloonFunction(item, content) {
                        const dataContext = item.dataContext;
                        var html = `<p style="font-size: 120%; font-weight: bold; margin-bottom: 15px;">
              <p>DENSITY</p>
              <p> IV: ${dataContext.iv}</p>
              <p> Density: ${dataContext.strike_density}</p>
              <p> Monneyness: ${dataContext.moneyness}</p>
              </p>`;

                        return html;
                    },
                },
            ],
            // "chartScrollbar": {
            //   "offset": 15,
            //   "scrollbarHeight": 5
            // },

            chartCursor: {
                pan: true,
                cursorAlpha: 0,
                valueLineAlpha: 0,
            },
        };
    }

    return (
        <>
            <Button size="sm" className="mb-1 mr-1" variant="primary" onClick={handleShow}>
                Show IV & Moneyness chart
            </Button>

            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        IV & Moneyness: {moment(props.expiration).format("D MMMM Y")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Line data={chartData} options={chartOptions} /> */}
                    <AmCharts.React
                        style={{
                            width: "100%",
                            height: "600px",
                        }}
                        options={tableConfig}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}
