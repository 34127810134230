import React from "react";
import styled from "styled-components";
import cx from "classnames";

import Spinner, { SpinnerColor, SpinnerSize } from "app/components/_base/Spinner";
import theme from "theme";

export type ButtonColorType = "primary" | "secondary" | "danger";
type SizeType = "s" | "m" | "l";

const SPINNER_COLOR_MAP: Record<ButtonColorType, SpinnerColor> = {
    primary: "dark",
    secondary: "light",
    danger: "warning",
};

const SPINNER_SIZE_MAP: Record<SizeType, SpinnerSize> = { s: "s", m: "m", l: "m" };

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: SizeType;
    loading?: boolean;
    fitWidth?: boolean;
    className?: string;
    color?: ButtonColorType;
}

const FillButton: React.FC<Props> = ({
    loading,
    children,
    disabled,
    fitWidth,
    className,
    size = "m",
    color = "primary",
    ...props
}) => {
    const isLoading = !disabled && loading;

    return (
        <button
            className={cx("fillButton", className, size, {
                loading: isLoading,
                disabled: !!disabled,
                [color]: !disabled && Boolean(color),
                minWidth: `${fitWidth ? 0 : 80}px`,
            })}
            disabled={disabled || loading}
            type="button"
            {...props}
        >
            <span className="content">
                {isLoading && <Spinner size={SPINNER_SIZE_MAP[size]} color={SPINNER_COLOR_MAP[color]} />}
                {children && <span className="text">{children}</span>}
            </span>
        </button>
    );
};

export default styled(FillButton)`
    opacity: 1;
    flex: 0 0 auto;
    padding: 0 16px;
    border-radius: 4px;
    transition-property: opacity, background-color, color;
    transition-duration: ${theme.animSpeed.fast};
    white-space: nowrap;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.typo.T16_M}

    &:hover:not(.disabled):not(.loading) {
        opacity: 0.8;
    }

    &.s {
        height: 32px;
        ${theme.typo.T14_M}
    }

    &.m {
        height: 40px;
        ${theme.typo.T14_M}
    }

    &.l {
        height: 48px;
        ${theme.typo.T16_M}
    }

    &.primary {
        color: white;
        background-color: ${theme.color.primary[500]};
    }

    &.secondary {
        color: ${theme.color.ink[500]};
        background-color: ${theme.color.ink[200]};
    }

    &.danger {
        color: ${theme.color.red[500]};
        background-color: ${theme.color.red[200]};
    }

    &[disabled] {
        cursor: not-allowed;
        color: ${theme.color.ink[300]};
        background-color: ${theme.color.ink[100]};
        /* pointer-events: none; */
        /* Workaround for disabled button mouseleave event bug on Chrome/Safari */
        /* https://github.com/facebook/react/issues/4251#issuecomment-267004045 */
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;

        &.icon-left {
            flex-direction: row;
            flex: 0 0 auto;

            .text {
                margin-left: 8px;
            }
        }

        &.icon-right {
            flex-direction: row-reverse;
            flex: 0 0 auto;

            .text {
                margin-right: 8px;
            }
        }
    }

    &.loading {
        cursor: not-allowed;

        .content .text {
            margin-left: 8px;
        }
    }
`;
