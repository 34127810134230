interface TypoProps {
    fontSize: number;
    lineHeight: number;
    letterSpacing?: number;
    fontWeight: "normal" | 500 | 700;
    textTransform?: "capitalize" | "uppercase" | "lowercase" | "revert";
}

export const getTypo = ({ fontSize, lineHeight, fontWeight, letterSpacing, textTransform }: TypoProps): string => `
  ${letterSpacing ? `letter-spacing: ${letterSpacing}em;` : ""}
  ${textTransform ? `text-transform: ${textTransform};` : ""}
  line-height: ${lineHeight}px;
  font-weight: ${fontWeight};
  font-size: ${fontSize}px;
`;
