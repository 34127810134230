import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OptionService from "app/services/option";
import ReactJson from "react-json-view";
import { InputGroup, Form, Container, Col, Row, Button } from "react-bootstrap";

const SELECTOR_SIDE = ["buy", "sell"];

export default function OptionOrderHandler() {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [isLoading, setLoading] = useState(false);
    const [instrumentName, setInstrumentName] = useState(null);
    const [instrumentSelector, setInstrumentSelector] = useState([]);
    const [side, setSide] = useState(SELECTOR_SIDE[0]);
    const [price, setPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isMust, setIsMust] = useState(false);

    const [data, setData] = useState({});

    useEffect(() => {
        if (optionService) {
            getArrayExpiry();
        }
    }, [optionService]);

    const getArrayExpiry = async () => {
        const optionInstruments = await optionService.LoadOptionInstruments();

        if (optionInstruments && optionInstruments.length) {
            const arrayInstrumentName = optionInstruments.map((option) => option.instrument_name);
            setInstrumentSelector(arrayInstrumentName);
            setInstrumentName(arrayInstrumentName[0]);
        }
    };

    const getApiData = async () => {
        setLoading(true);
        setData({});
        const data = await optionService.GetApiData("debug_OptionHandler", [
            instrumentName,
            side,
            price,
            totalAmount,
            isMust,
        ]);
        setLoading(false);
        setData(data);
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Instrument Name</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                                as="select"
                                value={instrumentName}
                                onChange={(e) => setInstrumentName(e.target.value)}
                            >
                                {instrumentSelector.map((futureName) => {
                                    return (
                                        <option key={futureName} value={futureName}>
                                            {futureName}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Side</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" value={side} onChange={(e) => setSide(e.target.value)}>
                                {SELECTOR_SIDE.map((side) => (
                                    <option key={side} value={side}>
                                        {side}
                                    </option>
                                ))}
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Price</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                value={price}
                                onChange={(e) => setPrice(+e.target.value)}
                                type="number"
                                step="0.01"
                                placeholder="Price"
                                aria-label="Price"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Total Amount</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                value={totalAmount}
                                onChange={(e) => setTotalAmount(+e.target.value)}
                                type="number"
                                placeholder="Total Amount"
                                aria-label="Total Amount"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Is Must</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control as="select" value={isMust} onChange={(e) => setIsMust(e.target.value)}>
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getApiData}
                            disabled={isLoading}
                        >
                            {isLoading && <div className="spinner-border spinner-border-sm" />} Send
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="p-0">
                        <div>Data</div>
                        <ReactJson
                            src={data}
                            name={false}
                            collapseStringsAfterLength={5}
                            indentWidth={2}
                            displayDataTypes={false}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
