import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
    text: "",
    title: "",
    type: "error",
};

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        clearNotification: () => initialState,
        toast: (state, action) => {
            const { id = nanoid(), text, type, title } = action.payload;

            state.id = id;
            state.type = type;
            state.title = title;

            if (!text && type === "error") {
                state.text = "Something went wrong";
            } else {
                state.text = text;
            }
        },
    },
});

export const { toast } = toastSlice.actions;
export default toastSlice;
