import { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import ComponentErrorBoundary from "../Errors/ComponentErrorBoundary";

import Home from "app/components/Home/Home";

import Debug from "app/components/Debug";
import ToastNotificationContainer from "app/components/_base/Toast";

import "react-datepicker/dist/react-datepicker.css";

const Body = () => {
    return (
        <div>
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <ComponentErrorBoundary>
                        <ToastNotificationContainer />
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/debug" exact component={Debug} />
                            <Route path="/debug/:tab" exact component={Debug} />
                            <Redirect to={"/"} />
                        </Switch>
                    </ComponentErrorBoundary>
                </Suspense>
            </BrowserRouter>
        </div>
    );
};

export default Body;
