import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { InputGroup, Container, Col, Row, Button } from "react-bootstrap";
import DateTimeInput from "app/components/_base/DateTimeInput";
import { CSVLink } from "react-csv";
import PaginateTable from "./PaginateTable";
import OptionService from "app/services/option";
import moment from "moment";
import { formatMoney } from "app/helper/function";

const OptionAttempts = () => {
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 1209600000); // 2 weeks
    const [toTime, setToTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const [csvData, setCsvData] = useState([]);
    const [trades, setTrades] = useState([]);

    const getOptionAttempts = async () => {
        setLoading(true);

        const currentFromTime = new Date(fromTime).getTime();
        const currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();

        let data = await optionService.GetOptionAttempts(currentFromTime, currentToTime);
        data = data.map((datum) => {
            return {
                ...datum,
                market_update_time: moment(datum.market_update_time / 1000).format("DD-MM-YYYY HH:mm:ss.SSS"),
                server_update_time: datum.server_update_time / 1000,
                receive_time: datum.receive_time / 1000,
                trade_direction: datum.trade_direction.toLowerCase(),

                // rounding 4 decimals for Planned price, misprice magnitude, actual price
                option_planned_price: formatMoney(datum.option_planned_price, 4),
                option_misprice_magnitude: formatMoney(datum.option_misprice_magnitude, 4),
                option_price: formatMoney(datum.option_price, 4),
            };
        });
        setTrades(data);
        setLoading(false);
        return data;
    };

    const csvInstance = useRef(null);

    const optionAttemptColumns = [
        {
            accessor: "timestamp",
            Header: "Timestamp",
        },
        {
            accessor: "market_update_time",
            Header: "Market Update Time",
        },
        {
            accessor: "last_update_type",
            Header: "Last Update Type",
        },
        {
            accessor: "server_update_time",
            Header: "Server Update Time",
        },
        {
            accessor: "receive_time",
            Header: "Receive Time",
        },
        {
            accessor: "option_type",
            Header: "Option Type",
        },
        {
            accessor: "option_planned_price",
            Header: "Option Planned Price",
        },
        {
            accessor: "option_misprice_magnitude",
            Header: "Option Misprice Magnitude",
        },
        {
            accessor: "option_planned_amount",
            Header: "Option Planned Amount",
        },
        {
            accessor: "trade_direction",
            Header: "Trade Direction",
        },
        {
            accessor: "future_ask_bid_amount",
            Header: "Future Bid/Ask Amount",
        },
        {
            accessor: "future_ask_bid_price",
            Header: "Future Bid/Ask Price",
        },
        {
            accessor: "planned_future_amount",
            Header: "Planned Future Amount",
        },
        {
            accessor: "planned_net_profit",
            Header: "Planned Net Profit",
        },
        {
            accessor: "planned_cash_flow",
            Header: "Planned Cash Flow",
        },
        {
            accessor: "trade_category",
            Header: "Trade Category",
        },
        {
            accessor: "first_order_time",
            Header: "Send First Order Time",
        },
        // For Success and Partial trade
        {
            accessor: "option_amount",
            Header: "Option Amount",
        },
        {
            accessor: "option_price",
            Header: "Option Price",
        },
        {
            accessor: "future_amount",
            Header: "Future Amount",
        },
        {
            accessor: "future_price",
            Header: "Future Price",
        },
        {
            accessor: "trade_completion_time",
            Header: "Trade Completion Time",
        },
        {
            accessor: "actual_pnl_per_contract",
            Header: "Actual PnL Per Contract",
        },
        {
            accessor: "actual_trade_pnl",
            Header: "Actual Trade PnL",
        },
    ];

    const exportOptionAttempts = async () => {
        const trades = await getOptionAttempts();
        await exportCSV(trades, optionAttemptColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => {
                    return item[c.accessor];
                });
                newCsvData.push(rowData);
            });
            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={fromTime} onDateChange={(date) => setFromTime(date)} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(date) => setToTime(date)} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getOptionAttempts}
                            disabled={loading}
                        >
                            {loading && <div className="spinner-border spinner-border-sm" />} Get Option Attempts
                        </Button>

                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="info"
                            onClick={exportOptionAttempts}
                            disabled={loading}
                        >
                            Export Option Attempts
                        </Button>

                        <CSVLink
                            data={csvData}
                            filename={`Option_Attempts_${fromTime}_${toTime}.csv`}
                            ref={csvInstance}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    {trades?.length ? (
                        <PaginateTable columns={optionAttemptColumns} data={trades} className="w-100 text-center" />
                    ) : null}
                </Row>
            </Container>
        </div>
    );
};

export default OptionAttempts;
