import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { InputGroup, Container, Col, Row, FormControl } from "react-bootstrap";
import styled from "styled-components";

import DateTimeInput from "app/components/_base/DateTimeInput";
import BaseButton from "app/components/_base/Button";
import OptionService from "app/services/option";
import { toast } from "app/components/_base/Toast/slice";

import PaginateTable from "./PaginateTable";

const generalColumns = [
    {
        Header: "Leading Contract",
        accessor: "leading_contract",
    },
    {
        Header: "Leading Contract Price",
        accessor: "leading_contract_price",
    },
    {
        Header: "timestamp",
        accessor: "timestamp",
    },
    {
        Header: "iv 30 day",
        accessor: "iv30day",
    },
    {
        Header: "iv 90 day",
        accessor: "iv90day",
    },
    {
        Header: "p1 iv 30",
        accessor: "p1_iv_30",
    },
    {
        Header: "c1 iv 30",
        accessor: "c1_iv_30",
    },
    {
        Header: "p2 iv 30",
        accessor: "p2_iv_30",
    },
    {
        Header: "c2 iv 30",
        accessor: "c2_iv_30",
    },
    {
        Header: "p1 iv 90",
        accessor: "p1_iv_90",
    },
    {
        Header: "c1 iv 90",
        accessor: "c1_iv_90",
    },
    {
        Header: "p2 iv 90",
        accessor: "p2_iv_90",
    },
    {
        Header: "c2 iv 90",
        accessor: "c2_iv_90",
    },

    {
        Header: "p1 ratio 30",
        accessor: "p1_ratio_30",
    },
    {
        Header: "p1 ratio 90",
        accessor: "p1_ratio_90",
    },
    {
        Header: "c1 ratio 30",
        accessor: "c1_ratio_30",
    },
    {
        Header: "c1 ratio 90",
        accessor: "c1_ratio_90",
    },

    {
        Header: "p2 ratio 30",
        accessor: "p2_ratio_30",
    },
    {
        Header: "p2 ratio 90",
        accessor: "p2_ratio_90",
    },
    {
        Header: "c2 ratio 30",
        accessor: "c2_ratio_30",
    },
    {
        Header: "c2 ratio 90",
        accessor: "c2_ratio_90",
    },
    {
        Header: "index_price",
        accessor: "index_price",
    },
    {
        Header: "perp_price",
        accessor: "perp_price",
    },
    {
        Header: "latency",
        accessor: "latency",
    },
    {
        Header: "iso date",
        accessor: "iso_date",
    },
    {
        Header: "perp_bid_qty",
        accessor: "perp_bid_qty",
    },
    {
        Header: "perp_bid_price",
        accessor: "perp_bid_price",
    },
    {
        Header: "perp_ask_qty",
        accessor: "perp_ask_qty",
    },
    {
        Header: "perp_ask_price",
        accessor: "perp_ask_price",
    },
    {
        Header: "perp_last_price",
        accessor: "perp_last_price",
    },
    {
        Header: "perp_last_trade_time",
        accessor: "perp_last_trade_time",
    },
];

const serialColumns = [
    {
        Header: "Expiration",
        accessor: "expiration",
    },
    {
        Header: "future contract",
        accessor: "future_contract",
    },
    {
        Header: "future contract price",
        accessor: "future_contract_price",
    },
    {
        Header: "underlying rounded",
        accessor: "underlying",
    },
    {
        Header: "pricing offset",
        accessor: "pricing_offset",
    },
    {
        Header: "at the money iv",
        accessor: "at_the_money_iv",
    },
    {
        Header: "p1 ratio",
        accessor: "p1_ratio",
    },
    {
        Header: "c1 ratio",
        accessor: "c1_ratio",
    },
    {
        Header: "p2 ratio",
        accessor: "p2_ratio",
    },
    {
        Header: "c2_ratio",
        accessor: "c2_ratio",
    },

    {
        Header: "p1",
        accessor: "p1",
    },
    {
        Header: "p2",
        accessor: "p2",
    },
    {
        Header: "c1",
        accessor: "c1",
    },
    {
        Header: "c2",
        accessor: "c2",
    },
    {
        Header: "timestamp",
        accessor: "timestamp",
    },
    {
        Header: "iso date",
        accessor: "iso_date",
    },
];

const MODE = {
    General: "General",
    Series: "Series",
};

const asyncWithTimeout = async (runner, timeout) => {
    return new Promise((resolve) => {
        runner()
            .then((resp) => {
                resolve({
                    success: true,
                    data: resp,
                });
            })
            .catch((err) => {
                resolve({
                    success: false,
                    error: err?.message || "something went wrong",
                });
            });

        setTimeout(() => {
            resolve({
                success: false,
                error: "timed out",
            });
        }, timeout);
    });
};

const HistoricalData = ({ className }) => {
    const dispatch = useDispatch();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState({});

    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const [fromTime, setFromTime] = useState(Date.now() - 24 * 60 * 60 * 1000);
    const [toTime, setToTime] = useState(Date.now());

    const csvInstance = useRef(null);
    const [csvData, setCsvData] = useState([]);

    const [generalData, setGeneralData] = useState([]);
    const [serialData, setSerialData] = useState([]);
    const [interval, setInterval] = useState(0);
    const [selectedMode, setSelectedMode] = useState(null);
    const [loading, setLoading] = useState(false);

    const shouldGetButtonsBeDisabled = !fromTime || !toTime || (!interval && interval !== 0);

    const handleClickGetGeneralData = async () => {
        if (loading || shouldGetButtonsBeDisabled) {
            return;
        }

        setSelectedMode(MODE.General);
        setLoading(true);

        const { success, data, error } = await asyncWithTimeout(getGeneralData, 120_000);

        if (success) {
            setGeneralData(data);
        } else {
            dispatch(toast({ type: "error", text: error }));
        }

        setLoading(false);
    };

    const handleClickGetSeriesData = async () => {
        if (loading || shouldGetButtonsBeDisabled) {
            return;
        }

        setSelectedMode(MODE.Series);
        setLoading(true);

        const { success, data, error } = await asyncWithTimeout(getSeriesData, 120_000);

        if (success) {
            setSerialData(data);
        } else {
            dispatch(toast({ type: "error", text: error }));
        }

        setLoading(false);
    };

    const getGeneralData = async () => {
        console.log("getGeneralData");
        let generalData = [];

        if (!fromTime) {
            return;
        }

        const currentFromTime = new Date(fromTime).getTime();
        let currentToTime = toTime ? new Date(toTime).getTime() : new Date().getTime();
        let loopFetch = true;

        while (loopFetch) {
            const currentData = await optionService.GetGeneralData(currentFromTime, currentToTime, interval);

            if (currentData && currentData.length) {
                const lastItem = currentData[currentData.length - 1];
                currentToTime = lastItem.timestamp - 1;
                const convertedData = currentData.map((d) => ({ ...d, iso_date: new Date(d.timestamp).toISOString() }));
                generalData = [...generalData, ...convertedData];
            } else {
                loopFetch = false;
            }
        }

        return generalData;
    };

    const getSeriesData = async () => {
        const seriesData = [];

        if (!fromTime) {
            return;
        }

        const currentFromTime = fromTime;
        let currentToTime = toTime || Date.now();
        let loopFetch = true;

        while (loopFetch) {
            const currentData = await optionService.GetActiveSeries(currentFromTime, currentToTime, interval);
            if (currentData && currentData.length) {
                const lastItem = currentData[currentData.length - 1];
                currentToTime = lastItem.timestamp - 1;
                const convertedData = currentData.map((d) => ({ ...d, iso_date: new Date(d.timestamp).toISOString() }));
                seriesData.push(...convertedData);
            } else {
                loopFetch = false;
            }
        }

        return seriesData;
    };

    const handleClickExportButton = () => {
        if (selectedMode === MODE.General) {
            exportCSV(generalData, generalColumns);
        } else if (selectedMode === MODE.Series) {
            exportCSV(serialData, serialColumns);
        }
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];

        data.forEach((item) => {
            const rowData = columns.map((c) => item[c.accessor]);
            newCsvData.push(rowData);
        });

        setCsvData(newCsvData);

        Promise.resolve().then(() => {
            csvInstance.current.link.click();
        });
    };

    return (
        <div className={className}>
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <DateTimeInput date={fromTime} onDateChange={(e) => setFromTime(new Date(e).getTime())} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>To Time (local)</InputGroup.Text>
                            </InputGroup.Prepend>

                            <DateTimeInput date={toTime} onDateChange={(e) => setToTime(new Date(e).getTime())} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Interval</InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                value={interval}
                                onChange={(e) => setInterval(+e.target.value)}
                                placeholder="Interval"
                                aria-label="Interval"
                                type="number"
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <div className="buttonRow">
                    <div className="group">
                        <BaseButton.Fill
                            disabled={shouldGetButtonsBeDisabled}
                            loading={loading && selectedMode === MODE.General}
                            size="s"
                            onClick={handleClickGetGeneralData}
                        >
                            Get General Data
                        </BaseButton.Fill>

                        <BaseButton.Fill
                            disabled={shouldGetButtonsBeDisabled}
                            loading={loading && selectedMode === MODE.Series}
                            size="s"
                            onClick={handleClickGetSeriesData}
                        >
                            Get Series Data
                        </BaseButton.Fill>
                    </div>

                    <div className="group">
                        <BaseButton.Fill
                            disabled={!selectedMode || loading}
                            color="secondary"
                            size="s"
                            onClick={handleClickExportButton}
                        >
                            Export CSV {selectedMode}
                        </BaseButton.Fill>
                    </div>
                </div>

                <CSVLink data={csvData} filename={`${fromTime}_${toTime}_${selectedMode}.csv`} ref={csvInstance} />

                <div className="oversize">
                    {selectedMode ? <h1>{selectedMode.toUpperCase()}</h1> : <div />}

                    {selectedMode == MODE.General ? (
                        <PaginateTable columns={generalColumns} data={generalData} />
                    ) : null}

                    {selectedMode == MODE.Series ? <PaginateTable columns={serialColumns} data={serialData} /> : null}
                </div>
            </Container>
        </div>
    );
};

export default styled(HistoricalData)`
    padding-top: 30px;

    .buttonRow {
        display: flex;
        justify-content: space-between;

        .group {
            display: flex;
            column-gap: 0.5em;
        }
    }

    .oversize {
        max-width: 2000px;
        height: auto;
    }
`;
