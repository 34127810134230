import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactJson from "react-json-view";
import { Container, Button } from "react-bootstrap";

import OptionService from "app/services/option/";
import PaginateTable from "./ServerSidePaginateTable";
import useInterval from "app/services/useInterval";
import { toast } from "app/components/_base/Toast/slice";
import { CSVLink } from "react-csv";
import moment from "moment";

const Loader = () => {
    return <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" />;
};

export default function HardCross() {
    const dispatch = useDispatch();
    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);

    // Status
    const [getStatusLoading, setStatusLoading] = useState(false);
    const [status, setStatus] = useState(null);

    // Mode
    const [getModeLoading, setModeLoading] = useState(false);
    const [mode, setMode] = useState(null);
    const [requestStatus, setRequestStatus] = useState("");

    // hard cross logs
    const [getLogsLoading, setGetLogsLoading] = useState(false);
    const [logs, setLogs] = useState({ page: 0, limit: 0, total: 0, data: [] });
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [csvData, setCsvData] = useState([]);
    const csvInstance = useRef(null);

    const onChangePageIndex = async (e) => {
        setOffset(e);
        onGetHardCrossLog(e, limit);
    };

    const onChangePageSize = (e) => {
        setLimit(e);
        onGetHardCrossLog(offset, e);
    };

    // hard cross status
    const getStatus = (s) => s.getHardCrossStatus().then((res) => setStatus(res.is_enable));

    const setStatusFunc = async (val) => {
        setStatusLoading(true);
        setRequestStatus("");
        optionService.setHardCross(val).then((res) => {
            getStatus(optionService);
            setRequestStatus(res.error);
            setStatusLoading(false);
        });
    };

    // hard cross mode
    const getMode = (s) => s.GetHardCrossHunterMode().then((res) => setMode(res.mode));

    const setModeFunc = (val) => {
        setModeLoading(true);
        setRequestStatus("");
        optionService.SetHardCrossHunterMode(val).then((res) => {
            getMode(optionService);
            setRequestStatus(res.error);
            setModeLoading(false);
        });
    };

    // hard cross log
    const onGetHardCrossLog = async (off, lim) => {
        setGetLogsLoading(true);
        try {
            const result = await optionService.getHardCrossLog(off, lim);
            setGetLogsLoading(false);
            setLogs(result);
            dispatch(toast({ type: "success", text: "Fetch successfully" }));
            return result.data;
        } catch (e) {
            setGetLogsLoading(false);
            console.log("GetHardCrossLog Error", e);
            dispatch(toast({ type: "error", text: `GetHardCrossLog Error: ${e}` }));
        }
    };

    const exportHardCrossLog = async () => {
        const hardCrossLog = await onGetHardCrossLog(offset, limit);
        await exportCSV(hardCrossLog, logColumns);
    };

    const exportCSV = async (data, columns) => {
        const newCsvData = [columns.map((c) => c.Header)];
        if (data && data.length) {
            data.forEach((item) => {
                const rowData = columns.map((c) => {
                    if (typeof c.accessor === "function") return c.accessor(item);
                    if (typeof item[c.accessor] === "object")
                        return JSON.stringify(item[c.accessor]).replace(/"/g, '""');

                    return item[c.accessor];
                });
                newCsvData.push(rowData);
            });

            setCsvData(newCsvData);
            csvInstance.current.link.click();
        }
    };

    const logColumns = useMemo(
        () => [
            {
                Header: "Timestamp",
                accessor: "timestamp",
            },
            {
                Header: "Local Time",
                accessor: (row) => row.timestamp && new Date(row.timestamp).toString(),
            },
            {
                Header: "Type",
                accessor: "type",
            },
            {
                Header: "Data",
                accessor: "data",
                Cell: ({ value }) => {
                    if (typeof value === "object") {
                        return <ReactJson src={value} displayDataTypes={false} />;
                    }

                    return value;
                },
            },
        ],
        []
    );

    // useEffect, useInterval
    useEffect(() => {
        const s = new OptionService({ secret, currency });
        setOptionService(s);
        getStatus(s);
        getMode(s);
    }, [secret, currency]);

    useInterval(() => getStatus(optionService), 2000);
    useInterval(() => getMode(optionService), 20_000);

    return (
        <div className="global_config">
            <Container variant="dark">
                <div className="row">
                    <div className="col-xl-3 col-12">
                        <div className="mb-2">Hard Cross Mode: {getModeLoading ? <Loader /> : mode}</div>
                        <Button variant="success" onClick={() => setModeFunc("LOG")}>
                            LOG
                        </Button>
                        &ensp;
                        <Button variant="info" onClick={() => setModeFunc("INTERACTIVE")}>
                            INTERACTIVE
                        </Button>
                        &ensp;
                        <Button variant="warning" onClick={() => setModeFunc("AUTO")}>
                            AUTO
                        </Button>
                    </div>

                    <div className="col-xl-3 col-12">
                        <div className="mb-2">
                            Hard Cross Enable: {getStatusLoading ? <Loader /> : JSON.stringify(status).toUpperCase()}
                        </div>
                        <Button variant="success" onClick={() => setStatusFunc(true)}>
                            ON
                        </Button>
                        &ensp;
                        <Button variant="warning" onClick={() => setStatusFunc(false)}>
                            OFF
                        </Button>
                        <i>&ensp; {getStatusLoading ? <Loader /> : requestStatus}</i>
                    </div>
                </div>

                <div className="pt-4">
                    <Button
                        variant="primary"
                        onClick={() => onGetHardCrossLog(offset, limit)}
                        disabled={getLogsLoading}
                        className="mr-3"
                    >
                        {getLogsLoading && <div className="spinner-border spinner-border-sm" />} Get Hard Cross Logs
                    </Button>

                    <Button variant="info" onClick={exportHardCrossLog} disabled={getLogsLoading}>
                        {getLogsLoading && <div className="spinner-border spinner-border-sm" />} Export Hard Cross Logs
                    </Button>
                    <CSVLink data={csvData} filename={`Hard_Cross_log${moment().format("x")}.csv`} ref={csvInstance} />
                </div>
                {logs.limit ? (
                    <PaginateTable
                        columns={logColumns}
                        data={logs.data}
                        offset={offset}
                        limit={limit}
                        total={logs.total}
                        onChangePageIndex={onChangePageIndex}
                        onChangePageSize={onChangePageSize}
                    />
                ) : null}
            </Container>
        </div>
    );
}
