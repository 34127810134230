import React from "react";
import styled from "styled-components";
import cx from "classnames";

import CheckCircle from "../Icon/CheckCircle";
import AlertTriangle from "../Icon/AlertTriangle";
import AlertCircle from "../Icon/AlertCircle";
import Information from "../Icon/Information";

const ToastConfigByType = {
    success: CheckCircle,
    error: AlertCircle,
    warning: AlertTriangle,
    info: Information,
};

const ToastMessage = ({ text, title, type, className }) => {
    const Component = ToastConfigByType[type] ?? null;

    return (
        <div className={cx("toastMessage", className)}>
            <Component />

            <div className="title">{title}</div>
            <div className="content">{text}</div>
        </div>
    );
};

export default styled(ToastMessage)`
    display: grid;
    grid-column-gap: 8px;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;

    .icon {
        grid-row: span 2;
        grid-column: 1 / 2;
    }

    .title {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        color: #22313f;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .content {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
    }
`;
