import * as crypto from "crypto";
import axios from "axios";

const ENDPOINT = {
    BTC_API_URL: process.env.REACT_APP_BTC_API_URL,
    ETH_API_URL: process.env.REACT_APP_ETH_API_URL,
};

export class OptionService {
    constructor(props) {
        this.secret = props.secret;
        this.currency = props.currency;
        this.RPC_ENDPOINT = this.getRpcEndpoint(props.currency);
    }

    signData = (data) => {
        const timestamp = Math.floor(Date.now() / 1000);
        const dataString = JSON.stringify(data);
        const buf = Buffer.from(dataString);
        const hexData = buf.toString("hex");
        const encodeJson = { data: hexData, timestamp };
        const HmacSign = crypto.createHmac("sha256", this.secret);
        const signature = HmacSign.update(JSON.stringify(encodeJson)).digest("hex");

        return {
            data: hexData,
            timestamp,
            signature,
        };
    };

    getRpcEndpoint = (currency) => {
        return currency ? ENDPOINT[`${currency}_API_URL`] + "/api" : ENDPOINT.BTC_API_URL + "/api";
    };

    LoadSystemConfig = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_OptionConfig",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getLiveFuture = () => {
        return new Promise((resolve, reject) => {
            const subDomain = process.env.REACT_APP_ENVIRONMENT === "Testnet" ? "test" : "www";
            const url = `https://${subDomain}.deribit.com/api/v2/public/get_instruments?currency=${this.currency}&expired=false&kind=future`;
            axios
                .get(url)
                .then((res) => resolve(res.data.result))
                .catch((err) => reject(err));
        });
    };

    GetLastTradesByInstruments = (instruments) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetLastTradeByInstruments",
                    params: [instruments],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    LoadFutureInstrument = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`https://www.deribit.com/api/v2/public/get_instruments?currency=${this.currency}&kind=future`)
                .then((res) => resolve(res.data.result))
                .catch((err) => reject(err));
        });
    };

    LoadOptionInstruments = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(`https://www.deribit.com/api/v2/public/get_instruments?currency=${this.currency}&kind=option`)
                .then((res) => resolve(res.data.result))
                .catch((err) => reject(err));
        });
    };

    LoadExpiredOptionInstruments = () => {
        return new Promise((resolve, reject) => {
            const url = `https://www.deribit.com/api/v2/public/get_instruments?currency=${this.currency}&kind=option&expired=true`;
            axios
                .get(url)
                .then((res) => resolve(res.data.result))
                .catch((err) => reject(err));
        });
    };

    setHardCross = (val) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_SetHardCrossHunter",
                    params: [val],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getHardCrossStatus = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetHardCrossHunterStatus",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getHardCrossLog = (page, limit) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetHardCrossLogs",
                    params: [page, limit],
                })
                .then((res) => resolve(res.data || {}))
                .catch((err) => reject(err));
        });
    };

    GetFutureOrderbook = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetFutureOrderBook",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetOptionsData = (arrayExpirations) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetOptionData",
                    params: [arrayExpirations],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetStatsData = (fromTime, toTime, expiration) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetStats",
                    params: [expiration, fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetApiData = (apiMethod, arrayParams) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: apiMethod,
                    params: arrayParams,
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetTradeMetric = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetTradeMetrics",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetFutureOrderHandlerLogs = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetFutureOrderHandlerLogs",
                })
                .then((res) => resolve(res.data ? res.data : []))
                .catch((err) => reject(err));
        });
    };

    GetGeneralData = (fromTime, toTime, interval) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetGeneralData",
                    params: [fromTime, toTime, interval],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetSeriesData = (expiration, fromTime, toTime, interval) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetSeriesData",
                    params: [expiration, fromTime, toTime, interval],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getTradeLogsData = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetTradingLog",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetActiveSeries = (fromTime, toTime, interval) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetActiveSeriesData",
                    params: [fromTime, toTime, interval],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SetPCAbsolute = (expiration, value) => {
        const signedData = this.signData({ expiration, value });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_SetPricingOffset",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    AdjustIv = (expiration, value) => {
        const signedData = this.signData({ expiration, value });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_AdjustIv",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetLastTrade = async (instruments) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_LastTrade",
                    params: instruments,
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    IsSecretKeyCorrect = (key) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_IsSecretKeyCorrect",
                    params: [key],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    PopulateExchangeIv = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_PolulateIv",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    MarketOffset = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_MarketOffset",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    PricingRouting = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_PricingRoutine",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SmoothDensity = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_SmoothDensity",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SmoothIv = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_SmoothIv",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    MarketIv = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_MarketIv",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    MarketIvGap = (expiration) => {
        const signedData = this.signData({ expiration });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_MarketIvGap",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SetFutureContractAsPricing = (expiration, status) => {
        const signedData = this.signData({
            expiration,
            status,
        });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_SetFutureContractAsPricing",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SetOptionConfig = (expiration, key, value) => {
        const apiMap = {
            market_offset_enabled: "api_MarketOffsetEnabled",
            market_iv_enabled: "api_MarketIvEnabled",
            smooth_iv_enabled: "api_SmoothIvEnabled",
            smooth_density_enabled: "api_SmoothDensityEnabled",
            auto_routine: "api_AutoRoutine",
        };

        const signedData = this.signData({
            expiration,
            value,
        });

        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: apiMap[key],
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    UpdateParams = (params) => {
        const signedData = this.signData(params);
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_UpdateParams",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SaveUserConfig = (userId, config) => {
        const signedData = this.signData({
            userId,
            config: JSON.stringify(config),
        });
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_SaveUserConfig",
                    params: [signedData.data, signedData.timestamp, signedData.signature],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetMisPricingTrades = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetMispricingTrades",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetMarketData = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetMarketData",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetPositionValuation = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetPositionValuation",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetDeltaValuation = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetDeltaValuation",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetHcAttempts = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetHcAttempts",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetOffsetStatistics = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetOffsetStatistics",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    setSimpleOption = (val) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_SetSimpleOptionHunter",
                    params: [val],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getSimpleOptionStatus = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetSimpleOptionHunterStatus",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    getSimpleOptionLog = (page, limit) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_SimpleOptionLogs",
                    params: [page, limit],
                })
                .then((res) => resolve(res.data || {}))
                .catch((err) => reject(err));
        });
    };

    getMispricedPositions = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetMispricedPositions",
                })
                .then((res) => resolve(res.data || []))
                .catch((err) => reject(err));
        });
    };

    getLastMarketUpdate = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetLastMarketUpdate",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetOptionAttempts = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetOptionAttempts",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data || []))
                .catch((err) => reject(err));
        });
    };

    GetDeltaHistories = (fromTime, toTime) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetDeltaValuations",
                    params: [fromTime, toTime],
                })
                .then((res) => resolve(res.data || []))
                .catch((err) => reject(err));
        });
    };

    GetPositionSummaries = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "api_GetPositionSummaries",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SetHardCrossHunterMode = (val) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_SetHardCrossHunterMode",
                    params: [val],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetHardCrossHunterMode = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetHardCrossHunterMode",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    SetSimpleOptionHunterMode = (val) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_SetSimpleOptionHunterMode",
                    params: [val],
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    GetSimpleOptionHunterMode = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_GetSimpleOptionHunterMode",
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };

    UpdateOrderbookForInstrument = (params) => {
        return new Promise((resolve, reject) => {
            axios
                .post(this.RPC_ENDPOINT, {
                    method: "debug_UpdateOrderbookForInstrument",
                    params,
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    };
}
