import styled from "styled-components";
import cx from "classnames";
import DatePicker from "react-datepicker";

const DateTimeInput = ({ date, onDateChange, className }) => {
    return (
        <div className={cx(className, "form-control dateTimeInput")}>
            <DatePicker
                className="form-control"
                selected={date}
                onChange={onDateChange}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
            />
        </div>
    );
};

export default styled(DateTimeInput)`
    padding: 0;

    .react-datepicker-wrapper {
        width: 100%;
        height: 100%;
    }

    .react-datepicker__input-container {
        width: 100%;
        height: 100%;
        display: block;
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
    }
`;
