import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import ReactJson from "react-json-view";
import * as flat from "flat";
import { InputGroup, Form, Container, Col, Row, Button } from "react-bootstrap";
import produce from "immer";

import OptionService from "app/services/option";
import { toast } from "app/components/_base/Toast/slice";

const mapping = (data) => {
    return produce(data, (draft) => {
        data.strikes.forEach((strike) => {
            // const callPrice = strike.call?.theoretical_price;
            // const putPrice = strike.put?.theoretical_price;

            // if (callPrice) {
            //     strike.call.theoretical_price = Number(callPrice).toFixed(5);
            // }

            // if (putPrice) {
            //     strike.put.theoretical_price = Number(putPrice).toFixed(5);
            // }

            if (strike.call.ask_price) {
                strike.call.ask_price = Number(Number(strike.call.ask_price).toFixed(4));
            }

            if (strike.call.bid_price) {
                strike.call.bid_price = Number(Number(strike.call.bid_price).toFixed(4));
            }

            if (strike.put.ask_price) {
                strike.put.ask_price = Number(Number(strike.put.ask_price).toFixed(4));
            }

            if (strike.put.bid_price) {
                strike.put.bid_price = Number(Number(strike.put.bid_price).toFixed(4));
            }
        });
    });
};

export default function ChangeApiJson() {
    const dispatch = useDispatch();

    const { secret, currency } = useSelector((state) => state.global);
    const [optionService, setOptionService] = useState(null);
    React.useEffect(() => {
        setOptionService(new OptionService({ secret, currency }));
    }, [secret, currency]);

    const API_METHOD_DEBUG = [
        "debug_SmoothIv",
        "debug_SmoothIvBasic",
        "debug_SmoothIvFarOut",
        "debug_MarketIv",
        "debug_MarketOffset",
        "debug_SmoothDensityBasic",
        "debug_SmoothDensityFarIn",
        "debug_SmoothIvAbsPrice",
        "debug_MarketIvGap",
        "debug_MarketIvBasic",
    ];

    const csvInstance = useRef(null);
    const [endPoint, setEndPoint] = useState(API_METHOD_DEBUG[0]);
    const [expiry, setExpiry] = useState(null);

    const [oldData, setOldData] = useState({});
    const [newData, setNewData] = useState({});
    const [diffData, setDiffData] = useState({});
    const [csvData, setCsvData] = useState([]);

    const [objExpiry, setObjExpiry] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (optionService) {
            getArrayExpiry();
        }
    }, [optionService]);

    const getArrayExpiry = async () => {
        const systermData = await optionService.LoadSystemConfig();
        const series = systermData.series;
        const objExpiry = {};
        if (series && series.length) {
            series.map((s) => {
                objExpiry[s.expiration] = moment(s.expiration).format("D MMMM Y");
            });
            setExpiry(series[0].expiration);
            setObjExpiry(objExpiry);
        }
    };

    const getApiData = async () => {
        setLoading(true);
        const decimals = 18;
        try {
            const data = await optionService.GetApiData(endPoint, [expiry, decimals]);

            const newData = data?.next_series;
            const oldData = data?.prev_series;
            const diffData = data?.difference_series;

            if (newData && oldData && diffData) {
                setOldData(mapping(oldData));
                setNewData(mapping(newData));
                setDiffData(mapping(diffData));

                dispatch(toast({ type: "success", text: "Fetch successfully" }));
            } else {
                throw new Error("Something went wrong");
            }
        } catch (e) {
            dispatch(toast({ type: "error", text: `${e}` }));
        }
        setLoading(false);

        return [oldData, newData];
    };

    const exportCSV = async (done) => {
        const [oldData, newData] = await getApiData();
        const flatOldData = flat.flatten(oldData);
        const flatNewData = flat.flatten(newData);
        const newCsvData = [["Field", "Previous Value", "Next Value"]];
        Object.keys(flatOldData).map((oldK) => {
            const arrayItemKey = oldK.split(".");
            arrayItemKey.reverse();
            const newKey = arrayItemKey.join(".");
            newCsvData.push([newKey, flatOldData[oldK].toString(), flatNewData[oldK].toString()]);
        });
        setCsvData(newCsvData);
        csvInstance.current.link.click();
    };

    const changeEndPoint = (e) => {
        setEndPoint(e.target.value);
    };

    const changeExpiry = (e) => {
        setExpiry(+e.target.value);
    };

    return (
        <div className="global_config">
            <Container variant="dark">
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>API End Point Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" value={endPoint} onChange={changeEndPoint}>
                                {API_METHOD_DEBUG.map((api) => (
                                    <option key={api} value={api}>
                                        {api}
                                    </option>
                                ))}
                            </Form.Control>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Expiry</InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control as="select" onChange={changeExpiry}>
                                {Object.keys(objExpiry).map((expiry) => {
                                    return (
                                        <option key={expiry} value={expiry}>
                                            {expiry} - {objExpiry[expiry]}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            className="mb-1 mr-1"
                            variant="primary"
                            onClick={getApiData}
                            disabled={isLoading}
                        >
                            {isLoading && <div className="spinner-border spinner-border-sm" />} Send
                        </Button>

                        <Button size="sm" className="mb-1 mr-1" variant="info" onClick={exportCSV} disabled={isLoading}>
                            Export CSV
                        </Button>

                        <CSVLink data={csvData} filename={`${endPoint}_${expiry}.csv`} ref={csvInstance} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="p-0">
                        <div>Previous Series</div>
                        <ReactJson
                            src={oldData}
                            name={false}
                            collapseStringsAfterLength={15}
                            indentWidth={2}
                            displayDataTypes={false}
                        />
                    </Col>
                    <Col className="p-0">
                        <div>Next Series</div>
                        <ReactJson
                            src={newData}
                            collapseStringsAfterLength={15}
                            indentWidth={1}
                            name={false}
                            displayDataTypes={false}
                        />
                    </Col>

                    <Col>
                        <div>Diff ratio</div>
                        <ReactJson
                            src={diffData}
                            name={false}
                            displayDataTypes={false}
                            collapseStringsAfterLength={15}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
