import React from "react";
import styled from "styled-components";
import cx from "classnames";

import styles from "./styles";

const AlertCircle = ({ className }) => {
    return (
        <div className={cx(className, "icon")}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2412 2.66126C5.84121 2.66126 2.24121 6.26125 2.24121 10.6613C2.24121 15.0613 5.84121 18.6613 10.2412 18.6613C14.6412 18.6613 18.2412 15.0613 18.2412 10.6613C18.2412 6.26125 14.6412 2.66126 10.2412 2.66126ZM10.2412 14.6613C9.76121 14.6613 9.44121 14.3413 9.44121 13.8613C9.44121 13.3813 9.76121 13.0613 10.2412 13.0613C10.7212 13.0613 11.0412 13.3813 11.0412 13.8613C11.0412 14.3413 10.7212 14.6613 10.2412 14.6613ZM11.1212 11.4613C11.1212 11.9413 10.7212 12.2613 10.2412 12.2613C9.76121 12.2613 9.44121 11.9413 9.36121 11.4613L9.12121 7.86125C9.04121 7.22125 9.60121 6.66125 10.2412 6.66125C10.8812 6.66125 11.4412 7.22125 11.3612 7.86125L11.1212 11.4613Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default styled(AlertCircle)`
    ${styles}

    color: #E60A32;

    width: 24px;
    height: 24px;
`;
