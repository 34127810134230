import produce from "immer";
import * as constants from "app/actions/constants";

const initialState = {};

export default function orderbookReducer(state = initialState, action) {
    return produce(state, (draft) => {
        const state = draft;

        switch (action.type) {
            case constants.Action_UpdateOrderbook: {
                const { instrument, orderbook } = action.payload;
                state[instrument] = orderbook;

                return;
            }
            case constants.Action_UpdateAllOrderbook: {
                // To keep the same reference of state
                Object.keys(state).forEach((key) => {
                    delete state[key];
                });

                Object.keys(action.payload).forEach((key) => {
                    state[key] = action.payload[key];
                });

                return;
            }

            default:
                return;
        }
    });
}
