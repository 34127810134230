import * as constants from "./constants";

export function SaveUserConfig(config) {
    return {
        type: constants.Action_SaveUserConfig,
        payload: config,
    };
}
export function SaveSystemConfig(config) {
    return {
        type: constants.Action_SaveSystemConfig,
        payload: config,
    };
}

export function UpdateSystemConfig() {
    return {
        type: constants.Action_UpdateSystemConfig,
    };
}

export function ToggleExpandAllSeries(value) {
    return {
        type: constants.Action_ToggleExpandAllSeries,
        payload: value,
    };
}

export function SaveFutureOrderbook(futureOrderbook) {
    return {
        type: constants.Action_SaveFutureOrderbook,
        payload: futureOrderbook,
    };
}

export function ChangeMinDayExpiry(value) {
    return {
        type: constants.Action_ChangeMinDayExpiry,
        payload: value,
    };
}

export function ChangeRiskFreeRate(value) {
    return {
        type: constants.Action_ChangeRiskFreeRate,
        payload: value,
    };
}

export function ChangeDividendRate(value) {
    return {
        type: constants.Action_ChangeDividendRate,
        payload: value,
    };
}

export function ChangePastDealSec(value) {
    return {
        type: constants.Action_ChangePastDealSec,
        payload: value,
    };
}

export function ChangePastDealPriceDiff(value) {
    return {
        type: constants.Action_ChangePastDealPriceDiff,
        payload: value,
    };
}

export function UpdateFutureContractAsPricing(isTrue, expiration) {
    return {
        type: constants.Action_UpdateFutureContractAsPricing,
        payload: { isTrue, expiration },
    };
}

export function ChangeIV(offset, percent, expiration) {
    return {
        type: constants.Action_ChangeIv,
        payload: { offset, percent, expiration },
    };
}

export function SetGammas(expiration, value) {
    return {
        type: constants.Action_SetGamma,
        payload: { expiration, value },
    };
}

export function SetExpandSeries(expiration, value) {
    return {
        type: constants.Action_SetExpand,
        payload: { expiration, value },
    };
}

export function saveSecret(value) {
    return {
        type: constants.Action_SaveSecret,
        payload: value,
    };
}

export function saveCurrency(currency) {
    return {
        type: constants.Action_SaveCurrency,
        payload: currency,
    };
}

export function UpdatePricingContractOffset(value, expiration) {
    return {
        type: constants.Action_UpdatePricingContractOffset,
        payload: { value, expiration },
    };
}

export function UpdatePricingContractOffsetPercent(value, expiration) {
    return {
        type: constants.Action_UpdatePricingContractOffsetPercent,
        payload: { value, expiration },
    };
}

export function UpdateIvs(expiration, ivs) {
    return {
        type: constants.Action_UpdateIvs,
        payload: { expiration, ivs },
    };
}

export function ConvertUserConfig(config) {
    return {
        type: constants.Action_ConvertUserConfig,
        payload: { config },
    };
}

export const updateAutoRunConfig = (expiration, name, value) => {
    return {
        type: constants.Action_UpdateAutoRunConfig,
        payload: {
            expiration,
            name,
            value,
        },
    };
};

export const StartPollingConfigs = () => {
    return {
        type: constants.Action_StartPollingConfigs,
    };
};

export const EndPollingConfigs = () => {
    return {
        type: constants.Action_EndPollingConfigs,
    };
};
