import React from "react";
import styled from "styled-components";
import cx from "classnames";

import CloseMark from "./Icon/CloseMark";

const CloseButton = ({ closeToast, className }) => (
    <div className={cx(className, "closeButton")} onClick={closeToast}>
        <CloseMark />
    </div>
);

export default styled(CloseButton)`
    line-height: 0;
    padding: 4px;
    border-radius: 4px;

    .icon {
        color: #b3b8bd;
        transition: color 150ms;

        &:hover {
            color: #636c79;
        }
    }
`;
