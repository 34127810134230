import {
    HardCrossMispricedOptionLogType,
    HardCrossFirstOptionLogType,
    HardCrossSecondOptionLogType,
    HardCrossMinAmountLogType,
    HardCrossFutureOrderLogType,
    HardCrossFutureOrderBreakdownLogType,
    HardCrossCashFlowLogType,
    HardCrossCashFlowFutureOrderLogType,
} from "./index";

export function LoadConfig() {
    return new Promise((resolve, reject) => {
        resolve({
            future_contracts: [
                {
                    instrument: "BTC-10APR2020",
                    expiration: 1586478318000,
                },
                {
                    instrument: "BTC-26JUN2020",
                    expiration: 1593131118000,
                },
                {
                    instrument: "BTC-25SEP2020",
                    expiration: 1600993518000,
                },
            ],
            min_day_expiry: 2,
            risk_free_rate: 0,
            dividend_rate: 0,
            series: [
                {
                    expiration: 1586478318000,
                    future_contract_as_pricing: true,
                    pricing_offset: 0,
                    iv_offset: 0,
                    iv_offset_percent: 0,
                    options: [
                        {
                            instrument: "BTC-10APR2020-CALL-3000",
                            type: "call",
                            strike: 3000,
                            iv: Math.random(),
                        },
                        {
                            instrument: "BTC-10APR2020-CALL-4000",
                            type: "call",
                            strike: 4000,
                            iv: Math.random(),
                        },
                        {
                            instrument: "BTC-10APR2020-PUT-4000",
                            type: "put",
                            strike: 4000,
                            iv: Math.random(),
                        },
                    ],
                },
                {
                    expiration: 1593131118000,
                    future_contract_as_pricing: false,
                    pricing_offset: 1,
                    iv_offset: 1,
                    iv_offset_percent: 0.1,
                    options: [
                        {
                            instrument: "BTC-25SEP2020-CALL-1000",
                            type: "call",
                            strike: 1000,
                            iv: Math.random(),
                        },
                        {
                            instrument: "BTC-25SEP2020-CALL-2000",
                            type: "call",
                            strike: 2000,
                            iv: Math.random(),
                        },
                        {
                            instrument: "BTC-25SEP2020-PUT-6000",
                            type: "put",
                            strike: 6000,
                            iv: Math.random(),
                        },
                    ],
                },
            ],
        });
    });
}

export function PopulateExchangeIv(instruments) {
    var data = {};
    instruments.forEach((element) => {
        data[element] = (Math.random() * 2 - 1) * 100;
    });
    return new Promise((resolve, reject) => {
        resolve(data);
    });
}

export function SaveConfig(config) {
    return "success";
}

export class OptionService {
    constructor(props) {}

    signData = (data) => {};

    getRpcEndpoint = (env) => {};

    LoadSystemConfig = async () => {};

    getLiveFuture = async () => {};

    GetLastTradesByInstruments = async () => {};

    // LoadLastTrade = async () => { }

    LoadFutureInstrument = async () => {};

    LoadExpiredOptionInstruments = async () => {};

    setHardCross = async () => {};

    getHardCrossStatus = async () => {
        return new Promise((resolve, reject) => {
            const response = {
                is_enable: true,
            };

            resolve(response);
        });
    };

    getHardCrossLog = async () => {
        return new Promise((resolve, reject) => {
            const mispricedOptionLog = {
                timestamp: 1625201855000,
                type: HardCrossMispricedOptionLogType,
                data: "BTC-16JUL21-34000-C",
            };
            const minAmountLog = {
                timestamp: 1625201858000,
                type: HardCrossMinAmountLogType,
                data: 1.234,
            };
            const firstOptionLog = {
                timestamp: 1625201859000,
                type: HardCrossFirstOptionLogType,
                data: {
                    symbol: "BTC-24SEP21-10000-C",
                    side: "BUY",
                    original_price: 1,
                    original_amount: 1,
                    filled_amount: 1,
                    average_price: 1,
                },
            };
            const secondOptionLog = {
                timestamp: 1625201868000,
                type: HardCrossSecondOptionLogType,
                data: {
                    symbol: "BTC-24SEP21-10000-P",
                    side: "SELL",
                    original_price: 1,
                    original_amount: 1,
                    filled_amount: 1,
                    average_price: 1,
                },
            };
            const futureOrderBreakdownLog = {
                timestamp: 1625201869000,
                type: HardCrossFutureOrderBreakdownLogType,
                data: [
                    {
                        symbol: "BTC-24SEP21",
                        side: "BUY",
                        filled_amount: 0.1,
                        average_price: 1,
                    },
                    {
                        symbol: "BTC-24SEP21",
                        side: "BUY",
                        filled_amount: 0.2,
                        average_price: 1,
                    },
                ],
            };
            const futureOrderLog = {
                timestamp: 1625201879000,
                type: HardCrossFutureOrderLogType,
                data: {
                    symbol: "BTC-24SEP21",
                    side: "BUY",
                    filled_amount: 1,
                    average_price: 1,
                },
            };
            const cashFlowLog = {
                timestamp: 1625201889000,
                type: HardCrossCashFlowLogType,
                data: 0.123,
            };
            const cashFlowFutureOrderLog = {
                timestamp: 1625201889000,
                type: HardCrossCashFlowFutureOrderLogType,
                data: {
                    symbol: "BTC-25MAR22",
                    side: "SELL",
                    filled_amount: 1,
                    average_price: 1,
                },
            };

            const response = [
                mispricedOptionLog,
                firstOptionLog,
                secondOptionLog,
                minAmountLog,
                futureOrderBreakdownLog,
                futureOrderLog,
                cashFlowLog,
                cashFlowFutureOrderLog,
            ];
            resolve(response);
        });
    };

    GetFutureOrderbook = async () => {};

    GetOptionsData = async () => {};

    GetStatsData = async () => {};

    GetApiData = async () => {};

    GetTradeMetric = async () => {};

    GetFutureOrderHandlerLogs = async () => {};

    GetGeneralData = async () => {};

    GetSeriesData = async () => {};

    getTradeLogsData = async () => {};

    GetActiveSeries = async () => {};

    SetPCAbsolute = async () => {};

    AdjustIv = async () => {};

    GetLastTrade = async () => {};

    IsSecretKeyCorrect = async () => {};

    PopulateExchangeIv = async () => {};

    MarketOffset = async () => {};

    PricingRouting = async () => {};

    SmoothDensity = async () => {};

    SmoothIv = async () => {};

    MarketIv = async () => {};

    MarketIvGap = async () => {};

    SetFutureContractAsPricing = async () => {};

    SetOptionConfig = async () => {};

    UpdateParams = async () => {};

    SaveUserConfig = async () => {};
}
