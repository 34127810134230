import React, { useState, useEffect, useRef } from "react";
import { Modal, InputGroup, Button, ButtonGroup } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import AmCharts from "@amcharts/amcharts3-react";
import * as _ from "lodash";
import moment from "moment";

const SEVEN_DAY = 1000 * 60 * 60 * 24 * 7;

export default function ChartCombinedIv(props) {
    const [show, setShow] = useState(false);
    const [combinedData, setCombinedData] = useState([]);
    const [tableConfig, setTableConfig] = useState(getTableConfig([]));

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        handleThroteUpdateTableConfig(props.series);
    };

    const handleThroteUpdateTableConfig = async (series = []) => {
        const seriesData = mappingCombinedData(series);
        const combinedOne = combineSeriesToOne(seriesData);
        const newTableConfig = getTableConfig(combinedOne, seriesData);
        setTableConfig(newTableConfig);
    };

    const throttleUpdateTableConfig = useRef(_.throttle(handleThroteUpdateTableConfig, 10000)).current;

    useEffect(() => {
        throttleUpdateTableConfig(props.series);
    }, [props.series]);

    const mappingCombinedData = (series) => {
        const seriesObj = {};
        //.filter(s => s.expiration >= new Date().getTime() + SEVEN_DAY)
        const seriesData = series.map((s) => {
            const arrayChartData = [];
            let isFoundZero = false;
            let isFound_1 = false;
            let isFound_2 = false;
            let isFound1 = false;
            let isFound2 = false;

            s.strikes.map((i) => {
                if (+i.moneyness > 3 || +i.moneyness < -3) return;

                if (+i.moneyness > 0 && !isFoundZero) {
                    isFoundZero = true;

                    arrayChartData.push({
                        moneyness: 0,
                        iv: (Math.round(s.at_the_money * 1000) / 10).toFixed(1),
                        // strike: i.Strike
                    });
                } else if (+i.moneyness > -1 && !isFound_1) {
                    isFound_1 = true;

                    arrayChartData.push({
                        moneyness: -1,
                        iv: (Math.round(s.p1_iv * 1000) / 10).toFixed(1),
                        // strike: i.Strike
                    });
                } else if (+i.moneyness > -2 && !isFound_2) {
                    isFound_2 = true;

                    arrayChartData.push({
                        moneyness: -2,
                        iv: (Math.round(s.p2_iv * 1000) / 10).toFixed(1),
                        // strike: i.Strike
                    });
                } else if (+i.moneyness > 1 && !isFound1) {
                    isFound1 = true;

                    arrayChartData.push({
                        moneyness: 1,
                        iv: (Math.round(s.c1_iv * 1000) / 10).toFixed(1),
                        // strike: i.Strike
                    });
                } else if (+i.moneyness > 2 && !isFound2) {
                    isFound2 = true;

                    arrayChartData.push({
                        moneyness: 2,
                        iv: (Math.round(s.c2_iv * 1000) / 10).toFixed(1),
                        // strike: i.Strike
                    });
                }
                arrayChartData.push({
                    iv: (Math.round(i.iv * 1000) / 10).toFixed(1),
                    moneyness: i.moneyness,
                    strike: i.strike,
                });
            });

            return {
                expiration: s.expiration,
                chartData: arrayChartData,
                seriesName: moment(s.expiration).format("D MMMM Y"),
            };
        });

        return seriesData;
    };

    const combineSeriesToOne = (data) => {
        let arrayCombined = [];
        for (let i = 0; i < data.length; i++) {
            const seriesExpiration = data[i].expiration;
            const seriesIvName = seriesExpiration + "_iv";
            const seriesStrikeName = seriesExpiration + "_strike";
            const seriesMoneynessName = seriesExpiration + "_moneyness";
            const seriesNamedData = data[i].chartData.map((i) => {
                return {
                    [seriesIvName]: +i.iv,
                    [seriesStrikeName]: +i.strike,
                    [seriesMoneynessName]: (Math.round(i.moneyness * 1000) / 10).toFixed(1),
                    moneyness: (Math.round(i.moneyness * 1000) / 10).toFixed(1),
                    realMoneyness: i.moneyness,
                };
            });

            arrayCombined = [...arrayCombined, ...seriesNamedData];
        }

        const sortedData = arrayCombined.sort((a, b) =>
            a.realMoneyness > b.realMoneyness ? 1 : b.realMoneyness > a.realMoneyness ? -1 : 0
        );

        const returnData = [];
        sortedData.map((d) => {
            if (!returnData.length) {
                returnData[0] = d;
                return;
            }

            if (returnData[returnData.length - 1].moneyness == d.moneyness) {
                returnData[returnData.length - 1] = { ...returnData[returnData.length - 1], ...d };
                return;
            }
            returnData.push(d);
        });

        return returnData;
    };

    function getTableConfig(combinedData, series) {
        // const timeBaseData = dataProvider.map(d => ({...d, date: new Date(d.timestamp)}))
        const arrayGraph = series
            ? series.map((s) => ({
                  title: s.seriesName,
                  xField: "moneyness",
                  yField: s.expiration + "_iv",
                  valueField: 1,
                  bullet: "diamond",
                  bulletSize: 10,
                  bulletBorderColor: "#ffffff",
                  bulletBorderAlpha: 1,
                  bulletBorderThickness: 2,
                  // "valueField": "iv",
                  hideBulletsCount: 120,
                  position: "left",
                  step: 0.5,
                  balloonFunction(item, content) {
                      const dataContext = item.dataContext;
                      var html = `<p style="font-size: 120%; font-weight: bold; margin-bottom: 15px;">
              <p> ${s.seriesName}</p>
              <p> IV: ${dataContext[s.expiration + "_iv"]}</p>
              <p> Strike: ${dataContext[s.expiration + "_strike"]}</p>
              <p> Monneyness: ${dataContext[s.expiration + "_moneyness"]}</p>
              </p>`;

                      return html;
                  },
              }))
            : null;

        return {
            type: "xy",
            theme: "none",
            dataProvider: combinedData,
            marginRight: 80,
            dataDateFormat: "YYYY-MM-DD",
            startDuration: 0,
            pathToImages: "https://cdn.amcharts.com/lib/3/images/",

            graphs: arrayGraph,

            chartCursor: {
                valueBalloonsEnabled: true,
                categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
                // "categoryBalloonText": "abc [[index]] [[category]]"
            },
        };
    }

    return (
        <>
            <Button size="sm" className="mb-1 mr-1" variant="primary" onClick={handleShow}>
                Combined IV & Moneyness
            </Button>

            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Combined IV & Moneyness</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Line data={chartData} options={chartOptions} /> */}
                    <AmCharts.React
                        style={{
                            width: "100%",
                            height: "600px",
                        }}
                        options={tableConfig}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}
