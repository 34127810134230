import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "assets/styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Body from "app/components/Layouts/Body";
import { persistor, store } from "store";
import GlobalStyle from "theme/globalStyle";
import "./common.css";

console.log(`Built time: %c${new Date(Number(process.env.BUILT_TIME))}`, "color: #bada55");

const App = (
    <Provider store={store}>
        <GlobalStyle />
        <PersistGate loading={null} persistor={persistor}>
            <Body />
        </PersistGate>
    </Provider>
);

ReactDOM.render(App, document.getElementById("root"));
