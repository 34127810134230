import { useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import useInterval from "app/services/useInterval";
import useLocalStorage from "app/services/useLocalStorage";
import { formatMoney } from "app/helper/function";
import moment from "moment";

const PositionSummary = ({ optionService, totalDelta }) => {
    const [data, setData] = useLocalStorage("PositionSummary", []);
    const [total, setTotal] = useState({});

    const updatePositionSummary = useCallback(async () => {
        const delta = await optionService.GetPositionSummaries();
        if (delta?.length) delta.sort((a, b) => a.expiration - b.expiration);
        setData(delta || []);
    }, [optionService]);

    useEffect(() => {
        if (optionService) {
            updatePositionSummary();
        }
    }, [optionService, updatePositionSummary]);

    useEffect(() => {
        const result = {
            delta: 0,
            gamma: 0,
            total_vega: 0,
            far_put_vega: 0,
            put_vega: 0,
            atm_vega: 0,
            call_vega: 0,
            far_call_vega: 0,
            total_position: 0,
        };
        if (data && data.length) {
            data.forEach((datum) => {
                result.delta += datum.delta || 0;
                result.gamma += datum.gamma || 0;
                result.total_vega += datum.total_vega || 0;
                result.far_put_vega += datum.far_put_vega || 0;
                result.put_vega += datum.put_vega || 0;
                result.atm_vega += datum.atm_vega || 0;
                result.call_vega += datum.call_vega || 0;
                result.far_call_vega += datum.far_call_vega || 0;
                result.total_position += datum.total_position || 0;
            });
        }
        setTotal(result);
    }, [data]);

    useInterval(() => updatePositionSummary(), 1_000); // 1s

    return (
        <Table striped bordered hover className="table-valuation">
            <thead>
                <tr>
                    <th>Expiration</th>
                    <th>Delta</th>
                    <th>Gamma</th>
                    <th>Total Vega</th>
                    <th className="pr-1 pl-1">Far Put Vega</th>
                    <th>Put Vega</th>
                    <th>ATM Vega</th>
                    <th>Call Vega</th>
                    <th className="pr-1 pl-1">Far Call Vega</th>
                    <th>Total Pos</th>
                </tr>
            </thead>
            <tbody>
                {data?.length ? (
                    data.map((datum, index) => (
                        <tr key={index}>
                            <td>{datum.expiration ? moment(datum.expiration).format("MMM DD YYYY") : ""}</td>
                            <td>{formatMoney(totalDelta[datum.expiration], 4)}</td>
                            <td>{formatMoney(datum.gamma, 5)}</td>
                            <td>{formatMoney(datum.total_vega, 1)}</td>
                            <td>{formatMoney(datum.far_put_vega, 1)}</td>
                            <td>{formatMoney(datum.put_vega, 1)}</td>
                            <td>{formatMoney(datum.atm_vega, 1)}</td>
                            <td>{formatMoney(datum.call_vega, 1)}</td>
                            <td>{formatMoney(datum.far_call_vega, 1)}</td>
                            <td>{formatMoney(datum.total_position, 1)}</td>
                        </tr>
                    ))
                ) : (
                    <tr />
                )}

                <tr>
                    <td>None</td>
                    <td>{formatMoney(totalDelta[0], 4)}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>

                <tr>
                    <td>Total</td>
                    <td>{formatMoney(totalDelta[1], 4)}</td>
                    <td>{formatMoney(total.gamma, 5)}</td>
                    <td>{formatMoney(total.total_vega, 1)}</td>
                    <td>{formatMoney(total.far_put_vega, 1)}</td>
                    <td>{formatMoney(total.put_vega, 1)}</td>
                    <td>{formatMoney(total.atm_vega, 1)}</td>
                    <td>{formatMoney(total.call_vega, 1)}</td>
                    <td>{formatMoney(total.far_call_vega, 1)}</td>
                    <td>{formatMoney(total.total_position, 1)}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default PositionSummary;
